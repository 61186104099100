import { useMemo } from 'react'
import * as _ from 'lodash'
import { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import styles from './index.module.scss'
import { RecordChangeSourceList } from 'src/types/enum/RecordChange'

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {}

export const RecordChangedWhatField = (props: Props) => {
  const record = useRecordContext(props)

  const initiatorInfo = useMemo((): string => {
    if (!_.isNil(record.admin) && _.isPlainObject(record.admin)) {
      return `${record.admin.name || ''} ${record.admin.email}`.trim()
    }
    if (!_.isNil(record.user) && _.isPlainObject(record.user)) {
      return `${record.user.name || ''} ${record.user.phone}`.trim()
    }
    return 'Unknown'
  }, [props])

  return (
    <div className={styles.root}>
      <Typography component="div" variant="body2">
        {RecordChangeSourceList.find((i) => i.id === record.source)?.name ?? ''}
      </Typography>
      {(record.admin || record.user) && (
        <Typography component="div" variant="body2">
          {initiatorInfo}
        </Typography>
      )}
    </div>
  )
}

export default RecordChangedWhatField
