export enum ViewTemplateItemSource {
  BestOffers = 'bestOffers',
  PromoTypeList = 'promoTypeList',
  PromoList = 'promoList',
  PromoGroupList = 'promoGroupList',
  PromoAllList = 'promoAllList',
}

export const ViewTemplateItemSourceBrandList = [{ id: ViewTemplateItemSource.BestOffers, name: 'Лучшие предложения' }]
export const ViewTemplateItemSourcePromoList = [
  { id: ViewTemplateItemSource.PromoTypeList, name: 'Акции по типам' },
  { id: ViewTemplateItemSource.PromoList, name: 'Определенные акции' },
  { id: ViewTemplateItemSource.PromoGroupList, name: 'Группы акций' },
  { id: ViewTemplateItemSource.PromoAllList, name: 'Все акции' },
]
export const ViewTemplateItemSourceList = [...ViewTemplateItemSourceBrandList, ...ViewTemplateItemSourcePromoList]
