import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin'
import * as React from 'react'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
  alwaysOn?: boolean
  filter?: any
}

const RestaurantArrayInput = (props: Props) => {
  return (
    <ReferenceArrayInput
      alwaysOn={props.alwaysOn}
      allowEmpty={false}
      source={props.source}
      reference="restaurant"
      variant={'outlined'}
      fullWidth={true}
      filter={props.filter}
      enableGetChoices={({ q }) => q && q.length >= 2}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        label={props.label}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        source="id"
        optionText={(i) => (i?.id ? `${i?.id} ${i?.innerName ?? i?.name ?? ''}` : '')}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </ReferenceArrayInput>
  )
}

export default RestaurantArrayInput
