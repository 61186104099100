import { AdminRole } from 'src/types/enum/AdminRole'
const ViewRules = ['list', 'show', 'edit']
const Orders = ['order', 'order-active', 'order-finished', 'order-refunding']
const PromoReserves = [
  'promo-reserve',
  'promo-reserve-confirmation',
  'promo-reserve-approved',
  'promo-reserve-canceled',
]
const actionAll = (resources: string[]) => {
  return resources.map((i) => ({ action: '*', resource: i }))
}
export const ROLES = {
  [AdminRole.SuperAdmin]: [{ action: '*', resource: '*' }],
  [AdminRole.AdminPartner]: [
    ...actionAll([
      'partner',
      'brand',
      'unit',
      'area',
      'product',
      'menu',
      'product-category',
      'restaurant',

      ...Orders,
      ...PromoReserves,
    ]),
    { action: ViewRules, resource: 'user' },
    { action: ViewRules, resource: 'category' },
    { action: ViewRules, resource: 'promo' },
    ...actionAll(['promo-unit', 'reports']),
    { action: ViewRules, resource: 'review' },
    { action: ViewRules, resource: 'region' },
    { action: ViewRules, resource: 'country' },
    { action: ViewRules, resource: 'wiki' },
    ...actionAll(['admin']),
    { action: ViewRules, resource: 'dashboard' },
    ...actionAll(['complaint', 'penalty']),
    ...actionAll(['penalty']),
    { action: ViewRules, resource: 'reports-orders' },
    { action: ViewRules, resource: 'reports-auction' },
    { action: ViewRules, resource: 'refund' },
    { action: ViewRules, resource: 'partner-transaction' },
  ],
  [AdminRole.SupportPartner]: [
    ...actionAll(['product', 'menu', 'product-category', 'brand', 'unit', 'area', 'promo-unit']),
  ],
  [AdminRole.Operator]: [
    { action: ViewRules, resource: 'partner' },
    { action: ViewRules, resource: 'brand' },
    { action: ViewRules, resource: 'unit' },
    { action: ViewRules, resource: 'product' },
    { action: ViewRules, resource: 'product-category' },
    ...actionAll([...Orders]),
    { action: ViewRules, resource: 'user' },
    { action: ViewRules, resource: 'category-delivery' },
    { action: ViewRules, resource: 'category-local' },
    { action: ViewRules, resource: 'promo-service-delivery' },
    { action: ViewRules, resource: 'promo-unit' },
    { action: ViewRules, resource: 'review' },
    { action: ViewRules, resource: 'penalty' },
    ...actionAll(['penalty']),
    { action: ['list', 'create'], resource: 'refund' },
    { action: ViewRules, resource: 'payment' },

    { action: ViewRules, resource: 'restaurant' },
    { action: ViewRules, resource: 'promo-service-restaurants' },
    { action: ViewRules, resource: 'restaurant-coffee' },
    { action: ViewRules, resource: 'promo-service-coffee' },
    ...PromoReserves.map((i) => ({ action: ['list', 'edit'], resource: i })),
    { action: ViewRules, resource: 'area' },
  ],
  [AdminRole.ClientManager]: [
    { action: ViewRules, resource: 'partner' },
    { action: ViewRules, resource: 'brand' },
    { action: ViewRules, resource: 'unit' },
    { action: ViewRules, resource: 'product' },
    { action: ViewRules, resource: 'product-category' },
    ...actionAll([...Orders]),
    { action: ViewRules, resource: 'user' },
    { action: ViewRules, resource: 'category-delivery' },
    { action: ViewRules, resource: 'category-local' },
    { action: ViewRules, resource: 'promo-service-delivery' },
    { action: ViewRules, resource: 'promo-unit' },
    { action: ViewRules, resource: 'review' },
    ...actionAll(['complaint']),
    ...actionAll(['penalty']),
    { action: ViewRules, resource: 'reports-orders' },
    { action: [...ViewRules, 'create'], resource: 'refund' },
    { action: ViewRules, resource: 'payment' },

    { action: ViewRules, resource: 'restaurant' },
    { action: ViewRules, resource: 'promo-service-restaurants' },
    { action: ViewRules, resource: 'restaurant-coffee' },
    { action: ViewRules, resource: 'promo-service-coffee' },
    { action: ViewRules, resource: 'restaurant-order' },
    ...PromoReserves.map((i) => ({ action: [...ViewRules, 'edit'], resource: i })),
    { action: ViewRules, resource: 'area' },
  ],
  [AdminRole.ContentManager]: [...actionAll(['page', 'promo', 'news'])],

  [AdminRole.ContentUnit]: [...actionAll(['product', 'menu', 'product-category', 'unit', 'restaurant'])],
  [AdminRole.Finance]: [...actionAll(['report'])],
  [AdminRole.PartnerAdmin]: [
    ...actionAll([
      'brand',
      'unit',
      ...Orders,
      'area',
      'product',
      'menu',
      'product-category',
      'promo-unit',
      'restaurant',
      'promo-restaurant',
      'restaurant-order',
      'promo-reserve',
      'promo-reserve-confirmation',
      'promo-reserve-approved',
      'promo-reserve-canceled',
    ]),
  ],

  [AdminRole.PartnerUnitAdmin]: [
    ...actionAll([
      'unit',
      ...Orders,
      'area',
      'product',
      'menu',
      'product-category',
      'promo-unit',
      'restaurant',
      'promo-restaurant',
      'restaurant-order',
      'promo-reserve',
      'promo-reserve-confirmation',
      'promo-reserve-approved',
      'promo-reserve-canceled',
    ]),
  ],
  [AdminRole.PartnerContent]: [...actionAll(['product', 'menu', 'product-category'])],
  [AdminRole.PartnerBrandAdmin]: [
    ...actionAll([
      'brand',
      'unit',
      ...Orders,
      'area',
      'product',
      'menu',
      'product-category',
      'promo-unit',
      'restaurant',
      'promo-restaurant',
      'restaurant-order',
      ...PromoReserves,
    ]),
  ],
}
