import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, SelectInput, SelectField, TextField, DateField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { PSCBRefundStateList } from 'src/types/enum/PSCBRefundState'
import { PromoPayerList } from 'src/types/enum/PromoPayer'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import { Datagrid } from 'src/components/list/Datagrid'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Refund',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput
        source="id:$eq&order.number:$eq"
        label={'Поиск...'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />

      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn={true} />
      <SelectInput source={'payer'} label={'Плательщик'} choices={PromoPayerList} resettable={true} />
      <SelectInput source={'state'} label={'Статус'} choices={PSCBRefundStateList} resettable={true} />
    </Filter>
  )
}

const RefundList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Возвраты"
      hasCreate={false}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Пока нет возвратов'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <SelectField source={'payer'} label={'Плательщик'} choices={PromoPayerList} />
        <TextField source={'orderId'} label={'ID заказа'} />
        <TextField source={'total'} label={'Сумма'} />
        <SelectField source={'state'} label={'Статус'} choices={PSCBRefundStateList} />
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default RefundList
