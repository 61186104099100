export enum ViewTemplateItemView {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export const ViewTemplateItemViewList = [
  { id: ViewTemplateItemView.Vertical, name: 'Вертикальные' },
  { id: ViewTemplateItemView.Horizontal, name: 'Горизонтальные' },
]

export const ViewTemplateItemViewBrandList = [{ id: ViewTemplateItemView.Horizontal, name: 'Горизонтальные' }]
export const ViewTemplateItemViewPromoList = [
  { id: ViewTemplateItemView.Vertical, name: 'Вертикальные' },
  { id: ViewTemplateItemView.Horizontal, name: 'Горизонтальные' },
]
