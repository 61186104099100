export enum PromoUnitStatus {
  Active = 'active',
  Moderation = 'moderation',
  Disabled = 'disabled',
}

export const PromoUnitStatusList = [
  { id: PromoUnitStatus.Active, name: 'Активна' },
  { id: PromoUnitStatus.Moderation, name: 'На модерации' },
  { id: PromoUnitStatus.Disabled, name: 'Заблокирована' },
]
