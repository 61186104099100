import ListIcon from '@mui/icons-material/List'
import PromoUnitList from 'src/resources/promo-unit/PromoUnitList'
import PromoUnitEdit from 'src/resources/promo-unit/PromoUnitEdit'
import PromoUnitCreate from 'src/resources/promo-unit/PromoUnitCreate'

export default {
  create: PromoUnitCreate,
  edit: PromoUnitEdit,
  list: PromoUnitList,
  icon: ListIcon,
}
