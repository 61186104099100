import { AutocompleteInput, FilterPayload, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { omit } from 'lodash'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  validate?: any
  fullWidth?: boolean
  filter?: FilterPayload
  helperText?: string
  onChange?: (val: any) => void
}
const PromoUnitGroupInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source="groupId"
      reference="promo-unit-group"
      variant={'outlined'}
      sort={{ field: 'name', order: 'ASC' }}
      filter={props.filter}
      perPage={100}
      enableGetChoices={({ q }) => q && q.length >= 2}
      disabled={props.disabled}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        validate={props.validate}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        optionText={(i) => `${i?.name || ''}`}
        label="Группы акций заведения"
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </ReferenceInput>
  )
}

export default PromoUnitGroupInput
