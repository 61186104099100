import * as React from 'react'
import styles from './index.module.scss'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress'
import Formatter from 'src/utils/formatters'

interface Props {
  currentFileProgress: number
  currentFileName: string
  totalUploaded: number
  total: number
  onCancel: () => void
}

const ModalMediaUploading = (props: Props) => {
  return (
    <div className={styles.root} onClick={(e) => e.stopPropagation()}>
      <div className={styles.progressItem}>
        <CircularProgressWithLabel value={((props.totalUploaded ?? 0) / props.total) * 100} size={54} />
        <Typography variant="h6" component="div" color="inherit">
          Загружено {props.totalUploaded} из {props.total} {Formatter.pluralize(props.total, 'файл', 'файла', 'файлов')}
        </Typography>
      </div>
      <div className={styles.progressItem}>
        <Typography variant="subtitle1" component="div" color="inherit">
          Текущий файл: {props.currentFileName} {props.currentFileProgress ?? 0}%
        </Typography>
      </div>

      <Button
        variant="contained"
        sx={(theme) => ({
          marginTop: '20px',
          background: theme.palette.error.main,
        })}
        color="primary"
        onClick={props.onCancel}
      >
        Отменить
      </Button>
    </div>
  )
}

const CircularProgressWithLabel = (props: CircularProgressProps) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div" color="inherit">{`${Math.round(props.value ?? 0)}%`}</Typography>
      </Box>
    </Box>
  )
}
export default ModalMediaUploading
