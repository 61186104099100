import * as React from 'react'
import { FC } from 'react'
import {
  Filter,
  List,
  TextInput,
  ListProps,
  ReferenceField,
  TextField,
  SelectField,
  DateField,
  ReferenceInput,
  SelectInput,
  useResourceContext,
} from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import { ArticleType, ArticleTypeList } from 'src/types/enum/ArticleType'
import TransTextField from 'src/components/fields/TransTextField'
import { getTranslation } from 'src/utils/translation'
import AssetField from 'src/components/fields/AssetField'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      published: 'Опубликовано',
      name: 'Название',
      type: 'Тип',
      category: 'Категория',
      seoTitle: 'SEO Title',
      seoDescription: 'SEO Description',
      seoKeywords: 'SEO Keywords',

      totalViews: 'Просмотры',
      publishedAt: 'Публикация',
      adminUpdatedAt: 'Обновлено',
      admin: 'Админ',
    },
    data: posts.map((item: any) => ({
      ...item,
      name: getTranslation(item)?.name,
      seoTitle: getTranslation(item)?.seoTitle,
      seoDescription: getTranslation(item)?.seoDescription,
      seoKeywords: getTranslation(item)?.seoKeywords,
      admin: item.admin?.name || '',
      category: getTranslation(item?.articleCategory)?.name,
      type: ArticleTypeList.find((i) => i.id === item.articleType)?.name || '',
      published: item.published ? 'Да' : 'Нет',
      isTop: item.isTop ? 'Топ' : '',
      createdAt: formatCsvDate(item.createdAt),
      adminUpdatedAt: formatCsvDate(item.adminUpdatedAt),
      publishedAt: formatCsvDate(item.publishedAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Articles',
  })
}

const _Filter = (props: any) => {
  const resource = useResourceContext()
  return (
    <Filter {...props}>
      <ReferenceInput
        source="articleCategoryId"
        reference="article-category"
        variant={'outlined'}
        filter={{ categoryType: ArticleType.Article }}
        sort={{ field: 'translations.name', order: 'ASC' }}
        perPage={100}
      >
        <SelectInput fullWidth={true} label="Категория" optionText={(record) => `${getTranslation(record)?.name}`} />
      </ReferenceInput>
      <TextInput source="translations.name" label={'Поиск'} variant={'outlined'} alwaysOn />
      <SelectInput source={'articleType'} label={'Тип'} choices={ArticleTypeList} />
    </Filter>
  )
}

const ArticleList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title={'Статьи'}
      sort={{ field: 'id', order: 'DESC' }}
      empty={
        <EmptyList title={'Нет статей'} description={'Вы можете добавить статью'} buttonText={'Добавить статью'} />
      }
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <VisibilityField source="published" label={''} />
        <AssetField source={'image'} label={''} />
        <ReferenceField label="Категория" source="articleCategoryId" reference="article-category">
          <TransTextField source="name" />
        </ReferenceField>
        <TransTextField source="name" label={'Название'} />
        <SelectField source="articleType" label={'Тип'} choices={ArticleTypeList} />
        <DateField source="publishedAt" label={'Время публикации'} showTime={true} />
        <DateField source="adminUpdatedAt" label={'Обновлено'} showTime={true} />
        <ReferenceField label="Админ" source="adminId" reference="admin">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

export default ArticleList
