import * as React from 'react'
import * as _ from 'lodash'
import { SimpleForm, required, DateInput } from 'react-admin'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { MediaInput } from 'src/components/inputs/MediaInput'

const allowedMimeTypes = [
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const PartnerOfferForm = (props: any) => {
  const formatMediaInput = (value: Record<any, any>) => {
    if (_.isArray(value)) {
      return _.filter(value, (asset) => allowedMimeTypes.includes(asset?.mimeType))
    }
    return allowedMimeTypes.includes(value?.mimeType) ? value : null
  }

  return (
    <SimpleForm {...props} redirect={'list'}>
      <PartnerInput source={'partnerId'} label={'Партнер'} validate={required()} fullWidth />
      <MediaInput
        source={'asset'}
        isMulti={false}
        label={'Файл'}
        format={formatMediaInput}
        helperText={'Допускаются файлы формата pdf, word и jpeg'}
      />
      <DateInput source={'offerDate'} label={'Дата договора'} fullWidth />
    </SimpleForm>
  )
}
