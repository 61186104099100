import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import { CustomEdit } from 'src/components/CustomEdit'
import PromoUnitGroupForm from './PromoUnitGroupForm'

const PromoUnitGroupEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic">
      <PromoUnitGroupForm isCreation={false} />
    </CustomEdit>
  )
}
export default PromoUnitGroupEdit
