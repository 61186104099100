import * as React from 'react'
import { RecordContextProvider, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import { useEffect, useState } from 'react'
import { RestaurantMenuItemEditForm } from 'src/resources/restaurant/menu/RestaurantMenuItemEditForm'
interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  mainRecord: Record<any, any>
  onClose?: () => void
}
export const RestaurantMenuItemEditModal = (props: Props) => {
  const baseAreaRecord = useRecordContext()
  const [record, setRecord] = useState<Record<any, any> | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefresh()

  const dataProvider = useDataProvider()
  useEffect(() => {
    dataProvider.getOne('restaurant-menu-item', { id: baseAreaRecord.id }).then(({ data }) => {
      setRecord(data)
      setIsLoading(false)
    })
  }, [])
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update('restaurant-menu-item', { id: baseAreaRecord.id, data, previousData: record })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={record ?? {}}>
      <ModalForm
        isShown
        isLoading={isLoading}
        defaultValues={{ preview: 'medium' }}
        fullWidth={true}
        maxWidth={'lg'}
        title={record ? 'Изменить' : 'Новый раздел меню'}
        onClose={props.onClose}
        save={handleSubmit}
        record={record}
      >
        {!isLoading ? <RestaurantMenuItemEditForm /> : <div />}
      </ModalForm>
    </RecordContextProvider>
  )
}
RestaurantMenuItemEditModal.defaults = {
  refresh: true,
}
