import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import PenaltyForm from 'src/resources/penalty/PenaltyForm'
import { CustomEdit } from 'src/components/CustomEdit'
import Formatter from 'src/utils/formatters'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) =>
  record ? (
    <span>
      Штраф № {record.id} от {Formatter.formatDateRelative(record.createdAt)}
    </span>
  ) : null

const PenaltyEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <PenaltyForm />
    </CustomEdit>
  )
}
export default PenaltyEdit
