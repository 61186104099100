import { AutocompleteInput, FilterPayload, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { omit } from 'lodash'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  fullWidth?: boolean
  filter?: FilterPayload
  helperText?: string
  multiple?: boolean
  validate?: any
}
const PromoServiceCoffeeInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source={props.source ?? 'promoId'}
      reference="promo-service-coffee"
      variant={'outlined'}
      filter={props.filter}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        optionText={(i) => `${i?.name || ''}`}
        label="Акция"
        multiple={props.multiple}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        validate={props.validate}
      />
    </ReferenceInput>
  )
}

export default PromoServiceCoffeeInput
