import * as React from 'react'
import { FC } from 'react'
import { EditProps, TopToolbar, usePermissions } from 'react-admin'
import RestaurantForm from 'src/resources/restaurant/RestaurantForm'
import { CustomEdit } from 'src/components/CustomEdit'
import { ModalButton } from 'src/components/Modal/ModalButton'
import Box from '@mui/material/Box'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { RestaurantBlockedModal } from 'src/resources/restaurant/RestaurantBlockedModal'
import { Lock } from 'mdi-material-ui'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'

const Actions = () => {
  const { permissions } = usePermissions()

  return (
    <TopToolbar>
      <IfCanAccess aggregator action={'show'}>
        <PhoneCallActionField source={'phone'} />
      </IfCanAccess>
      <IfCanAccess aggregator action={'edit'}>
        <Box ml={3}>
          <ModalButton label={'Заблокировать'} icon={<Lock />} modal={<RestaurantBlockedModal />} />
        </Box>
      </IfCanAccess>
    </TopToolbar>
  )
}
const RestaurantEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" actions={<Actions />}>
      <RestaurantForm isEdit />
    </CustomEdit>
  )
}
export default RestaurantEdit
