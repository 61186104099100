import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import ProductForm from 'src/resources/product/ProductForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Товар</span>

const ProductCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <ProductForm />
    </Create>
  )
}

export default ProductCreate
