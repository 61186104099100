export enum UnitStatus {
  Moderation = 'moderation',
  Blocked = 'blocked',
  Active = 'active',
}
export const UnitStatusList = [
  { id: UnitStatus.Moderation, name: 'На модерации' },
  { id: UnitStatus.Blocked, name: 'Заблокирован' },
  { id: UnitStatus.Active, name: 'Активен' },
]
