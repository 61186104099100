import * as React from 'react'
import { FC } from 'react'
import {
  Filter,
  List,
  TextField,
  SelectField,
  FunctionField,
  ListProps,
  TextInput,
  SelectInput,
  RaRecord,
} from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { ViewTemplateTypeList } from 'src/types/enum/ViewTemplateType'
import { ServiceTypeList } from 'src/types/enum/ServiceType'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'ViewTemplate',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <SelectInput source="type" label={'Тип'} choices={ViewTemplateTypeList} />
      <SelectInput source="serviceType" label={'Сервис'} choices={ServiceTypeList} />
    </Filter>
  )
}

const ViewTemplateList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Шаблоны вывода"
      sort={{ field: 'name', order: 'ASC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'show'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'name'} label={'Название'} />
        <SelectField source={'type'} label={'Тип'} choices={ViewTemplateTypeList} />
        <SelectField source={'serviceType'} label={'Сервис'} choices={ServiceTypeList} />
        <FunctionField
          source={'type'}
          label={''}
          render={(record: RaRecord) => (record.isDefault ? 'По умолчанию' : '')}
        />
      </Datagrid>
    </List>
  )
}

export default ViewTemplateList
