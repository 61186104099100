import * as React from 'react'
import { RecordContextProvider, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { UnitSelectionItemEditForm } from 'src/resources/unit-selection/items/UnitSelectionItemEditForm'
interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  mainRecord: Record<any, any>
  onClose?: () => void
}
export const UnitSelectionItemCreateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.create('unit-selection-item', {
      data: { ...data, unitSelectionId: props.mainRecord.id },
    })
    if (props.refresh) {
      refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{ preview: 'medium' }}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Новый ресторан подборки'}
        onClose={props.onClose}
        save={handleSubmit}
      >
        {<UnitSelectionItemEditForm refresh />}
      </ModalForm>
    </RecordContextProvider>
  )
}
UnitSelectionItemCreateModal.defaults = {
  refresh: true,
}
