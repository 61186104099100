import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { AccountingTypeList } from '../../types/enum/AccountingType'

const _Filter = (props: any) => (
  <Filter {...props}>
    <PartnerInput source="partnerId" label={'Партнер'} alwaysOn={true} resettable={true} />
    <SelectInput
      source="documentType"
      label={'Тип документа'}
      alwaysOn={true}
      resettable={true}
      choices={AccountingTypeList}
    />
  </Filter>
)

const AccountingList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Бухгалтерия"
      empty={
        <EmptyList
          title={'Нет документов в бухгалтерии'}
          description={'Вы можете добавить документ'}
          buttonText={'Добавить документ'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <PartnerLinkField source="partner.id" mainSource={'partner'} label={'Партнер'} />
        <TextField source={'name'} label={'Тип документа'} />
        <DateField source="offerDate" label={'Дата договора'} showTime={true} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default AccountingList
