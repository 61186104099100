export enum BanquetReserveStatus {
  WaitingPayment = 'WaitingPayment',
  Confirmed = 'Confirmed',
  WaitingForFillingTotalPrice = 'WaitingForFillingTotalPrice',
  FilledTotalPrice = 'FilledTotalPrice',
  Cancelled = 'Cancelled',
}

export const BanquetReserveStatusList = [
  { id: BanquetReserveStatus.WaitingPayment, name: 'Ожидание подтверждения' },
  { id: BanquetReserveStatus.Confirmed, name: 'Подтверждена' },
  { id: BanquetReserveStatus.WaitingForFillingTotalPrice, name: 'Не указана сумма' },
  { id: BanquetReserveStatus.FilledTotalPrice, name: 'Завершена' },
  { id: BanquetReserveStatus.Cancelled, name: 'Отменена' },
]

export enum BanquetStatus {
  Active = 'Active',
  Stopped = 'Stopped',
  Confirmed = 'Confirmed',
}

export const BanquetStatusList = [
  { id: BanquetStatus.Active, name: 'Активен' },
  { id: BanquetStatus.Stopped, name: 'Завершен' },
  { id: BanquetStatus.Confirmed, name: 'Остановлен' },
]
