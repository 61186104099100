import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { PartnerOfferForm } from './PartnerOfferForm'

const PartnerOfferEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <PartnerOfferForm />
    </Edit>
  )
}
export default PartnerOfferEdit
