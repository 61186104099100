import { AutocompleteInput, ReferenceInput, FilterPayload } from 'react-admin'
import * as React from 'react'
import { omit } from 'lodash'
import { ServiceType } from 'src/types/enum/ServiceType'
import { useMemo } from 'react'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  partnerId?: number
  validate?: any
  onChange?: (val: any) => void
  fullWidth?: boolean
  filter?: FilterPayload
  serviceType: ServiceType
}

const RestaurantInput = (props: Props) => {
  const reference = useMemo(() => {
    switch (props.serviceType) {
      case ServiceType.Restaurants:
        return 'restaurant'
      default:
        return `restaurant-${props.serviceType}`
    }
  }, [])

  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source="restaurantId"
      reference={reference}
      variant={'outlined'}
      filter={props.filter}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      enableGetChoices={({ q }) => q && q.length >= 2}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL&innerName:$contL': q })}
        optionText={(i) => (i?.id ? `${i?.id} ${i?.innerName ?? i?.name ?? ''}` : '')}
        label={ServiceTypeDictionary.getServiceEntity(props.serviceType)?.single}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        validate={props.validate}
      />
    </ReferenceInput>
  )
}

export default RestaurantInput
