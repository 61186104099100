export enum PromoReserveStatus {
  Created = 'created',
  Approved = 'approved',
  Confirmation = 'confirmation',
  Cancelled = 'cancelled',
  WaitingForFillingTotalPrice = 'waitingForFillingTotalPrice',
  FilledTotalPrice = 'filledTotalPrice',
}

export const PromoReserveStatusList = [
  { id: PromoReserveStatus.Created, name: 'Создан' },
  { id: PromoReserveStatus.Confirmation, name: 'Подтверждается' },
  { id: PromoReserveStatus.Approved, name: 'Подтверждена' },
  { id: PromoReserveStatus.Cancelled, name: 'Отменена' },
  { id: PromoReserveStatus.WaitingForFillingTotalPrice, name: 'Не указана сумма' },
  { id: PromoReserveStatus.FilledTotalPrice, name: 'Завершена' },
]

export enum PromoReserveCancelReason {
  GuestChangedMind = 'GuestChangedMind',
  GuestIsLate = 'GuestIsLate',
  GuestDidntCome = 'GuestDidntCome',
  GuestBookedElsewhere = 'GuestBookedElsewhere',
  PlansHaveChanged = 'PlansHaveChanged',
  Other = 'Other',
}

export const PromoReserveCancelReasonList = [
  { id: PromoReserveCancelReason.GuestChangedMind, name: 'Гость передумал' },
  { id: PromoReserveCancelReason.GuestIsLate, name: 'Гость опаздывает' },
  { id: PromoReserveCancelReason.GuestDidntCome, name: 'Гость не пришел' },
  { id: PromoReserveCancelReason.GuestBookedElsewhere, name: 'Забронировали в другом месте' },
  { id: PromoReserveCancelReason.PlansHaveChanged, name: 'Изменились планы' },
  { id: PromoReserveCancelReason.Other, name: 'Другое' },
]
