import * as React from 'react'

import styles from './index.module.scss'

import { PromoIcon } from 'src/types/enum/PromoIcon'
import { PromoColor } from 'src/types/enum/PromoColor'
import { PromoTemplate } from 'src/types/enum/PromoTemplate'
import VsemedaPromoBgSvg from 'src/components/svg/VsemedaPromoBgSvg'
import { IPromoSettings } from 'src/types/interfaces/IPromoSettings'

interface Props {
  image?: any
  icon: PromoIcon
  color: PromoColor
  badgeColor: PromoColor
  template: PromoTemplate
  badge?: string
  name?: string
  text?: string
  bigText?: string
  shortDesc?: string
  settings?: IPromoSettings
}

const PromoBigCard = (props: Props) => {
  const isGradient = props.color?.includes(';')

  return (
    <div
      className={styles.root}
      style={{
        ...(props.color
          ? {
              background: isGradient
                ? `linear-gradient(125.99deg, ${props.color.split(';')[0]} -2.47%, ${props.color.split(';')[1]} 86.22%)`
                : props.color,
            }
          : {}),
      }}
    >
      <div className={styles.bgLogo}>
        <VsemedaPromoBgSvg />
      </div>
      <div className={styles.badge}>{props.bigText}</div>
      <div className={styles.name}>
        {props.badge?.replace('{{minOrderAmount}}', props.settings?.minOrderAmount?.toString() || '{{minOrderAmount}}')}
      </div>
      {props.shortDesc && <div className={styles.name}>{props.shortDesc}</div>}
      {props.image && <img src={props.image.link} className={styles.image} />}
    </div>
  )
}
export default PromoBigCard
