import React from 'react'
import { Typography } from '@mui/material'
import { FieldProps, RaRecord } from 'react-admin'
import { useRecordContext } from 'ra-core'
import get from 'lodash/get'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
  sourceId?: string
  sourceName?: string
  sourcePhone?: string
}
const UserField = (props: Props) => {
  const record = useRecordContext(props)
  const id = get(record, props.sourceId ?? 'id')
  const name = get(record, props.sourceName ?? 'name')
  const phone = get(record, props.sourcePhone ?? 'phone')

  return (
    <>
      {name && <Typography variant={'body2'}>{name}</Typography>}
      {phone && <PhoneCallActionField source={props.sourcePhone ?? 'phone'} link={`/user/${id}/show`} />}
    </>
  )
}

export default UserField
