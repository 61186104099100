import ListIcon from '@mui/icons-material/List'
import PenaltyList from 'src/resources/penalty/PenaltyList'
import PenaltyEdit from 'src/resources/penalty/PenaltyEdit'
import PenaltyCreate from 'src/resources/penalty/PenaltyCreate'

export default {
  create: PenaltyCreate,
  edit: PenaltyEdit,
  list: PenaltyList,
  icon: ListIcon,
}
