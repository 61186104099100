import PersonIcon from '@mui/icons-material/Person'
import AppSettingsList from './AppSettingsList'
import AppSettingsEdit from './AppSettingsEdit'
import AppSettingsCreate from './AppSettingsCreate'

export default {
  create: AppSettingsCreate,
  edit: AppSettingsEdit,
  list: AppSettingsList,
  icon: PersonIcon,
}
