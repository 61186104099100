import * as React from 'react'
import {
  Show,
  Labeled,
  SimpleShowLayout,
  TextField,
  SelectField,
  DateField,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  FunctionField,
} from 'react-admin'
import * as _ from 'lodash'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { OrderStatusList } from 'src/types/enum/OrderStatus'
import { PaymentStatusList } from 'src/types/enum/PaymentStatus'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styles from './index.module.scss'
import LinkField from 'src/components/fields/LinkField'
import RelatedList from 'src/components/list/RelatedList'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { ModalButton } from 'src/components/Modal/ModalButton'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { PromoReserveStatusChip } from 'src/components/chips/PromoReserveStatusChip'
import { PromoReserveStatus } from 'src/types/enum/PromoReserveStatus'
import { PromoReserveApproveModal } from 'src/resources/promo-reserve/PromoReserveApproveModal'
import { PromoReserveCancelModal } from 'src/resources/promo-reserve/PromoReserveCancelModal'
import { PromoReserveChangedDataField } from 'src/components/fields/PromoReserveChangedDataField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import { PromoReserveUtils } from 'src/utils/PromoReserveUtils'
import PriceField from 'src/components/fields/PriceField'
import { PromoReserveFillTotalPriceModal } from '../PromoReserveFillTotalPriceModal'
import PromoReserveWhatField from 'src/components/fields/PromoReserveChangedWhatField'
// @ts-ignore
import Duration from 'duration-js'

const Actions = () => {
  const record = useRecordContext()

  return (
    <TopToolbar>
      {record.status === PromoReserveStatus.Confirmation && (
        <ModalButton label={'Подтвердить'} modal={<PromoReserveApproveModal />} />
      )}
      {[PromoReserveStatus.Approved, PromoReserveStatus.WaitingForFillingTotalPrice].includes(record.status) && (
        <ModalButton label={'Завершить'} modal={<PromoReserveFillTotalPriceModal />} />
      )}
      {[PromoReserveStatus.Confirmation, PromoReserveStatus.Approved, PromoReserveStatus.Created].includes(
        record.status,
      ) && <ModalButton label={'Отменить'} modal={<PromoReserveCancelModal />} />}
    </TopToolbar>
  )
}

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}

const HeaderSection = () => {
  const record = useRecordContext()

  return (
    <div className={styles.headingSection}>
      <div className={styles.info}>
        <div className={styles.subTitle}>
          <Typography variant="h6">Бронь № {record.id}</Typography>
          <PromoReserveStatusChip status={record.status} />
        </div>
        <DateField source="createdAt" label={'Создан'} showTime={true} />
      </div>
    </div>
  )
}

const PromoReserveChanges = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="promo-reserve-change"
      target="subscriptionQrCodeId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'promo-reserve-change'}
        hasCreate={false}
        emptyTitle={'Пока нет изменений'}
        bulkActionButtons={false}
      >
        <PromoReserveWhatField source={'source'} label={''} />
        <PromoReserveChangedDataField source={'before'} label={'До'} />
        <PromoReserveChangedDataField source={'after'} label={'После'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

type ScheduleInitialType = {
  from: string
  to: string
}

const scheduleDays = {
  0: 'Пн',
  1: 'Вт',
  2: 'Ср',
  3: 'Чт',
  4: 'Пт',
  5: 'Сб',
  6: 'Вс',
} as const

function toDuration(value: string): Duration | null {
  if (!value) {
    return null
  }
  const list = value.split(':')
  //
  return list.length == 2 ? new Duration(`${_.parseInt(list[0])}h${_.parseInt(list[1])}m`) : null
}

const Schedule = () => {
  const record = useRecordContext()
  const [schedule, setSchedule] = useState<Record<number, ScheduleInitialType[]> | null>(null)

  useEffect(() => {
    initializeSchedule()
  }, [])

  const initializeSchedule = useCallback(() => {
    if (!_.isObject(record?.restaurant?.schedule)) {
      return
    }
    let restaurantSchedule = _.cloneDeep(record.restaurant.schedule) as Record<number, ScheduleInitialType[]>
    const entries: Record<string, ScheduleInitialType[]> = {}

    for (const key in restaurantSchedule) {
      const values = restaurantSchedule[key]

      const include = !_.isNil(
        values.find((scheduleElement) => scheduleElement.from === '00:00' && scheduleElement.to !== '00:00'),
      )

      if (include) {
        entries[key] = values
      }
    }
    const entryKeys = Object.keys(entries)

    _.forEach(entries, (value, key) => {
      if (entryKeys.includes(key)) {
        const previousKey = _.parseInt(key == '0' ? '7' : key) - 1
        //
        const currentScheduleIndex = value.findIndex((element) => element.from === '00:00' && element.to !== '00:00')

        const previousScheduleIndex = restaurantSchedule[previousKey]?.findIndex(
          (element) =>
            element.to == '00:00' &&
            (toDuration(element.from) ?? new Duration()).seconds() >
              (toDuration(value[currentScheduleIndex].to)?.seconds() ?? new Duration().seconds()),
        )

        if ((previousScheduleIndex ?? -1) != -1) {
          const previousSchedule = restaurantSchedule[previousKey][previousScheduleIndex]
          //
          restaurantSchedule[previousKey][previousScheduleIndex] = {
            from: previousSchedule.from,
            to: value[currentScheduleIndex].to,
          }
          //
          restaurantSchedule[_.parseInt(key)]?.splice(currentScheduleIndex, 1)
        }
      }
    })
    for (const key of Object.keys(restaurantSchedule)) {
      const schedule = restaurantSchedule[_.parseInt(key)]

      if (!_.isArray(schedule) || _.isEmpty(schedule)) {
        delete restaurantSchedule[_.parseInt(key)]
      }
    }
    if (_.isObject(restaurantSchedule) && !_.isEmpty(restaurantSchedule)) {
      const restaurantScheduleClone = _.cloneDeep(restaurantSchedule)

      restaurantSchedule[0] = restaurantScheduleClone[1]
      restaurantSchedule[1] = restaurantScheduleClone[2]
      restaurantSchedule[2] = restaurantScheduleClone[3]
      restaurantSchedule[3] = restaurantScheduleClone[4]
      restaurantSchedule[4] = restaurantScheduleClone[5]
      restaurantSchedule[5] = restaurantScheduleClone[6]
      restaurantSchedule[6] = restaurantScheduleClone[0]
    }
    //
    setSchedule(restaurantSchedule)
  }, [record])

  const RenderScheduleDays = useCallback(() => {
    if (!schedule) {
      return null
    }
    const widgets: ReactElement[] = []

    for (const key in schedule) {
      const scheduleItem = schedule[_.parseInt(key)]

      if (!_.isArray(scheduleItem) || _.isEmpty(scheduleItem)) {
        continue
      }
      widgets.push(
        <Typography key={key} variant={'body1'}>
          {scheduleDays[_.parseInt(key) as keyof typeof scheduleDays]} {scheduleItem[0].from} - {scheduleItem[0].to}
        </Typography>,
      )
    }
    return <>{widgets}</>
  }, [schedule])

  if (_.isNil(schedule) || _.isEmpty(schedule)) {
    return null
  }
  return (
    <SimpleShowLayout spacing={0} sx={{ px: 0 }}>
      <Typography variant={'caption'}>Режим работы</Typography>
      <RenderScheduleDays />
    </SimpleShowLayout>
  )
}

const Commission = () => {
  const record = useRecordContext()

  if (record.status !== PromoReserveStatus.FilledTotalPrice) {
    return null
  }
  return (
    <div>
      <Labeled label="Комиссия">
        <PriceField source={'commission'}></PriceField>
      </Labeled>
    </div>
  )
}

const Index = () => {
  return (
    <Show emptyWhileLoading actions={<Actions />} resource={'promo-reserve'}>
      <SimpleShowLayout>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <HeaderSection />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <IfCanAccess aggregator action={'show'}>
                  <Grid item md={6}>
                    <Labeled label="Партнер">
                      <LinkField
                        link={(order) => `/partner/${order.restaurant?.partner?.id}`}
                        source={'restaurant.partner.name'}
                      />
                    </Labeled>
                  </Grid>
                </IfCanAccess>

                <div>
                  <Labeled label="Комментарий">
                    <TextField source={'adminComment'} />
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Акция">
                    <TextField source={'promo.name'} />
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Время брони">
                    <FunctionField
                      source="startAt"
                      label={'Время брони'}
                      render={(i: any) =>
                        PromoReserveUtils.formatBookingTime({
                          startAt: i.startAt,
                          endAt: i.endAt,
                          timezone: i.timezone ?? i.restaurant?.timezone,
                        })
                      }
                    />
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Подтвердить до">
                    <DateField source="confirmUntil" label={'Подтвердить до'} showTime={true} />
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Кол-во гостей">
                    <TextField source={'numOfPersons'} showTime={true} />
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Причина отмены">
                    <TextField source={'rejectionReason'}></TextField>
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Сумма чека">
                    <PriceField source={'total'}></PriceField>
                  </Labeled>
                </div>
                <div>
                  <Labeled label="Время заполнения суммы чека">
                    <DateField source="totalFilledAt" showTime></DateField>
                  </Labeled>
                </div>
                <Commission />
              </Grid>

              <Grid item md={6}>
                <Labeled label="Ресторан">
                  <RestaurantLinkField source={'restaurant.id'} withPhone />
                </Labeled>
                <Schedule />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Labeled label="Статус">
                  <SelectField source={'status'} label={'Статус'} choices={OrderStatusList} />
                </Labeled>
              </Grid>
              <Grid item md={3}>
                <Labeled label="Статус оплаты">
                  <SelectField
                    source={'subscription.paymentStatus'}
                    label={'Статус оплаты'}
                    choices={PaymentStatusList}
                  />
                </Labeled>
              </Grid>
            </Grid>

            <Header>Клиент</Header>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Labeled label="Имя">
                  <TextField source={'user.name'} />
                </Labeled>
              </Grid>
              <Grid item md={4}>
                <Labeled label="Телефон">
                  <PhoneCallActionField source={'user.phone'} label={'Телефон'} />
                </Labeled>
              </Grid>
              <Grid item md={4}>
                <Labeled label="Email">
                  <TextField source={'user.email'} />
                </Labeled>
              </Grid>
              <LinkField link={(order) => `/user/${order.userId}/show`} value={() => 'Карточка клиента'} />
            </Grid>
          </Grid>
        </Grid>
        <PromoReserveChanges />
      </SimpleShowLayout>
    </Show>
  )
}
export default Index
