import { Layout, LayoutProps } from '@glm/ra-enterprise'
import { AppLocationContext } from '@glm/ra-navigation'
import { MyAppBar } from './AppBar'
import { MyMenu } from 'src/components/layout/Menu'
import * as React from 'react'
import { Breadcrumb } from 'src/components/layout/Breadcrumb'

export const AppLayout = (props: LayoutProps) => (
  <AppLocationContext>
    <Layout {...props} appBar={MyAppBar} menu={MyMenu} breadcrumb={<Breadcrumb />} />
  </AppLocationContext>
)
