import * as React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  ReferenceField,
  SelectField,
  TopToolbar,
  Labeled,
} from 'react-admin'
import Box from '@mui/material/Box'
import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PercentField from 'src/components/fields/PercentField'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import PriceField from 'src/components/fields/PriceField'
import { BanquetReserveStatus, BanquetReserveStatusList } from 'src/types/enum/BanquetStatus'
import UserField from 'src/components/fields/UserField'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { useRecordContext } from 'ra-core'
import { ModalButton } from 'src/components/Modal/ModalButton'
import { BanquetReserveFillTotalPriceModal } from '../BanquetReserveFillTotalPriceModal'

const Actions = () => {
  const record = useRecordContext()

  return (
    <TopToolbar>
      {[BanquetReserveStatus.Confirmed, BanquetReserveStatus.WaitingForFillingTotalPrice].includes(record.status) && (
        <ModalButton label={'Завершить'} modal={<BanquetReserveFillTotalPriceModal />} />
      )}
    </TopToolbar>
  )
}

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}

const BanquetOffer = () => {
  return (
    <ReferenceField reference="banquet-offer" source="banquetOfferId" label={'Предложение'}>
      <SimpleShowLayout sx={{ px: 0 }}>
        <IdShowButtonField source={'id'} label={'ID'} type={'show'} />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Ресторан'} />
        <TextField source={'restaurant.region.name'} label={'Регион'} />
        <TextField source={'comment'} label={'Комментарий к предложению'} emptyText={'Нет комментария'} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <DateField source="createdAt" label={'Создан'} showTime />
      </SimpleShowLayout>
    </ReferenceField>
  )
}

const FinishedFields = () => {
  const record = useRecordContext()

  if (record.status !== BanquetReserveStatus.FilledTotalPrice) {
    return null
  }
  return (
    <SimpleShowLayout sx={{ p: 0 }}>
      <Labeled label="Сумма чека">
        <PriceField source="total" />
      </Labeled>
      <Labeled label="Комиссия">
        <PriceField source="commission" />
      </Labeled>
      <Labeled label="Время заполнения суммы чека">
        <DateField source="totalFilledAt" showTime />
      </Labeled>
    </SimpleShowLayout>
  )
}

const Index = () => {
  return (
    <Show emptyWhileLoading actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source={'id'} label={'ID'} type={'show'} />
        <UserField
          source={'userId'}
          sourceId={'userId'}
          sourceName={'user.name'}
          sourcePhone={'user.phone'}
          label={'Клиент'}
        />
        <PhoneCallActionField source={'phone'} label={'Телефон указанный в брони'} />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Ресторан'} withPhone />
        <TextField source={'restaurant.region.name'} label={'Регион'} />
        <SelectField source={'status'} label={'Статус'} choices={BanquetReserveStatusList} />
        <TextField source={'comment'} label={'Комментарий к брони'} emptyText={'Нет комментария'} />
        <TextField source={'numOfPersons'} label={'Количество персон'} />
        <PriceField source={'amountPerPerson'} label={'Депозит на 1 персону'} />
        <PriceField source={'depositAmount'} label={'Депозит'} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <DateField source="time" label={'Время проведения'} showTime />
        <FinishedFields />
        <DateField source="createdAt" label={'Создан'} showTime />
        <Header>Предложение</Header>
        <BanquetOffer />
      </SimpleShowLayout>
    </Show>
  )
}

export default Index
