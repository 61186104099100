import * as React from 'react'
import {
  TextField,
  SelectField,
  FunctionField,
  ReferenceManyField,
  DeleteButton,
  WithRecord,
  RaRecord,
} from 'react-admin'
import { CreateInDialogButton, EditInDialogButton } from '@glm/ra-form-layout'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import { useRecordContext } from 'ra-core'
import { ViewTemplateItemForm } from 'src/resources/view-template/view-template-item/ViewTemplateItemForm'
import { ViewTemplateItemType, ViewTemplateItemTypeList } from 'src/types/enum/ViewTemplateItemType'
import { ViewTemplateItemSourceList } from 'src/types/enum/ViewTemplateItemSource'
import { ViewTemplateItemViewList } from 'src/types/enum/ViewTemplateItemView'

interface Props {
  record?: any
}

const CreateButton = () => {
  return (
    <WithRecord
      render={(record) => (
        <CreateInDialogButton record={{ viewTemplateId: record.id }} fullWidth>
          <ViewTemplateItemForm />
        </CreateInDialogButton>
      )}
    />
  )
}
const EditButton = () => {
  return (
    <EditInDialogButton fullWidth inline>
      <ViewTemplateItemForm />
    </EditInDialogButton>
  )
}
const DetailsField = (props: any) => {
  const record = useRecordContext()
  switch (record.type as ViewTemplateItemType) {
    case ViewTemplateItemType.BannerBrand:
    case ViewTemplateItemType.BannerPromo:
    case ViewTemplateItemType.SliderBrands:
    case ViewTemplateItemType.SliderPromo:
    default:
      return null
  }
}
export const ViewTemplateItemList = (props: Props) => {
  const record = useRecordContext()
  return (
    <ReferenceManyField
      reference="view-template-item"
      target="viewTemplateId"
      emptyText={'&nbps;'}
      sort={{ field: 'sort', order: 'ASC' }}
      perPage={100}
    >
      <RelatedList
        title={'Элементы'}
        record={record}
        resource={'area'}
        emptyTitle={'Нет элементов'}
        hasCreate={true}
        actions={[<CreateButton key={'create'} />]}
        createButton={<CreateButton key={'create'} />}
      >
        <FunctionField
          source={'name'}
          label={'Заголовок'}
          render={(record: RaRecord) => `${record.name ?? ''}${record.emoji ? ` ${record.emoji}` : ''}`}
        />
        <SelectField source={'type'} label={'Тип'} choices={ViewTemplateItemTypeList} />
        <SelectField source={'view'} label={'Вид'} choices={ViewTemplateItemViewList} />
        <SelectField source={'source'} label={'Источник'} choices={ViewTemplateItemSourceList} />
        <DetailsField source={'details'} label={''} />
        <TextField source={'sort'} label={'Сортировка'} />
        <TextField source={'unitSelection.name'} label={'Подборка'} />
        <TextField source={'unitSelectionCategory.name'} label={'Категория подборок'} />
        <EditListButtons
          editButton={<EditButton />}
          deleteButton={
            <DeleteButton
              confirmTitle={'Вы уверены?'}
              confirmContent={'Вы уверены что хотите удалить элемент?'}
              redirect={false}
              label={''}
            />
          }
        />
      </RelatedList>
    </ReferenceManyField>
  )
}
