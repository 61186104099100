interface Props {
  className?: string
}

export default function VsemedaPromoBgSvg(props: Props) {
  return (
    <svg
      className={props.className}
      width="267"
      height="175"
      viewBox="0 0 267 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M155.163 -19.9927L51.9771 -79.6377L-7.66834 23.5487L95.5176 83.1937L155.163 -19.9927Z" fill="white" />
      <path d="M266.578 44.3329L163.392 -15.3121L103.747 87.8743L206.933 147.519L266.578 44.3329Z" fill="white" />
      <path d="M90.8314 91.4336L-12.3545 31.7886L-71.9998 134.975L31.1861 194.62L90.8314 91.4336Z" fill="white" />
      <path d="M202.247 155.759L99.0605 96.114L39.4152 199.3L142.601 258.945L202.247 155.759Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.99 -13.3319L113.4 -18.8752L64.0108 18.7639L71.8458 -42.8964L62.1284 -48.5136L53.5019 36.06L54.0133 36.3556L122.99 -13.3319Z"
        fill="#8B229E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.878 92.6532C209.48 72.5757 176.429 55.6857 184.188 42.2581C187.809 35.9919 196.083 37.0221 202.092 40.496C207.078 43.3787 211.93 47.3783 214.691 52.0454L220.364 45.7709C217.273 41.0838 212.367 36.8824 206.486 33.4824C195.618 27.1997 180.914 26.3759 174.264 37.8853C162.957 57.4512 196.156 74.0856 187.806 88.5363C183.668 95.6976 173.982 95.0453 167.078 91.0539C161.069 87.5799 156.33 82.7931 152.916 76.8953L147.115 83.0957C150.584 89.1954 154.683 93.6126 162.482 98.1213C174.446 104.867 190.414 105.569 197.878 92.6532Z"
        fill="#8B229E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5849 92.8341L7.01025 68.2206L-30.678 133.44L11.1295 157.609L15.2678 150.448L-17.7179 131.378L-4.04669 107.721L25.3592 124.721L29.4975 117.56L0.0916009 100.559L11.6197 80.61L43.5827 99.0885L49.5849 92.8341Z"
        fill="#8B229E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.233 230.046L172.14 162.4L171.884 162.252L118.425 181.844L108.592 125.667L108.209 125.445L68.6389 192.131L77.3336 197.156L104.251 151.475L111.591 193.077L111.847 193.224L151.688 178.895L125.538 225.021L134.233 230.046Z"
        fill="#8B229E"
      />
    </svg>
  )
}
