import {
  useEditController,
  EditContextProvider,
  EditView,
  ResourceContextProvider,
  useGetRecordRepresentation,
} from 'react-admin'

export const CustomEdit = (props: any) => {
  const controllerProps = useEditController(props)

  const getRecordRepresentation = useGetRecordRepresentation(controllerProps.resource)

  const recordRepresentation = getRecordRepresentation(controllerProps.record)
  const {
    isLoading, // boolean that is true on mount, and false once the record was fetched
  } = controllerProps
  const renderBody = () => {
    if (isLoading) {
      //  return <div>Загрузка...</div>
    }
    return (
      <EditContextProvider value={controllerProps}>
        <EditView {...props} {...controllerProps} title={recordRepresentation} />
      </EditContextProvider>
    )
  }
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>{renderBody()}</ResourceContextProvider>
  ) : (
    renderBody()
  )
}
