import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps, useResourceContext } from 'react-admin'
import PromoForm from 'src/resources/promo-service-restaurants/PromoForm'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => {
  const resource = useResourceContext()
  return <span>{ServiceTypeDictionary.getPromo(resource)?.create}</span>
}
const PromoCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <PromoForm />
    </Create>
  )
}

export default PromoCreate
