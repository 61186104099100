import * as React from 'react'
import { SimpleForm, TextInput, required, BooleanInput, SimpleFormIterator, ArrayInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import LanguageInput from 'src/components/inputs/LanguageInput'

const Form = (props: any) => {
  const form = useFormContext()

  return (
    <>
      <BooleanInput source="published" label={'Активен'} fullWidth />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator inline>
          <LanguageInput
            source="languageCode"
            label={'Язык'}
            validate={required()}
            helperText={false}
            fullWidth={false}
          />
          <TextInput
            source={'name'}
            label={'Название'}
            validate={required()}
            helperText={false}
            fullWidth={false}
            variant={'outlined'}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source="slug"
        label={'URI'}
        helperText={'Название страны на английском, нижний регистр'}
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="code"
        label={'ISO code'}
        helperText={'ISO код страны два сивола например ru, нижний регистр'}
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="currency"
        label={'ISO Валюты'}
        helperText={'ISO код Валюты'}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
const CountryForm = (props: any) => {
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      defaultValues={{
        translations: [
          { name: null, languageCode: 'ru' },
          { name: null, languageCode: 'en' },
        ],
      }}
    >
      <Form />
    </SimpleForm>
  )
}
export default CountryForm
