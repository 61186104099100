import * as React from 'react'
import { Show, TextField, SimpleShowLayout, DateField, ReferenceManyField, Pagination, SelectField } from 'react-admin'
import { useRecordContext } from 'ra-core'
import Box from '@mui/material/Box'
import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PercentField from 'src/components/fields/PercentField'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import BanquetLinkField from 'src/components/fields/BanquetLinkField'
import UserLinkField from 'src/components/fields/UserLinkField'
import RelatedList from 'src/components/list/RelatedList'
import { BanquetReserveStatusList } from 'src/types/enum/BanquetStatus'
import PriceField from 'src/components/fields/PriceField'

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}

const BanquetOfferReserve = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="banquet-reserve"
      target="banquetOfferId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={5}
    >
      <RelatedList
        record={record}
        resource={'banquet-offer'}
        hasCreate={false}
        emptyTitle={'Пока нет брони'}
        bulkActionButtons={false}
      >
        <IdShowButtonField source={'id'} label={'ID'} type={'show'} />
        <UserLinkField mainSource={'user'} label={'Клиент'} />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Ресторан'} />
        <TextField source={'restaurant.region.name'} label={'Регион'} />
        <SelectField source={'status'} label={'Статус'} choices={BanquetReserveStatusList} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <PriceField source={'depositAmount'} label={'Депозит'} />
        <DateField source="createdAt" label={'Создан'} showTime />
      </RelatedList>
    </ReferenceManyField>
  )
}

const Index = () => {
  return (
    <Show emptyWhileLoading>
      <SimpleShowLayout>
        <TextField source={'id'} label={'ID'} type={'show'} />
        <BanquetLinkField source={'banquetId'} label={'Банкет'} />
        <PartnerLinkField mainSource={'partner'} label={'Партнер'} />
        <RestaurantLinkField mainSource={'restaurant'} label={'Ресторан'} />
        <TextField source={'restaurant.region.name'} label={'Регион'} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <DateField source="createdAt" label={'Создан'} showTime />
        <Header>Бронь</Header>
        <BanquetOfferReserve />
      </SimpleShowLayout>
    </Show>
  )
}

export default Index
