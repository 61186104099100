import * as React from 'react'

import styles from './index.module.scss'

import { IPromo } from 'src/types/interfaces/IPromo'
import classNames from 'classnames'
import { PromoTemplate } from 'src/types/enum/PromoTemplate'
import PromoColorImageCard from 'src/components/Promo/PromoColorImageCard'
import PromoGradientImageCard from 'src/components/Promo/PromoGradientImageCard'
import { PromoKindList } from 'src/types/enum/PromoKind'
import Typography from '@mui/material/Typography'

interface Props {
  promo: IPromo
  selected?: boolean
  onClick?: () => void
  showLabel?: boolean
}

const PromoCardWithDetails = (props: Props) => {
  return (
    <div className={classNames(styles.offset)}>
      {props.showLabel && (
        <Typography>
          {PromoKindList.find((el) => el.id === props.promo.kind)?.name}: {props.promo.innerName}
        </Typography>
      )}
      <div className={classNames(styles.root, { [styles.selected]: props.selected })} onClick={props.onClick}>
        {props.promo.template === PromoTemplate.WithImage && (
          <PromoColorImageCard
            icon={props.promo.icon}
            color={props.promo.color}
            template={props.promo.template}
            name={props.promo.name}
            badgeColor={props.promo.badgeColor}
            badge={props.promo.badge}
            image={props.promo.image}
            settings={props.promo.settings}
          />
        )}
        {props.promo.template === PromoTemplate.WithImageGradient && (
          <PromoGradientImageCard
            icon={props.promo.icon}
            color={props.promo.color}
            template={props.promo.template}
            name={props.promo.name}
            bigText={props.promo.bigText}
            shortDesc={props.promo.shortDesc}
            badgeColor={props.promo.badgeColor}
            badge={props.promo.badge}
            image={props.promo.image}
            settings={props.promo.settings}
          />
        )}
        <div className={styles.description}></div>
      </div>
    </div>
  )
}
export default PromoCardWithDetails
