import * as React from 'react'

import SyncIcon from '@mui/icons-material/Sync'
import { ModalButton } from 'src/components/Modal/ModalButton'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { useDataProvider } from 'react-admin'
import { ApiIntegrationSyncType } from 'src/types/enum/ApiIntegrationSyncType'
import { Identifier } from 'ra-core'
import { MenuModalButton } from 'src/components/Modal/MenuModalButton'
import { useMemo } from 'react'

export interface Props {
  buttonView: 'button' | 'menuItem'
  syncType: ApiIntegrationSyncType
  unitId: Identifier
  repeatable?: boolean
}

const ApiIntegrationSyncButton = (props: Props) => {
  const dataProvider = useDataProvider()

  const handleConfirm = async () => {
    await dataProvider.create('api-integration-sync', {
      data: { type: props.syncType, unitId: props.unitId, repeatable: props.repeatable },
    })
  }

  const title = useMemo(() => {
    switch (props.syncType) {
      case ApiIntegrationSyncType.Menu:
        return `Синхронизировать ${props.repeatable ? 'постоянно' : 'разово'}  меню`
      case ApiIntegrationSyncType.StopList:
        return `Синхронизировать ${props.repeatable ? 'постоянно' : 'разово'}  стоп-лист`
    }
  }, [props.syncType])
  const content = useMemo(() => {
    switch (props.syncType) {
      case ApiIntegrationSyncType.Menu:
        return `Вы уверены что хотите запустить ${props.repeatable ? 'постоянную' : 'разовую'} синхронизацию меню по API?`
      case ApiIntegrationSyncType.StopList:
        return `Вы уверены что хотите запустить ${props.repeatable ? 'постоянную' : 'разовую'} синхронизацию стоп-листа по API?`
    }
  }, [props.syncType])
  switch (props.buttonView) {
    case 'button':
      return (
        <ModalButton
          label={'ra.action.edit'}
          type={'button'}
          icon={<SyncIcon />}
          modal={
            <ConfirmDialog
              {...props}
              confirmButton={'Запустить'}
              title={title}
              content={content}
              onConfirm={handleConfirm}
            />
          }
          {...props}
        >
          <>{title}</>
        </ModalButton>
      )
    case 'menuItem':
      return (
        <MenuModalButton
          label={'ra.action.edit'}
          type={'button'}
          icon={<SyncIcon />}
          modal={
            <ConfirmDialog
              {...props}
              confirmButton={'Запустить'}
              title={title}
              content={content}
              onConfirm={handleConfirm}
            />
          }
          {...props}
        >
          <>{title}</>
        </MenuModalButton>
      )
  }
}
export default ApiIntegrationSyncButton
