export enum PaymentMethod {
  CardOnline = 'cardOnline',
  CardCourier = 'cardCourier',
  Cash = 'cash',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
  SberPay = 'sberpay',
}

export const PaymentMethodList = [
  { id: PaymentMethod.CardOnline, name: 'Оплата онлайн' },
  { id: PaymentMethod.SberPay, name: 'Оплата онлайн Sberpay' },
  { id: PaymentMethod.CardCourier, name: 'Картой курьеру' },
  { id: PaymentMethod.Cash, name: 'Наличные' },
  { id: PaymentMethod.GooglePay, name: 'Google Pay' },
  { id: PaymentMethod.ApplePay, name: 'Apple Pay' },
]
