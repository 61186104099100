import ListIcon from '@mui/icons-material/List'
import PromoUnitGroupEdit from './PromoUnitGroupEdit'
import PromoUnitGroupList from './PromoUnitGroupList'
import PromoUnitGroupCreate from './PromoUnitGroupCreate'

export default {
  create: PromoUnitGroupCreate,
  edit: PromoUnitGroupEdit,
  list: PromoUnitGroupList,
  icon: ListIcon,
}
