import * as React from 'react'
import Formatter from 'src/utils/formatters'
import { TabbedForm, FormTab, TextInput, required, AutocompleteInput, ReferenceInput } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { UnitSelectionItemList } from 'src/resources/unit-selection/items/UnitSelectionItemList'
const ItemsTab = (props: any) => {
  return (
    <FormTab {...props} label={'Рестораны'}>
      <UnitSelectionItemList {...props} />
    </FormTab>
  )
}
const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <FormTab label="Информация" path={'benefit'} {...props}>
      <TextInput
        source="name"
        label={'Название'}
        validate={[required()]}
        fullWidth
        onChange={handleNameChange}
        helperText={'Может выводиться в каталоге'}
      />
      <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
      <TextInput
        source="innerName"
        label={'Внутреннее название'}
        fullWidth
        onChange={handleNameChange}
        helperText={'Выводиться только в ЛК Партнера'}
      />
      <ReferenceInput
        source="categoryId"
        reference="unit-selection-category"
        variant={'outlined'}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={100}
      >
        <AutocompleteInput
          noOptionsText="-"
          filterToQuery={(q: string) => ({ 'name:$contL': q })}
          optionText={(i) => `${i?.name || ''}`}
          label="Категория"
          fullWidth
        />
      </ReferenceInput>
      <RichTextInput source="description" label={'Описание'} fullWidth />
      <MediaInput source="image" label={'Картинка подборки'} helperText={''} fullWidth />
      <MediaInput source="imageLarge" label={'Большая картинка'} helperText={'Для экрана подборки'} fullWidth />
    </FormTab>
  )
}
const UnitSelectionForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab />
      {props.isEdit && <ItemsTab />}
    </TabbedForm>
  )
}
export default UnitSelectionForm
