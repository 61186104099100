export enum PromoColor {
  Blue = '#474EEB',
  DarkBlue = '#402392',
  LightBlue = '#a7dada',
  Orange = '#EB8747',
  Green = '#4BBE58',
  LightGreen = '#bdd7c0',
  DarkOrange = '#EC5E20',
  White = '#FFFFFF',
  Black = '#000000',
  GrOrange = '#F58220;#E53913',
  GrGreen = '#4BBE58;#16A2FA',
  GrPurple = '#8C239F;#E53913',
  GrBlue = '#20A8F5;#3013E5',
}

export const PromoColorList = [
  { id: PromoColor.Blue, name: 'Синий' },
  { id: PromoColor.DarkBlue, name: 'Темносиний' },
  { id: PromoColor.LightBlue, name: 'Светло-синий' },
  { id: PromoColor.Orange, name: 'Оранжевый' },
  { id: PromoColor.DarkOrange, name: 'Темно оранжевый' },
  { id: PromoColor.Green, name: 'Зеленый' },
  { id: PromoColor.LightGreen, name: 'Светло-зеленый' },
  { id: PromoColor.White, name: 'Белый' },
  { id: PromoColor.GrOrange, name: 'Оранжевый градиент' },
  { id: PromoColor.GrGreen, name: 'Зеленый градиент' },
  { id: PromoColor.GrPurple, name: 'Фиолетовый градиент' },
  { id: PromoColor.GrBlue, name: 'Синий градиент' },
  { id: '#F52079;#E53913', name: '' },
  { id: '#89A956', name: '' },
  { id: '#1FAD49;#6ACD67', name: '' },
  { id: '#A069E9;#7830E3', name: '' },
  { id: '#A064EB;#3013E5', name: '' },
  { id: '#EA73FF;#C813E5', name: '' },
  { id: '#FF8C73;#E5AA13', name: '' },
  { id: '#FFA1AE;#D93680', name: '' },
  { id: '#19942D', name: '' },
  { id: '#CD845A;#56280E', name: '' },
  { id: '#B6521A', name: '' },
  { id: '#EAAF17', name: '' },
]

export const PromoTextColorList = [
  { id: PromoColor.Blue, name: 'Синий' },
  { id: PromoColor.DarkBlue, name: 'Темносиний' },
  { id: PromoColor.LightBlue, name: 'Светло-синий' },
  { id: PromoColor.Orange, name: 'Оранжевый' },
  { id: PromoColor.DarkOrange, name: 'Темно оранжевый' },
  { id: PromoColor.Green, name: 'Зеленый' },
  { id: PromoColor.LightGreen, name: 'Светло-зеленый' },
  { id: PromoColor.White, name: 'Белый' },
]
