import ListIcon from '@mui/icons-material/List'
import ProductCategoryList from 'src/resources/product-category/ProductCategoryList'
import ProductCategoryEdit from 'src/resources/product-category/ProductCategoryEdit'
import ProductCategoryCreate from 'src/resources/product-category/ProductCategoryCreate'

export default {
  create: ProductCategoryCreate,
  edit: ProductCategoryEdit,
  list: ProductCategoryList,
  icon: ListIcon,
}
