import * as React from 'react'
import Formatter from 'src/utils/formatters'
import { SimpleForm, NumberInput, FormTab, TextInput, required, SelectInput } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import { range } from 'src/utils/array'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return <FormTab label="Информация" path={'benefit'} {...props}></FormTab>
}
const PromoCodeSubscriptionForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source="code" label={'Промокод'} helperText={''} fullWidth validate={[required()]} />
      <SelectInput
        source={'month'}
        label="Кол-во месяцев"
        helperText={'Кол-во месяцев бесплатной подписки (Триала)'}
        resettable={false}
        validate={[required()]}
        choices={range(1, 24, 1).map((i) => ({ id: i, name: `${i}` }))}
      />
      <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
    </SimpleForm>
  )
}
export default PromoCodeSubscriptionForm
