import * as React from 'react'
import get from 'lodash/get'
import { FieldProps, RaRecord } from 'react-admin'
import { Typography } from '@mui/material'
import { useRecordContext } from 'ra-core'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const PercentField = (props: Props) => {
  const record = useRecordContext(props)
  const percent = get(record, props.source ?? '')

  return (
    <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
      {typeof percent === 'undefined' ? '' : percent + '%'}
    </Typography>
  )
}

export default PercentField
