import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import CountryForm from 'src/resources/country/CountryForm'
import { CustomEdit } from 'src/components/CustomEdit'
import { getTranslation } from 'src/utils/translation'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Страна {getTranslation(record, 'ru')?.name}</span> : null)

const CountryEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <CountryForm />
    </CustomEdit>
  )
}

export default CountryEdit
