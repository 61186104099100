export enum ReviewStatus {
  Moderation = 'moderation',
  Blocked = 'blocked',
  Active = 'active',
}

export const ReviewStatusList = [
  { id: ReviewStatus.Moderation, name: 'Модерация' },
  { id: ReviewStatus.Blocked, name: 'Заблокирован' },
  { id: ReviewStatus.Active, name: 'Опубликован' },
]
