import * as React from 'react'
import { Link } from 'react-router-dom'
import { useRecordContext } from 'ra-core'
import { FieldProps, RaRecord } from 'react-admin'
import get from 'lodash/get'
import { CSSProperties, ReactElement } from 'react'
type Callback = (record: RaRecord) => string
interface Props<RecordType extends RaRecord = any> extends FieldProps {
  link: string | Callback
  value?: string | Callback
  style?: CSSProperties | undefined
  icon?: ReactElement | null
}

const LinkField = (props: Props) => {
  const record = useRecordContext(props)
  const link = (typeof props.link === 'function' ? props.link(record as any) : props.link)?.replaceAll(
    '$id',
    record['id'] as string,
  )
  const value = props.value
    ? typeof props.value === 'function'
      ? props.value(record as any)
      : props.value
    : get(record, props.source ?? '')
  if (!value) {
    return null
  }
  return (
    <Link
      style={{ ...props.style, ...(props.icon ? { display: 'flex', alignItems: 'center' } : {}) }}
      to={encodeURI(link)}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {props.icon}
      {value}
    </Link>
  )
}

export default LinkField
