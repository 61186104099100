export enum PriceRating {
  Cheap = 'cheap',
  Average = 'average',
  Expensive = 'expensive',
}

export const PriceRatingList = [
  { id: PriceRating.Cheap, name: '₽' },
  { id: PriceRating.Average, name: '₽₽' },
  { id: PriceRating.Expensive, name: '₽₽₽' },
]
