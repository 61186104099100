import ListIcon from '@mui/icons-material/List'
import BrandList from 'src/resources/brand/BrandList'
import BrandEdit from 'src/resources/brand/BrandEdit'
import BrandCreate from 'src/resources/brand/BrandCreate'

export default {
  create: BrandCreate,
  edit: BrandEdit,
  list: BrandList,
  icon: ListIcon,
}
