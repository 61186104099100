import ListIcon from '@mui/icons-material/List'
import ComplaintList from 'src/resources/complaint/ComplaintList'
import ComplaintEdit from 'src/resources/complaint/ComplaintEdit'
import ComplaintCreate from 'src/resources/complaint/ComplaintCreate'
import ComplaintShow from 'src/resources/complaint/ComplaintShow'

export default {
  create: ComplaintCreate,
  edit: ComplaintEdit,
  list: ComplaintList,
  show: ComplaintShow,
  icon: ListIcon,
}
