import * as React from 'react'
import {
  RecordContextProvider,
  useDataProvider,
  useRefresh,
  required,
  NumberInput,
  TextInput,
  SelectInput,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { range } from 'src/utils/array'
interface Props {
  isShown?: boolean
  id?: number
  onClose?: () => void
}
export const PromoCodeSubscriptionGenerateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.create('subscription-promo-code/generate', { data: { ...data } })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Генерация промкодов подписки'}
        onClose={props.onClose}
        save={handleSubmit}
      >
        <TextInput
          source="codeTemplate"
          label={'Шаблон'}
          helperText={'Например TEST{code}'}
          fullWidth
          validate={[required()]}
        />
        <NumberInput source="count" label={'Кол-во промокодов'} helperText={''} fullWidth validate={[required()]} />
        <SelectInput
          source={'month'}
          label="Кол-во месяцев"
          helperText={'Кол-во месяцев бесплатной подписки (Триала)'}
          resettable={false}
          validate={[required()]}
          choices={range(1, 24, 1).map((i) => ({ id: i, name: `${i}` }))}
        />
        <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
      </ModalForm>
    </RecordContextProvider>
  )
}
