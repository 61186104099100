import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import ComplaintForm from 'src/resources/complaint/ComplaintForm'
import { CustomEdit } from 'src/components/CustomEdit'
import Formatter from 'src/utils/formatters'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) =>
  record ? (
    <span>
      Жалоба {record.id} от {Formatter.formatDateRelative(record.createdAt)}
    </span>
  ) : null

const ComplaintEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <ComplaintForm isCreation={false} />
    </CustomEdit>
  )
}
export default ComplaintEdit
