import * as React from 'react'
import Formatter from 'src/utils/formatters'
import { TabbedForm, FormTab, SelectInput, TextInput, CheckboxGroupInput, required } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import DateTimeInput from 'src/components/inputs/DateTimeInput'
import { NotificationChannelList } from 'src/types/enum/NotificationChannel'
import { PushNotificationPriorities } from 'src/types/enum/PushNotificationPriorities'
import { PushNotificationSoundList } from 'src/types/enum/PushNotificationSound'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <FormTab label="Информация" path={'benefit'} {...props}>
      <SelectInput
        validate={[required()]}
        source="type"
        label={'Тип'}
        variant={'outlined'}
        fullWidth={true}
        choices={[{ id: 'admin', name: 'Общее уведомление' }]}
      />
      <SelectInput
        validate={[required()]}
        source="targetType"
        label={'Кому отправить'}
        variant={'outlined'}
        fullWidth={true}
        choices={[{ id: 'all', name: 'Всем' }]}
      />

      <CheckboxGroupInput source="channels" label={'Каналы доставки'} choices={NotificationChannelList} />

      <TextInput
        source="title"
        label={'Название уведомления (Необязательно)'}
        helperText={'Заголовок уведомления отображается для конечных пользователей.'}
        variant={'outlined'}
        fullWidth={true}
      />
      <TextInput
        source="body"
        label={'Текст уведомления'}
        helperText={'Отображется под название уведомления'}
        variant={'outlined'}
        fullWidth={true}
        validate={required()}
      />

      <TextInput
        source="icon"
        label={'Изображение в уведомлении (Необязательно)'}
        helperText={'Вставьте общедоступную ссылку на изображение.'}
        variant={'outlined'}
        fullWidth={true}
      />
      <TextInput
        source="color"
        label={'Цвет иконки уведомления (Необязательно)'}
        helperText={'Только для Android'}
        variant={'outlined'}
        fullWidth={true}
      />

      <SelectInput
        source="sound"
        label={'Звук'}
        variant={'outlined'}
        fullWidth={true}
        choices={PushNotificationSoundList}
      />
      <TextInput
        source="tag"
        label={'Тэг (название группы уведмоления) (Необязательно)'}
        helperText={
          'Если есть активные уведомления с таким тэгом то они будут скрыты в центре уведомления. Только для Android'
        }
        variant={'outlined'}
        fullWidth={true}
      />
      <SelectInput
        source="priority"
        label={'Приоритет'}
        validate={[required()]}
        variant={'outlined'}
        fullWidth={true}
        helperText={`By default, notification messages are sent with high priority,
          and data messages are sent with normal priority.
          Normal priority optimizes the client app's battery consumption
           and should be used unless immediate delivery is required.
           For messages with normal priority, the app may receive
           the message with unspecified delay.\n
           When a message is sent with high priority, it is sent immediately,
           and the app can wake a sleeping device and open a network connection
           to your server.`}
        choices={PushNotificationPriorities}
      />

      <DateTimeInput
        label="Отправить в"
        helperText={'Отложенная отправка в определенное время'}
        source="scheduleSentAt"
        variant={'outlined'}
        fullWidth={true}
      />
    </FormTab>
  )
}
const NotificationForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab />
    </TabbedForm>
  )
}
export default NotificationForm
