import React, { ComponentProps, ReactElement, useCallback, useState } from 'react'
import { useInput } from 'ra-core'
import {
  format as dateFormat,
  addDays,
  addWeeks,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns'
import { TextInput, useTranslate } from 'react-admin'
import styles from './index.module.scss'
import { InputHelperText } from 'ra-ui-materialui'
import { DefinedRange, PickerModal } from '@glm/mui-daterange-picker-plus'
import type { DateRange } from '@glm/mui-daterange-picker-plus'
import { ru } from 'date-fns/locale'
export interface Props {
  label?: string | ReactElement
  source: string
  fullWidth?: boolean
  helperText?: ComponentProps<typeof InputHelperText>['helperText']
  record?: Record<any, any>
  resource?: string
  variant?: string

  [key: string]: any
}

const DateRangeFilterInput = (props: Props) => {
  const { ...fieldProps } = props

  const {
    options,
    label,
    source,
    fullWidth,
    format,
    parse,
    resource,
    className,
    providerOptions,
    validate,
    helperText,
    ...rest
  } = fieldProps
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null)

  const translate = useTranslate()
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const {
    id,
    isRequired,
    field: { value, onChange: onChangeInput },
    fieldState: { isTouched: touched, error },
  } = useInput({ source, ...rest })

  const handleChange = useCallback((value: DateRange) => {
    const startDate = value.startDate
      ? setHours(setMinutes(setSeconds(setMilliseconds(value.startDate, 0), 0), 0), 0)
      : null
    const endDate = value.endDate
      ? setHours(setMinutes(setSeconds(setMilliseconds(value.endDate, 9999), 59), 59), 23)
      : null
    if (startDate && endDate) {
      onChangeInput(`${startDate.toISOString()},${endDate?.toISOString()}`)
    } else {
      onChangeInput('')
    }
  }, [])
  const formatDate = (date: string) => {
    if (!date) {
      return ''
    }
    try {
      return dateFormat(new Date(date), 'dd.MM.yy')
    } catch (e) {
      return ''
    }
  }
  const formatValue = (value: any) => {
    if (!value) {
      return ''
    }
    const valueParts = value?.split(',')
    return `${formatDate(valueParts[0])} - ${formatDate(valueParts[1])}`
  }
  const getInitialDateRange = (): DateRange => {
    if (!value) {
      return {
        startDate: new Date(),
        endDate: new Date(),
      }
    }
    const valueParts = value?.split(',')
    return {
      startDate: new Date(valueParts[0]),
      endDate: new Date(valueParts[1]),
    }
  }

  const getDefaultRanges = (date: Date, locale?: any): DefinedRange[] => [
    {
      label: 'Сегодня',
      startDate: date,
      endDate: date,
    },
    {
      label: 'Вчера',
      startDate: addDays(date, -1),
      endDate: addDays(date, -1),
    },
    {
      label: 'Неделя',
      startDate: startOfWeek(date, { locale }),
      endDate: endOfWeek(date, { locale }),
    },
    {
      label: 'Прошлая неделя',
      startDate: startOfWeek(addWeeks(date, -1), { locale }),
      endDate: endOfWeek(addWeeks(date, -1), { locale }),
    },
    {
      label: 'Последние 7 дней',
      startDate: addWeeks(date, -1),
      endDate: date,
    },
    {
      label: 'Месяц',
      startDate: startOfMonth(date),
      endDate: endOfMonth(date),
    },
    {
      label: 'Прошлый месяц',
      startDate: startOfMonth(addMonths(date, -1)),
      endDate: endOfMonth(addMonths(date, -1)),
    },
    {
      label: 'Год',
      startDate: startOfYear(date),
      endDate: endOfYear(date),
    },
    {
      label: 'Прошлый год',
      startDate: startOfYear(addYears(date, -1)),
      endDate: endOfYear(addYears(date, -1)),
    },
  ]
  return (
    <>
      <TextInput
        source={source}
        onChange={(value) => {
          if (!value) {
            onChangeInput('')
          }
        }}
        resettable
        label={label}
        helperText={false}
        variant={'outlined'}
        inputProps={{
          onClick: (e) => {
            if (open) {
              handleClose()
            } else {
              setAnchorEl(e.currentTarget)
              handleOpen()
            }
          },
        }}
        format={formatValue}
      />
      <div className={styles.wrapper}>
        <PickerModal
          hideOutsideMonthDays={false}
          initialDateRange={getInitialDateRange()}
          locale={ru}
          // onChange={(range: DateRange) => handleChange(range)}
          customProps={{
            onSubmit: (range: DateRange) => {
              handleChange(range)
              handleClose()
            },
            onCloseCallback: handleClose,
          }}
          definedRanges={getDefaultRanges(new Date(), ru)}
          labels={{
            footer: {
              endDate: 'Конец',
              actions: {
                cancelBtn: 'Закрыть',
                applyBtn: 'Применить',
              },
            },
            quickSelectBtn: 'Выбрать',
          }}
          modalProps={{
            open,
            anchorEl,
            onClose: () => handleClose,
            slotProps: {
              paper: {
                sx: {
                  borderRadius: '16px',
                  boxShadow: 'rgba(0, 0, 0, 0.21) 0px 0px 4px',
                },
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        />
      </div>
    </>
  )
}

export default DateRangeFilterInput
