export enum PSCBRefundState {
  PROC = 'PROC', //	Новый	Промежуточное	Создан новый счёт.
  END = 'END', //	Оплачен	Конечное	Платёж успешно завершен.
  ERR = 'ERR', //	Ошибка	Конечное	Платёж не был оплачен вследствие ошибки (описание ошибки содержится в истории платежа).
}

export const PSCBRefundStateList = [
  { id: PSCBRefundState.PROC, name: 'В процесс' },
  { id: PSCBRefundState.END, name: 'Возвращен' },
  { id: PSCBRefundState.ERR, name: 'Ошибка' },
]
