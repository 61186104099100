import ListIcon from '@mui/icons-material/List'
import CategoryList from 'src/resources/category/CategoryList'
import CategoryEdit from 'src/resources/category/CategoryEdit'
import CategoryCreate from 'src/resources/category/CategoryCreate'

export default {
  create: CategoryCreate,
  edit: CategoryEdit,
  list: CategoryList,
  icon: ListIcon,
}
