import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import BrandForm from 'src/resources/brand/BrandForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Бренд</span>

const BrandCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <BrandForm />
    </Create>
  )
}

export default BrandCreate
