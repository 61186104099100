export enum PromoIcon {
  Scooter = 'scooter',
  Percent = 'percent',
  Ruble = 'ruble',
  OnePlusOne = 'onePlusOne',
  Gift = 'gift',
}

export const PromoIconList = [
  { id: PromoIcon.Scooter, name: 'Скутер' },
  { id: PromoIcon.Percent, name: 'Процент' },
  { id: PromoIcon.Ruble, name: 'Рубль' },
  { id: PromoIcon.OnePlusOne, name: '1+1' },
  { id: PromoIcon.Gift, name: 'Подарок' },
]
