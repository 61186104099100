import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin'
import * as React from 'react'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
}

const PartnerArrayInput = (props: Props) => {
  return (
    <ReferenceArrayInput
      allowEmpty={false}
      source={props.source}
      reference="partner"
      variant={'outlined'}
      fullWidth={true}
      enableGetChoices={({ q }) => q && q.length >= 2}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        label={props.label}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        source="id"
        optionText={(i) => (i ? `${i?.name}` : '')}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </ReferenceArrayInput>
  )
}

export default PartnerArrayInput
