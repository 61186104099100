import * as React from 'react'
import { FC, useMemo } from 'react'
import {
  DateField,
  Filter,
  ListContextProvider,
  ListProps,
  ListToolbar,
  Pagination,
  ResourceContextProvider,
  TextField,
  useListContext,
  useResourceContext,
} from 'react-admin'
import { Card } from '@mui/material'
import { useListController } from 'src/components/list/useListController'
import Grid from '@mui/material/Grid'
import CardWithIcon from 'src/components/CardWithIcon'
import Formatter from 'src/utils/formatters'
import { OrderStatusFinishedList } from 'src/types/enum/OrderStatus'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import PartnerInput from 'src/components/inputs/PartnerInput'
import PriceField from 'src/components/fields/PriceField'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { Datagrid } from 'src/components/list/Datagrid'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { ServiceType } from 'src/types/enum/ServiceType'

interface IReportOrdersData {
  data: {
    commission: number
    discount_payer: number
    total: number
    total_discount: number
    total_to_partner: number
    id: string
  }[]
  total: { sum_order_commission: number; sum_order_total: number; sum_total_to_partner: number; total: string }
}
interface Props {
  pageInfo: {
    total: number
    sum_total: number
    sum_commission: number
  }
}

const _Filter = (props: any) => {
  const resource = useResourceContext()
  const statusList = useMemo(() => {
    switch (resource) {
      case 'order-active':
        return OrderStatusFinishedList
      case 'order-finished':
      case 'order-refunding':
        return OrderStatusFinishedList
    }
  }, [resource])

  return (
    <Filter {...props}>
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn={true} />
      <IfCanAccess source={'partnerId'} label={'Партнер'} aggregator action={'show'} alwaysOn={true}>
        <PartnerInput source={'partnerId'} label={'Партнер'} alwaysOn={true} />
      </IfCanAccess>

      <RestaurantInput
        source={'restaurantId'}
        serviceType={ServiceType.Restaurants}
        label={'Ресторан'}
        alwaysOn={true}
      />
    </Filter>
  )
}
const Details = (props: Props) => {
  const listContext = useListContext()

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon title={'Кол-во заказов'} subtitle={`${props.pageInfo.total}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3}>
        <CardWithIcon title={'Сумма чеков'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_total)}`} />
      </Grid>
      <Grid item xs={2} sm={3} md={3} mb={3}>
        <CardWithIcon title={'Сумма коммисий'} subtitle={`${Formatter.formatPrice(props.pageInfo.sum_commission)}`} />
      </Grid>
    </Grid>
  )
}
const ReportPromoReservesList: FC<ListProps> = (props) => {
  const listController = useListController<any>({
    resource: 'reports/promo-reserve',
    sort: { field: 'createdAt', order: 'DESC' },
  })
  return (
    <ResourceContextProvider value={'reports/orders'}>
      <ListContextProvider value={listController as any}>
        <ListToolbar filters={<_Filter />} title={''} />
        {listController.pageInfo && <Details pageInfo={listController.pageInfo} />}
        <Card>
          <Datagrid bulkActionButtons={false} rowClick={(id, resource, record) => `/order/${id}/show`}>
            <TextField source={'id'} label={'№ брони'} sx={{ whiteSpace: 'nowrap' }} />
            <IfCanAccess aggregator action={'show'} label={'Партнер'}>
              <TextField source={'partner_name'} label={'Партнер'} />
            </IfCanAccess>
            <TextField source={'restaurant_name'} label={'Ресторан'} />
            <TextField source={'restaurant_address'} label={'Адрес'} />
            <DateField source={'createdAt'} label={'Дата внесения суммы чека'} />
            <TextField source={'promo_name'} label={'Акция'} />
            <PriceField source={'total'} label={'Сумма чека'} />
            <PriceField source={'commission'} label={'Комиссия'} />
          </Datagrid>
          <Pagination />
        </Card>
      </ListContextProvider>
    </ResourceContextProvider>
  )
}

export default ReportPromoReservesList
