import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import OrderInput from 'src/components/inputs/OrderInput'
import { Datagrid } from 'src/components/list/Datagrid'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      id: 'ID',
      amount: 'Сумма',
      orderId: 'ID заказа',
      stateDate: 'Создано (Эквайринг)',
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,
      stateDate: formatCsvDate(item.stateDate),

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Payment',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id:$eq" label={'Поиск по id'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <OrderInput source="orderId:$eq" label={'Заказ'} alwaysOn={true} resettable={true} />
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn={true} />
    </Filter>
  )
}

const PaymentList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      hasCreate={false}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Пока нет платежей клиентов'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'amount'} label={'Сумма'} />
        <TextField source={'orderId'} label={'ID заказа'} />
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
        <DateField source={'stateDate'} label={'Создано (Эквайринг)'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default PaymentList
