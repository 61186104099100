import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import PromoUnitForm from 'src/resources/promo-unit/PromoUnitForm'
import { CustomEdit } from 'src/components/CustomEdit'

const PromoUnitEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic">
      <PromoUnitForm isCreation={false} />
    </CustomEdit>
  )
}
export default PromoUnitEdit
