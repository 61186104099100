import * as React from 'react'
import { TextInput, FormTab, SelectInput, required, FormDataConsumer } from 'react-admin'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { PromoTemplate, PromoTemplateList } from 'src/types/enum/PromoTemplate'
import { PromoColor, PromoColorList, PromoTextColorList } from 'src/types/enum/PromoColor'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PromoColorImageCard from 'src/components/Promo/PromoColorImageCard'
import PromoGradientImageCard from 'src/components/Promo/PromoGradientImageCard'
import PromoColorImageBigCard from 'src/components/Promo/PromoColorImageBigCard'

const PromoFormViewTab = (props: any) => {
  return (
    <FormTab label="Вид/Описание" path={'benefit'} {...props}>
      <Grid container>
        <Grid item xs={6}>
          <TextInput source="name" label={'Название'} validate={[required()]} fullWidth />
          <SelectInput
            source="template"
            label={'Шаблон баннера'}
            choices={PromoTemplateList}
            validate={[required()]}
            fullWidth
          />
          <SelectInput
            source="color"
            label={'Цвет'}
            choices={PromoColorList}
            validate={[required()]}
            fullWidth
            optionText={(i) => (
              <div
                style={{
                  ...(i.id
                    ? {
                        width: '50%',
                        borderRadius: '4px',
                        background: i.id.includes(';')
                          ? `linear-gradient(125.99deg, ${i.id.split(';')[0]} -2.47%, ${i.id.split(';')[1]} 86.22%)`
                          : i.id,
                      }
                    : {}),
                }}
              >
                &nbsp;
              </div>
            )}
          />

          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImageGradient && (
                <TextInput source="bigText" label={'Большой текст'} fullWidth />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImageGradient && (
                <TextInput source="badge" label={'Подпись'} fullWidth />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImageGradient && (
                <TextInput source="shortDesc" label={'Короткое описание текст'} fullWidth />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImage && (
                <SelectInput
                  source="textColor"
                  label={'Цвет текста'}
                  choices={PromoTextColorList}
                  validate={[required()]}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImage && (
                <TextInput source="badge" label={'Текст на бейдже'} fullWidth />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImage && (
                <SelectInput source="badgeColor" label={'Цвет бейджа'} choices={PromoTextColorList} fullWidth />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              formData.template === PromoTemplate.WithImage && (
                <SelectInput source="badgeTextColor" label={'Цвет текста'} choices={PromoTextColorList} fullWidth />
              )
            }
          </FormDataConsumer>

          <MediaInput source="image" label={'Картинка'} fullWidth helperText={'Прозрачная png без фона'} />
          <RichTextInput source="description" label={'Описание'} fullWidth />
          <MediaInput
            source="imageLarge"
            label={'Картинка болшая'}
            helperText={
              'Необязательно если не указывать то будет использоваться фоновый цвет или фон в зависимости от шаблона'
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: '0px 15px' }}>
          <Typography variant="h6" paragraph>
            Вид карточки акции
          </Typography>

          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) => {
              switch (formData.template) {
                case PromoTemplate.WithImage:
                  return (
                    <PromoColorImageCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={formData.name}
                      badge={formData.badge}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                    />
                  )

                case PromoTemplate.WithImageGradient:
                  return (
                    <PromoGradientImageCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={formData.name}
                      badge={formData.badge}
                      bigText={formData.bigText}
                      shortDesc={formData.shortDesc}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                      text={formData.description}
                    />
                  )
              }
            }}
          </FormDataConsumer>

          <Typography variant="h6" mt={2}>
            Вид страница акции
          </Typography>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) => {
              switch (formData.template) {
                case PromoTemplate.WithImage:
                  return (
                    <PromoColorImageBigCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={formData.name}
                      badge={formData.badge}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                      imageLarge={formData.imageLarge}
                      text={formData.description}
                    />
                  )

                case PromoTemplate.WithImageGradient:
                  return (
                    <PromoColorImageBigCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={formData.name}
                      badge={formData.badge}
                      bigText={formData.bigText}
                      shortDesc={formData.shortDesc}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                      imageLarge={formData.imageLarge}
                      text={formData.description}
                    />
                  )
              }
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
    </FormTab>
  )
}
export default PromoFormViewTab
