import { useGetIdentity, usePermissions } from 'react-admin'
import { canAccess, IfCanAccessProps, UseCanAccessParams } from '@glm/ra-rbac'
import * as React from 'react'
import { AdminRole, AggregatorAdminRoles } from 'src/types/enum/AdminRole'
export const useCanAccess = ({
  action,
  resource,
  onOfResources,
  record,
}: UseCanAccessParams & { onOfResources?: string[]; isAggregator?: boolean }) => {
  const { permissions, isLoading } = usePermissions()
  const { data } = useGetIdentity()
  return isLoading
    ? { canAccess: false, isLoading: true }
    : {
        canAccess:
          (onOfResources?.length ?? 0) > 0
            ? onOfResources?.some((i) => canAccess({ permissions, action, resource: i, record }))
            : canAccess({ permissions, action, resource, record }),
        isLoading: false,
        isAggregator: AggregatorAdminRoles.includes(data?.role as AdminRole),
      }
}
interface Props extends IfCanAccessProps {
  aggregator?: boolean
  source?: string
  alwaysOn?: boolean
  defaultValue?: any
  label?: string
  onOfResources?: string[]
}
export const IfCanAccess = (props: Props) => {
  const { action, record, children, aggregator, resource, onOfResources } = props
  const { data } = useGetIdentity()

  const { canAccess, isLoading, isAggregator } = useCanAccess({
    action,
    resource: resource ?? '',
    onOfResources,
    record,
  })
  const hasRole = aggregator ? isAggregator : true
  if (!isLoading && aggregator && isAggregator && !resource) {
    return <>{children}</>
  }
  return isLoading || !canAccess || !hasRole ? null : <>{children}</>
}
