import * as React from 'react'

import SyncIcon from '@mui/icons-material/Sync'
import { ModalButton } from 'src/components/Modal/ModalButton'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { useDataProvider, useRefresh } from 'react-admin'
import { ApiIntegrationSyncType } from 'src/types/enum/ApiIntegrationSyncType'
import { Identifier } from 'ra-core'
import { MenuModalButton } from 'src/components/Modal/MenuModalButton'
import { useMemo } from 'react'

export interface Props {
  buttonView: 'button' | 'menuItem'
  syncType: ApiIntegrationSyncType
  orderId: Identifier
}

const OrderSyncApiButton = (props: Props) => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const handleConfirm = async () => {
    switch (props.syncType) {
      case ApiIntegrationSyncType.OrderSend:
        await dataProvider.create(`order/sync-send/${props.orderId}`, { data: {} })
        break
      case ApiIntegrationSyncType.OrderStatus:
        await dataProvider.create(`order/sync-status/${props.orderId}`, { data: {} })
        break
    }
    refresh()
  }

  const title = useMemo(() => {
    switch (props.syncType) {
      case ApiIntegrationSyncType.OrderStatus:
        return 'Получить статус по api'
      case ApiIntegrationSyncType.OrderSend:
        return 'Отправить заказ по api'
    }
  }, [props.syncType])
  const content = useMemo(() => {
    switch (props.syncType) {
      case ApiIntegrationSyncType.OrderStatus:
        return 'Вы уверены что хотите запустить синхронизацию  статуса заказа по API?'
      case ApiIntegrationSyncType.OrderSend:
        return 'Вы уверены что хотите запустить отправку заказа по API?'
    }
  }, [props.syncType])
  switch (props.buttonView) {
    case 'button':
      return (
        <ModalButton
          label={'ra.action.edit'}
          type={'button'}
          icon={<SyncIcon />}
          modal={
            <ConfirmDialog
              {...props}
              confirmButton={'Запустить'}
              title={title}
              content={content}
              onConfirm={handleConfirm}
            />
          }
          {...props}
        >
          <>{title}</>
        </ModalButton>
      )
    case 'menuItem':
      return (
        <MenuModalButton
          label={'ra.action.edit'}
          type={'button'}
          icon={<SyncIcon />}
          modal={
            <ConfirmDialog
              {...props}
              confirmButton={'Запустить'}
              title={title}
              content={content}
              onConfirm={handleConfirm}
            />
          }
          {...props}
        >
          <>{title}</>
        </MenuModalButton>
      )
  }
}
export default OrderSyncApiButton
