export enum ComplaintStatus {
  Created = 'created',
  Resolved = 'resolved',
  PostPoned = 'postPoned',
}

export const ComplaintStatusList = [
  { id: ComplaintStatus.Created, name: 'Создана' },
  { id: ComplaintStatus.PostPoned, name: 'Отложена' },
  { id: ComplaintStatus.Resolved, name: 'Решена' },
]
