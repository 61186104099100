import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, SelectField, DateField, ReferenceField, FunctionField, TextField } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import ChannelsField from 'src/resources/notification/components/ChannelsField'
import { NotificationTypeList } from 'src/types/enum/NotificationType'
import { NotificationTargetTypeList } from 'src/types/enum/NotificationTargetType'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Notification',
  })
}

const _Filter = (props: any) => {
  return <Filter {...props}></Filter>
}

const NotificationList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Уведомления"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField type={'show'} source={'id'} label={'ID'} />
        <SelectField source="type" choices={NotificationTypeList} label={'Тип'} />
        <SelectField source="targetType" choices={NotificationTargetTypeList} label={'Кому'} />
        <ChannelsField label={'Каналы'} />
        <DateField source={'scheduleSentAt'} label={'Отправить в'} showTime={true} />
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
        <IfCanAccess label="Клиент" source="userId" aggregator action={'show'}>
          <ReferenceField label="Клиент" source="userId" reference="user">
            <FunctionField render={(i: any) => `#${i.id} ${i.defaultProfile?.name || ''}`} />
          </ReferenceField>
        </IfCanAccess>
      </Datagrid>
    </List>
  )
}

export default NotificationList
