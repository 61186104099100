export enum ApiIntegrationSyncStatus {
  Created = 'created',
  InProgress = 'inProgress',
  Finished = 'finished',
  Error = 'error',
}

export const ApiIntegrationSyncStatusList = [
  { id: ApiIntegrationSyncStatus.Created, name: 'В очереди' },
  { id: ApiIntegrationSyncStatus.InProgress, name: 'В процессе' },
  { id: ApiIntegrationSyncStatus.Finished, name: 'Завершен' },
  { id: ApiIntegrationSyncStatus.Error, name: 'Ошибка' },
]
