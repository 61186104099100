import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import CategoryForm from 'src/resources/category/CategoryForm'
import { CustomEdit } from 'src/components/CustomEdit'

const CategoryEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic">
      <CategoryForm />
    </CustomEdit>
  )
}
export default CategoryEdit
