import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'
import { ServiceType } from '../../types/enum/ServiceType'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const PromoLinkField = (props: Props) => {
  const getLink = (record: RaRecord): string => {
    if (record?.promo?.serviceType === ServiceType.Delivery) {
      return `/promo-service-delivery/${get(record, props.source ?? 'promo.id')}`
    } else if (record?.promo?.serviceType === ServiceType.Restaurants) {
      return `/promo-service-restaurants/${get(record, props.source ?? 'promo.id')}`
    } else if (record?.promo?.serviceType === ServiceType.Coffee) {
      return `/promo-service-coffee/${get(record, props.source ?? 'promo.id')}`
    }
    return `/promo-service-delivery/${get(record, props.source ?? 'promo.id')}`
  }

  return (
    <LinkField
      link={getLink}
      source={props.source ?? 'promo.id'}
      value={(record) => (props.mainSource ? get(record, props.mainSource ?? 'promo')?.name : record?.promo?.name)}
      label={props.label}
    />
  )
}

export default PromoLinkField
