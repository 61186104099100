import * as React from 'react'
import Formatter from 'src/utils/formatters'
import { TabbedForm, FormTab, TextInput, NumberInput, required } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import PartnerInput from 'src/components/inputs/PartnerInput'
import OrderInput from 'src/components/inputs/OrderInput'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <FormTab label="Информация" path={'benefit'} {...props}>
      <PartnerInput source={'partnerId'} label={'Партнер'} validate={required()} />
      <OrderInput source="orderId" label={'Заказ'} />
      <TextInput source={'reason'} label={'Причина'} validate={required()} multiline />
      <NumberInput source={'total'} label={'Сумма'} validate={required()} />
    </FormTab>
  )
}
const PenaltyForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab />
    </TabbedForm>
  )
}
export default PenaltyForm
