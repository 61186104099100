import React, { ReactElement } from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'
interface Props {
  title: string
  children?: ReactElement | ReactElement[]
}

export default function FormGroup(props: Props) {
  return (
    <Card sx={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}>
      <CardHeader title={props.title} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>{props.children}</CardContent>
    </Card>
  )
}
