import * as React from 'react'
import {
  Show,
  EditButton,
  TextField,
  SelectField,
  DateField,
  TopToolbar,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
} from 'react-admin'
import { useRecordContext } from 'ra-core'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { ModalButton } from 'src/components/Modal/ModalButton'
import OrderLinkField from 'src/components/fields/OrderLinkField'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import { ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { ComplaintReasonList } from 'src/types/enum/ComplaintReason'
import UserField from 'src/components/fields/UserField'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { ComplaintAnswerModal } from 'src/resources/complaint/ComplaintAnswerModal'
import AssetField from 'src/components/fields/AssetField'
import { ComplaintResolveTypeList } from 'src/types/enum/ComplaintResolveType'
import PriceField from 'src/components/fields/PriceField'
import RelatedList from 'src/components/list/RelatedList'
import ComplaintChangedDataField from 'src/components/fields/ComplaintChangedDataField'
import ComplaintChangedWhatField from 'src/components/fields/ComplaintChangedWhatField'
import { ComplaintSourceList } from 'src/types/enum/ComplaintSource'
import PromoCodeLinkField from '../../components/fields/PromoCodeLinkField'

const Actions = () => {
  return (
    <TopToolbar>
      <IfCanAccess aggregator action={'edit'}>
        <Box ml={3}>
          <EditButton />
        </Box>
        <Box ml={3}>
          <ModalButton
            label={'Ответить'}
            icon={<QuestionAnswerIcon />}
            key={'import'}
            modal={<ComplaintAnswerModal />}
          />
        </Box>
      </IfCanAccess>
    </TopToolbar>
  )
}

const ComplaintChanges = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="complaint-change"
      target="complaintId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'complaint-change'}
        hasCreate={false}
        emptyTitle={'Пока нет изменений'}
        bulkActionButtons={false}
      >
        <ComplaintChangedWhatField source={'source'} />
        <ComplaintChangedDataField source={'before'} label={'До'} />
        <ComplaintChangedDataField source={'after'} label={'После'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const ComplaintShow = () => {
  return (
    <Show emptyWhileLoading actions={<Actions />}>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <SimpleShowLayout>
            <AssetField source={'unit.brand.logo'} label={''} />

            <TextField source={'unit.brand.name'} label={'Бренд'} />
            <UnitLinkField label={'Заведение'} withPhone={true} />

            <OrderLinkField label={'Заказ'} />
            <DateField source={'order.createdAt'} label={'Время заказа'} showTime={true} />
            <DateField source="createdAt" label={'Время Жалобы'} showTime={true} />

            <UserField
              source={'userId'}
              sourceId={'userId'}
              sourceName={'user.name'}
              sourcePhone={'user.phone'}
              label={'Клиент'}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <TextField source={'text'} label={'Жалоба'} />
            <TextField label={'Комментарий менеджера'} source={'adminComment'} />
            <AssetField source={'assets'} label={'Фото'} />

            <SelectField source={'resolveType'} label={'Решение'} choices={ComplaintResolveTypeList} />
            <SelectField source={'reasonType'} label={'Причина'} choices={ComplaintReasonList} />
            <SelectField source={'source'} label={'Источник'} choices={ComplaintSourceList} />
            <SelectField source={'status'} label={'Статус'} choices={ComplaintStatusList} />
            <TextField label={'Ответ'} source={'answer'} />
            <DateField source="resolvedAt" label={'Решено'} showTime={true} />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <PriceField source="withholdingAmount" label={'Сумма удержания'} />
            <PriceField source="appealAmount" label={'Сумма апеляции'} />
            <PromoCodeLinkField mainSource="promoCode" label={'Промокод'} />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <ComplaintChanges />
    </Show>
  )
}
export default ComplaintShow
