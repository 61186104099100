import * as React from 'react'
import { Pagination, TextField, ReferenceManyField, DeleteButton, BulkDeleteButton } from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import { ModalCreateButton, ModalEditButton } from 'src/components/Modal/ModalButton'
import { useRecordContext } from 'ra-core'
import AssetField from 'src/components/fields/AssetField'
import { RestaurantMenuItemCreateModal } from 'src/resources/restaurant/menu/RestaurantMenuItemCreateModal'
import { RestaurantMenuItemEditModal } from 'src/resources/restaurant/menu/RestaurantMenuItemEditModal'

interface Props {
  record?: any
}
export const RestaurantMenuItemList = (props: Props) => {
  const record = useRecordContext()
  return (
    <ReferenceManyField
      {...props}
      reference="restaurant-menu-item"
      target="restaurantId"
      sort={{ field: 'id', order: 'ASC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'restaurant-menu-item'}
        emptyTitle={'Нет меню'}
        bulkActionButtons={<BulkDeleteButton mutationMode={'pessimistic'} confirmTitle={'Удалить выбранные меню?'} />}
        actions={[<ModalCreateButton key={'create'} modal={<RestaurantMenuItemCreateModal mainRecord={record} />} />]}
        createButton={
          <>
            <ModalCreateButton key={'create'} modal={<RestaurantMenuItemCreateModal mainRecord={record} />} />
          </>
        }
      >
        <TextField source={'name'} label={'Имя'} />
        <AssetField source={'file'} label={'Файл'} />
        <TextField source={'order'} label={'Сортировка'} />
        <EditListButtons
          editButton={<ModalEditButton label={''} modal={<RestaurantMenuItemEditModal mainRecord={record} />} />}
          deleteButton={<DeleteButton redirect={false} label={''} />}
        />
      </RelatedList>
    </ReferenceManyField>
  )
}
