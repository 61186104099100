import ListIcon from '@mui/icons-material/List'
import RegionList from 'src/resources/region/RegionList'
import RegionEdit from 'src/resources/region/RegionEdit'
import RegionCreate from 'src/resources/region/RegionCreate'

export default {
  create: RegionCreate,
  edit: RegionEdit,
  list: RegionList,
  icon: ListIcon,
}
