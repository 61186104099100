export enum ServiceType {
  Delivery = 'delivery',
  Restaurants = 'restaurants',
  Coffee = 'coffee',
}

export const ServiceTypeList = [
  { id: ServiceType.Delivery, name: 'Доставка' },
  { id: ServiceType.Restaurants, name: 'Рестораны' },
  { id: ServiceType.Coffee, name: 'Кофе' },
]
