import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import SubscriptionDescriptionForm from 'src/resources/subscription-description/SubscriptionDescriptionForm'
import { CustomEdit } from 'src/components/CustomEdit'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Тарифы подписки {record.name}</span> : null)

const SubscriptionDescriptionEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <SubscriptionDescriptionForm />
    </CustomEdit>
  )
}
export default SubscriptionDescriptionEdit
