import ListIcon from '@mui/icons-material/List'
import ViewTemplateList from 'src/resources/view-template/ViewTemplateList'
import ViewTemplateEdit from 'src/resources/view-template/ViewTemplateEdit'
import ViewTemplateCreate from 'src/resources/view-template/ViewTemplateCreate'

export default {
  create: ViewTemplateCreate,
  edit: ViewTemplateEdit,
  list: ViewTemplateList,
  icon: ListIcon,
}
