import { MultiLevelMenu } from '@glm/ra-navigation'
import DashboardIcon from '@mui/icons-material/Dashboard'
import {
  CurrencyUsdOff,
  ArchiveClock,
  ArchiveRemove,
  Api,
  Newspaper,
  DotsGrid,
  Archive,
  ArchiveCheck,
  Cogs,
  AccountTie,
  Account,
  NoteText,
  MapMarkerRadius,
  Store,
  AccountTieHat,
  OfficeBuilding,
  CommentTextMultiple,
  TicketPercent,
  CartPercent,
  ListBox,
  Food,
  MessageAlert,
  Finance,
  Numeric,
  ReceiptTextCheckOutline,
  Apps,
  Group,
} from 'mdi-material-ui'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import React, { ReactElement } from 'react'
import { TotalWrapper, useTotalContext } from 'src/context/total_state'
import Badge from '@mui/material/Badge'

const MenuItemComplaint = () => {
  const totalContext = useTotalContext()
  return (
    <MultiLevelMenu.Item
      name="complaint"
      to="/complaint"
      label={'Жалобы'}
      icon={
        totalContext.complaintsCreated > 0 ? (
          <Badge badgeContent={totalContext.complaintsCreated} color="error">
            <MessageAlert />
          </Badge>
        ) : (
          <MessageAlert />
        )
      }
    />
  )
}
const MenuItemPromoReserveConfirmation = () => {
  const totalContext = useTotalContext()
  return (
    <MultiLevelMenu.Item
      name={'promo-reserve.confirmation'}
      to={'/promo-reserve-confirmation'}
      label="В обработке"
      icon={
        totalContext.PromoReserveConfirmation > 0 ? (
          <Badge badgeContent={totalContext.PromoReserveConfirmation} color="error">
            <ArchiveClock />
          </Badge>
        ) : (
          <ArchiveClock />
        )
      }
    />
  )
}

const MenuItemPromoReserve = (props: { children: ReactElement[] }) => {
  const totalContext = useTotalContext()

  return (
    <MultiLevelMenu.Item
      name="promo-reserve"
      to={'/promo-reserve'}
      label="Брони"
      icon={
        totalContext.PromoReserveConfirmation > 0 ? (
          <Badge badgeContent={totalContext.PromoReserveConfirmation} color="error">
            <Archive />
          </Badge>
        ) : (
          <Archive />
        )
      }
    >
      {props.children}
    </MultiLevelMenu.Item>
  )
}

export const MyMenu = () => (
  <TotalWrapper>
    <MultiLevelMenu>
      {/* <MultiLevelMenu.Item name="dashboard" to="/" label="Сводка" icon={<ChartDonutVariant />} />*/}
      <IfCanAccess resource={'order'} action={'list'}>
        <MultiLevelMenu.Item name="order" to={'/order'} label="Заказы доставка" icon={<Archive />}>
          <MultiLevelMenu.Item name={'order-active'} to={'/order-active'} label="В обработке" icon={<ArchiveClock />} />
          <MultiLevelMenu.Item name="order-finished" to="/order-finished" label="Завершенные" icon={<ArchiveCheck />} />
          <MultiLevelMenu.Item
            name="order-refunding"
            to="/order-refunding"
            label="Ожидают возврата"
            icon={<ArchiveRemove />}
          />
        </MultiLevelMenu.Item>
      </IfCanAccess>
      <IfCanAccess resource={'promo-reserve'} action={'list'}>
        <MenuItemPromoReserve>
          <MenuItemPromoReserveConfirmation />
          <MultiLevelMenu.Item
            name="promo-reserve.approved"
            to="/promo-reserve-approved"
            label="Подтврежденные"
            icon={<ArchiveCheck />}
          />
          <MultiLevelMenu.Item
            name="promo-reserve.cancelled"
            to="/promo-reserve-canceled"
            label={'Отмененные'}
            icon={<ArchiveRemove />}
          />
        </MenuItemPromoReserve>
        <IfCanAccess resource={'banquet'} action={'list'}>
          <MultiLevelMenu.Item name="banquet" to="/banquet" label="Банкеты" icon={<Archive />}>
            <MultiLevelMenu.Item name="banquet-reserve" to="/banquet-reserve" label="Брони" icon={<ListBox />} />
            <MultiLevelMenu.Item name="banquet" to="/banquet" label="Заявки" icon={<ListBox />} />
            <MultiLevelMenu.Item name="banquet-offer" to="/banquet-offer" label="Предложения" icon={<ListBox />} />
          </MultiLevelMenu.Item>
        </IfCanAccess>
      </IfCanAccess>
      <IfCanAccess resource={'complaint'} action={'list'}>
        <MenuItemComplaint />
      </IfCanAccess>
      <IfCanAccess resource={'review'} action={'list'}>
        <MultiLevelMenu.Item name="review" to="/review" label="Отзывы" icon={<CommentTextMultiple />} />
      </IfCanAccess>

      <IfCanAccess resource={'refund'} action={'list'}>
        <MultiLevelMenu.Item name="refund" to={'/refund'} label="Возвраты" icon={<Archive />} />
      </IfCanAccess>
      <IfCanAccess resource={'payment'} action={'list'}>
        <MultiLevelMenu.Item name="payment" to={'/payment'} label="Платежи клиентов" icon={<Archive />} />
      </IfCanAccess>
      <IfCanAccess resource={'user'} action={'list'}>
        <MultiLevelMenu.Item name="user" to="/user" label="Клиенты" icon={<Account />} />
      </IfCanAccess>
      <IfCanAccess resource={'product'} action={'list'}>
        <MultiLevelMenu.Item name="menu" to="/product-category" label="Меню" icon={<Food />}>
          <MultiLevelMenu.Item name="product-category" to="/product-category" label="Категории" icon={<ListBox />} />
          <MultiLevelMenu.Item name="product" to="/product" label="Блюда" icon={<Food />} />
        </MultiLevelMenu.Item>
      </IfCanAccess>
      <IfCanAccess aggregator resource={'partner'} action={'list'}>
        <MultiLevelMenu.Item name="partner" to="/partner" label="Партнеры" icon={<AccountTie />} />
      </IfCanAccess>
      <IfCanAccess resource={'brand'} action={'list'}>
        <MultiLevelMenu.Item name="brand" to="/brand" label="Бренды" icon={<OfficeBuilding />} />
      </IfCanAccess>
      <IfCanAccess resource={'unit'} action={'list'}>
        <MultiLevelMenu.Item name="unit" to="/unit" label="Заведения" icon={<Store />} />
      </IfCanAccess>
      <IfCanAccess resource={'promo-service-delivery'} action={'list'}>
        <MultiLevelMenu.Item
          name="promo"
          to="/promo-service-delivery"
          label="Акции для Доставки"
          icon={<TicketPercent />}
        />
      </IfCanAccess>
      <IfCanAccess resource={'promo-unit'} action={'list'}>
        <MultiLevelMenu.Item
          name="promo-unit"
          to="/promo-unit"
          label="Акции заведений доставки"
          icon={<CartPercent />}
        />
        <MultiLevelMenu.Item
          name="promo-unit-group"
          to="/promo-unit-group"
          label="Группы акций заведения"
          icon={<Group />}
        />
      </IfCanAccess>
      <IfCanAccess resource={'restaurant'} action={'list'}>
        <MultiLevelMenu.Item name="restaurant" to="/restaurant" label="Рестораны" icon={<Store />} />
      </IfCanAccess>
      <IfCanAccess resource={'promo-service-restaurants'} action={'list'}>
        <MultiLevelMenu.Item
          name="promo-service-restaurants"
          to="/promo-service-restaurants"
          label="Акции для Ресторанов"
          icon={<TicketPercent />}
        />
      </IfCanAccess>
      <IfCanAccess resource={'promo-restaurant'} action={'list'}>
        <MultiLevelMenu.Item
          name="promo-restaurant"
          to="/promo-restaurant"
          label="Акции ресторанов"
          icon={<CartPercent />}
        />
      </IfCanAccess>

      <IfCanAccess resource={'restaurant-order'} action={'list'}>
        <MultiLevelMenu.Item
          name={'restaurant-order'}
          to={'/restaurant-order'}
          label="Заказы ресторанов"
          icon={<ReceiptTextCheckOutline />}
        />
      </IfCanAccess>

      <IfCanAccess resource={'restaurant-coffee'} action={'list'}>
        <MultiLevelMenu.Item name="restaurant-coffee" to="/restaurant-coffee" label="Кофейни" icon={<Store />} />
      </IfCanAccess>
      <IfCanAccess resource={'promo-service-coffee'} action={'list'}>
        <MultiLevelMenu.Item
          name="promo-service-coffee"
          to="/promo-service-coffee"
          label="Акции для Кофеен"
          icon={<TicketPercent />}
        />
      </IfCanAccess>
      <IfCanAccess resource={'promo-restaurant-coffee'} action={'list'}>
        <MultiLevelMenu.Item
          name="promo-restaurant-coffee"
          to="/promo-restaurant-coffee"
          label="Акции Кофеен"
          icon={<CartPercent />}
        />
      </IfCanAccess>

      <IfCanAccess resource={'promo-code'} action={'list'}>
        <MultiLevelMenu.Item name="promo-code" to="/promo-code" label="Промокоды доставки" icon={<Numeric />} />
      </IfCanAccess>
      <IfCanAccess resource={'subscription-promo-code'} action={'list'}>
        <MultiLevelMenu.Item
          name="subscription-promo-code"
          to="/subscription-promo-code"
          label="Промокоды подписки"
          icon={<Numeric />}
        />
      </IfCanAccess>
      {/*<IfCanAccess resource={'auction'} action={'list'}>
            <MultiLevelMenu.Item name="auction" to="/auction" label="Продвижение" icon={<RocketLaunch />}  />
        </IfCanAccess>*/}

      <IfCanAccess resource={'penalty'} action={'list'}>
        <MultiLevelMenu.Item name="penalty" to="/penalty" label="Штрафы" icon={<CurrencyUsdOff />} />
      </IfCanAccess>

      <IfCanAccess onOfResources={['reports', 'reports-auction']} action={'list'}>
        <MultiLevelMenu.Item name="reports" to="/reports" label="Отчеты" icon={<Finance />}>
          <IfCanAccess resource={'reports-orders'} action={'list'}>
            <MultiLevelMenu.Item name="reports/orders" to="/reports-orders" label="Заказы" icon={<Finance />} />
          </IfCanAccess>
          <IfCanAccess resource={'reports-promo-reserves'} action={'list'}>
            <MultiLevelMenu.Item
              name="reports/promo-reserves"
              to="/reports-promo-reserves"
              label="Брони"
              icon={<Finance />}
            />
          </IfCanAccess>
          {/*<IfCanAccess resource={'reports-auction'} action={'list'}>
                <MultiLevelMenu.Item name="reports/auction" to="/reports-auction" label="Продвижение" icon={<Finance  />} />
            </IfCanAccess>*/}
        </MultiLevelMenu.Item>
      </IfCanAccess>
      <IfCanAccess
        onOfResources={['category-deliver', 'category-local', 'unit-selection', 'unit-selection-category', 'page']}
        action={'list'}
      >
        <MultiLevelMenu.Item name="content" to="/category-delivery" label="Контент" icon={<DashboardIcon />}>
          <IfCanAccess resource={'category-delivery'} action={'list'}>
            <MultiLevelMenu.Item
              name="category-delivery"
              to="/category-delivery"
              label="Товарные разделы Доставка"
              icon={<ListBox />}
            />
          </IfCanAccess>
          <IfCanAccess resource={'category-local'} action={'list'}>
            <MultiLevelMenu.Item
              name="category-local"
              to="/category-local"
              label="Товарные разделы Рестораны"
              icon={<ListBox />}
            />
          </IfCanAccess>
          <IfCanAccess resource={'unit-selection'} action={'list'}>
            <MultiLevelMenu.Item
              name="unit-selection"
              to="/unit-selection"
              label="Подборки ресторанов"
              icon={<ListBox />}
            />
          </IfCanAccess>
          <IfCanAccess resource={'unit-selection-category'} action={'list'}>
            <MultiLevelMenu.Item
              name="unit-selection-category"
              to="/unit-selection-category"
              label="Категории Подборок ресторанов"
              icon={<ListBox />}
            />
          </IfCanAccess>
          <IfCanAccess resource={'page'} action={'list'}>
            <MultiLevelMenu.Item name="page" to="/page" label="Страницы" icon={<NoteText />} />
          </IfCanAccess>

          <IfCanAccess resource={'article'} action={'list'}>
            <MultiLevelMenu.Item name="article" to="/article" label="Статьи" icon={<Newspaper />} />
          </IfCanAccess>
          <IfCanAccess resource={'article-category'} action={'list'}>
            <MultiLevelMenu.Item
              name="article-category"
              to="/article-category"
              label="Категории статей"
              icon={<Newspaper />}
            />
          </IfCanAccess>
        </MultiLevelMenu.Item>
      </IfCanAccess>

      <IfCanAccess resource={'partner-offer'} action={'list'}>
        <MultiLevelMenu.Item
          name="partner-offer"
          to="/partner-offer"
          label="Индивидуальные условия"
          icon={<ListBox />}
        />
        <MultiLevelMenu.Item name="accounting" to="/accounting" label="Бухгалтерия" icon={<ListBox />} />
      </IfCanAccess>
      <IfCanAccess resource={'admin'} action={'list'}>
        <MultiLevelMenu.Item name="admin" to="/admin" label="Пользователи" icon={<AccountTieHat />} />
      </IfCanAccess>

      <IfCanAccess
        onOfResources={[
          'country',
          'region',
          'view-template',
          'api-integration-sync',
          'tariff',
          'subscription-description',
          'notification',
        ]}
        action={'list'}
      >
        <MultiLevelMenu.Item name="settings" to="/region" label="Настройки" icon={<Cogs />}>
          <IfCanAccess resource={'country'} action={'list'}>
            <MultiLevelMenu.Item name="country" to="/country" label="Страны" icon={<MapMarkerRadius />} />
          </IfCanAccess>
          <IfCanAccess resource={'region'} action={'list'}>
            <MultiLevelMenu.Item name="region" to="/region" label="Регионы" icon={<MapMarkerRadius />} />
          </IfCanAccess>
          <IfCanAccess resource={'view-template'} action={'list'}>
            <MultiLevelMenu.Item name="view-template" to="/view-template" label="Шаблоны вывода" icon={<DotsGrid />} />
          </IfCanAccess>
          <IfCanAccess resource={'api-integration-sync'} action={'list'}>
            <MultiLevelMenu.Item
              name="api-integration-sync"
              to="/api-integration-sync"
              label="Api синхронизации"
              icon={<Api />}
            />
          </IfCanAccess>

          <IfCanAccess resource={'tariff'} action={'list'}>
            <MultiLevelMenu.Item name="tariff" to="/tariff" label="Тарифы" icon={<Api />} />
          </IfCanAccess>
          <IfCanAccess resource={'subscription-description'} action={'list'}>
            <MultiLevelMenu.Item
              name="subscription-description"
              to="/subscription-description"
              label="Описание подписок"
              icon={<DotsGrid />}
            />
          </IfCanAccess>
          <IfCanAccess resource={'notification'} action={'list'}>
            <MultiLevelMenu.Item name="notification" to="/notification" label="Уведомления" icon={<Api />} />
          </IfCanAccess>
          <IfCanAccess resource={'app-settings'} action={'list'}>
            <MultiLevelMenu.Item name="app-settings" to="/app-settings" label="Приложения" icon={<Apps />} />
          </IfCanAccess>
        </MultiLevelMenu.Item>
      </IfCanAccess>
    </MultiLevelMenu>
  </TotalWrapper>
)
