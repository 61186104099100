import * as React from 'react'
import { RecordContextProvider, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'
import { useTotalContext } from 'src/context/total_state'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}
export const PromoReserveApproveModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const totalContext = useTotalContext()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update('promo-reserve/setApproved', {
      id: record.id,
      data: {},
      previousData: record,
    })
    if (props.onClose) {
      props.onClose()
    }
    refresh()
    totalContext.fetchPromoReserve()
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'xs'}
        resource={'area'}
        title={'Подтвердить бронь'}
        onClose={props.onClose}
        saveButtonLabel={'Подтвердить'}
        cancelButtonLabel={'Назад'}
        save={handleSubmit}
      >
        <Typography variant="body2" align={'center'}>
          Вы уверены что хотите подтвердить бронь?
        </Typography>
      </ModalForm>
    </RecordContextProvider>
  )
}
