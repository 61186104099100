import * as React from 'react'
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useDataProvider, useNotify } from 'react-admin'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Pagination from '@mui/material/Pagination'
import MuiGridList from '@mui/material/ImageList'
import { Button } from '@mui/material'
import { Accept, DropEvent, ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import Cookies from 'js-cookie'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import styles from './index.module.scss'
import { IAsset } from 'src/components/types'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Breakpoint, Theme, useTheme } from '@mui/material/styles'
import AssetListItem from 'src/components/AssetListItem'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Converter from 'src/utils/converter'
import { FileUploadAcceptType } from 'src/types'
import Formatter from 'src/utils/formatters'
import { MAX_FILE_UPLOAD_SIZE } from 'src/types/contstants'
import ModalMediaErrors from 'src/components/ModalMedia/ModalMediaErrors'
import ModalMediaUploading from 'src/components/ModalMedia/ModalMediaUploading'
import { AUTH_TOKEN_STORAGE_KEY } from 'src/common/providers/AuthProvider'

interface ModalMediaProps {
  isShown?: boolean
  onClose?: () => void
  title?: string
  fullScreen?: boolean
  fullWidth?: boolean
  filter?: any
  isMulti: boolean
}

interface ModalMediaViewProps {
  onSelect?: (records: IAsset[]) => void
  onClose?: () => void
  maxSize?: number
  accept?: FileUploadAcceptType[]
  filter?: any
  isMulti: boolean
  maxFiles?: number
}

type BreakpointOrNull = Breakpoint | null

function useWidth() {
  const theme: Theme = useTheme()
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

const ModalMedia = (props: ModalMediaProps & ModalMediaViewProps) => {
  return (
    <Dialog
      fullScreen={props.fullScreen ?? true}
      fullWidth={props.fullWidth ?? true}
      open={props.isShown ?? false}
      onClose={props.onClose}
      aria-label={props.title}
    >
      {props.isShown && <ModalMediaView {...props} />}
    </Dialog>
  )
}
ModalMedia.defaultTypes = {
  fullWidth: true,
  accept: [FileUploadAcceptType.Media, FileUploadAcceptType.Pdf],
}

const ModalMediaView = (props: ModalMediaViewProps) => {
  const { onSelect, maxSize, accept } = props
  const notify = useNotify()
  const [isUploading, setIsUploading] = useState(false)
  const [currentUploadingFile, setCurrentUploadingFile] = useState<File | null>(null)
  const [currentUploadingAmount, setCurrentUploadingAmount] = useState(0)
  const [currentFileProgress, setCurrentFileProgress] = useState(0)
  const [currentTotalUploaded, setCurrentTotalUploaded] = useState(0)
  const [currentSuccessUploaded, setCurrentSuccessUploaded] = useState(0)
  const [data, setData] = useState<IAsset[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [selectedRecords, setSelectedRecords] = useState<IAsset[]>([])
  const dataProvider = useDataProvider()
  const abortController = useRef<CancelTokenSource | null>(null)
  const width = useWidth()
  const [errors, setErrors] = useState<string[]>([])
  const selectedRecordsIds = useMemo(() => selectedRecords.map((i) => i.id), [selectedRecords])
  const perPage = 54
  const loadPage = async (page: number) => {
    setPage(page)
    const res = await dataProvider.getList('asset', {
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
      filter: props.filter ?? {},
    })
    setData(res.data as IAsset[])
    setTotal(res.total as number)
  }
  useEffect(() => {
    loadPage(1)
  }, [])
  const handleChangePage = async (_e: React.ChangeEvent<unknown>, page: number) => {
    loadPage(page)
  }
  const handleSelectAsset = (record: IAsset) => {
    if (props.isMulti) {
      if (selectedRecords.find((i) => i.id === record.id)) {
        setSelectedRecords((i) => i.filter((i) => i.id !== record.id))
      } else {
        setSelectedRecords((i) => [...i, record])
      }
    } else {
      if (selectedRecords.find((i) => i.id === record.id)) {
        setSelectedRecords([])
      } else {
        setSelectedRecords([record])
      }
    }
  }
  const handleChoose: MouseEventHandler = (e) => {
    e.stopPropagation()
    if (onSelect) {
      onSelect(selectedRecords!)
    }
  }
  const handleUploadCancel = () => abortController.current && abortController.current!.cancel()

  const uploadFiles = async (files: File[]) => {
    const cancelToken = axios.CancelToken
    const source = cancelToken.source()
    const token = Cookies.get(AUTH_TOKEN_STORAGE_KEY)

    abortController.current = source
    let errors = []
    const uploaded: IAsset[] = []
    let i = 0
    for (const file of files) {
      setCurrentUploadingFile(file)
      const form = new FormData()
      form.append('file', file)
      const axiosOptions = {
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
        onUploadProgress: (e: ProgressEvent) => {
          setCurrentFileProgress(e.loaded / e.total)
        },
      }
      try {
        const uploadRes = await axios.put<IAsset>(
          `${process.env.REACT_APP_API_URL || ''}/admin/api/asset/upload`,
          form,
          axiosOptions,
        )
        uploaded.push(uploadRes.data)
        setCurrentSuccessUploaded(i + 1)
      } catch (e) {
        if (e instanceof CanceledError) {
          setSelectedRecords((i) => [...i, ...uploaded])
          return
        }
        errors.push(`${file.name}: Ошибка загрузки файла`)
      }
      ++i
      setCurrentTotalUploaded(i)
    }
    setSelectedRecords((i) => [...i, ...uploaded])
    setErrors(errors)
  }
  const onDrop = async (newFiles: File[]) => {
    setCurrentUploadingFile(null)
    setCurrentUploadingAmount(newFiles.length)
    setCurrentFileProgress(0)
    setCurrentTotalUploaded(0)
    setCurrentSuccessUploaded(0)
    setIsUploading(true)
    await uploadFiles(newFiles)
    setIsUploading(false)
    await loadPage(1)
  }
  const getColsForWidth = (width: string) => {
    if (width === 'xs') return 2
    if (width === 'sm') return 3
    if (width === 'md') return 3
    if (width === 'lg') return 6
    return 6
  }
  const formatDropRejectedError = (rejection: FileRejection, index: number): string => {
    const error = rejection.errors[index]
    let errorName = ''
    switch (error.code) {
      case ErrorCode.FileInvalidType:
        errorName = 'Неверный формат файла'
        break
      case ErrorCode.FileTooLarge:
        errorName = `Максимальный размер файла ${Formatter.formatSize(props.maxSize ?? MAX_FILE_UPLOAD_SIZE)}`
        break
      case ErrorCode.FileTooSmall:
        errorName = `Минимальный размер файла ${error.message}`
        break
      case ErrorCode.TooManyFiles:
        errorName = `Максимальное кол-во файлов ${props.maxFiles}`
        break
    }
    return `${rejection.file.name}: ${errorName}`
  }
  const onDropRejected = (fileRejections: FileRejection[], event: DropEvent) => {
    if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
      if (props.isMulti) {
        setErrors(fileRejections.map((r) => r.errors.map((i, index) => formatDropRejectedError(r, index))).flat())
      } else {
        notify(formatDropRejectedError(fileRejections[0], 0), { type: 'error' })
      }
    }
  }

  const dropzoneAccept: Accept = useMemo(() => {
    let obj = {}
    const arr = (props.accept ?? []).map((i) => Converter.getFileUploadAccept(i)) ?? ({} as Accept)
    arr.forEach((i) => {
      obj = { ...obj, ...i }
    })
    return obj
  }, [props.accept])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: dropzoneAccept,
    maxSize: props.maxSize ?? MAX_FILE_UPLOAD_SIZE,
    multiple: true,
    onDrop,
    onDropRejected,
  })
  const handleClose: MouseEventHandler = (e) => {
    e.stopPropagation()
    props.onClose?.()
  }
  const handleCloseErrors = () => {
    setErrors([])
  }
  return (
    <div className={styles.root} {...getRootProps()}>
      <DialogTitle>
        <div className={styles.header}>
          <Typography variant="h6" className={styles.title}>
            {props.isMulti ? 'Выберите файлы' : 'Выберите файл'}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.toolBar}></div>
      </DialogTitle>

      <DialogContent className={styles.dialogContent} onClick={(e) => e.stopPropagation()}>
        <MuiGridList rowHeight={180} cols={getColsForWidth(width)} className={styles.gridList}>
          {data.map((item) => (
            <AssetListItem
              key={item.id}
              item={item}
              onSelect={handleSelectAsset}
              isActive={selectedRecordsIds.includes(item.id)}
            />
          ))}
        </MuiGridList>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary">
          Загрузить файл <input {...getInputProps()} />
        </Button>
        <div className={styles.pagination}>
          <Pagination
            onClick={(e) => e.stopPropagation()}
            page={page}
            size={'small'}
            count={Math.ceil(total / perPage)}
            onChange={handleChangePage}
          />
        </div>
        <Button disabled={selectedRecords.length === 0} variant="contained" color="primary" onClick={handleChoose}>
          Выбрать{props.isMulti ? ` ${selectedRecords.length}` : ''}
        </Button>
      </DialogActions>
      {isDragActive && (
        <div className={styles.dropZone}>
          <div className={styles.dropZoneWrapper}>
            <div className={styles.dropZoneText}>Перетащите файлы сюда</div>
          </div>
        </div>
      )}

      {isUploading && (
        <ModalMediaUploading
          onCancel={handleUploadCancel}
          totalUploaded={currentTotalUploaded}
          total={currentUploadingAmount}
          currentFileProgress={currentFileProgress}
          currentFileName={currentUploadingFile?.name ?? 'sd sdsdsd'}
        />
      )}
      {!isUploading && errors.length > 0 && (
        <ModalMediaErrors errors={errors} onClose={handleCloseErrors} totalUploaded={currentSuccessUploaded} />
      )}
    </div>
  )
}

ModalMediaView.defaultProps = {}

export default ModalMedia
