import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps, useResourceContext } from 'react-admin'
import RestaurantForm from 'src/resources/restaurant/RestaurantForm'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => {
  const resource = useResourceContext()
  return <span>{ServiceTypeDictionary.getServiceEntity(resource)?.create}</span>
}

const RestaurantCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <RestaurantForm />
    </Create>
  )
}

export default RestaurantCreate
