import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import OrderForm from 'src/resources/order/OrderForm'
import { CustomEdit } from 'src/components/CustomEdit'

const OrderEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic">
      <OrderForm />
    </CustomEdit>
  )
}
export default OrderEdit
