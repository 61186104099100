import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps, useResourceContext } from 'react-admin'
import { ArticleForm } from 'src/resources/article/ArticleForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => {
  const resource = useResourceContext()
  const isNews = resource === 'article-news'
  return <span>Новая {isNews ? 'Новость' : 'Статья'}</span>
}

const ArticleCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <ArticleForm />
    </Create>
  )
}

export default ArticleCreate
