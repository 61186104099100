import { AutocompleteInput, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { getTranslation } from 'src/utils/translation'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  validate?: any
  fullWidth?: boolean
  onChange?: (val: any) => void
  emptyText?: string
  helperText?: string
}
const CountryInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source="countryId"
      reference="country"
      resettable={props.resettable}
      filterToQuery={(searchText: string) => ({ ...(searchText ? { 'translations.||$eq': searchText } : {}) })}
      filter={{ 'translations.languageCode': 'ru' }}
      sort={{ field: 'translations.name', order: 'ASC' }}
      perPage={100}
    >
      <AutocompleteInput
        optionText={(i) => `${getTranslation(i)?.name || ''}`}
        label={props.label ?? 'Страна'}
        emptyText={props.emptyText}
        validate={props.validate}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </ReferenceInput>
  )
}

export default CountryInput
