import * as React from 'react'
import { SimpleForm, SelectInput, TextInput, BooleanInput, required } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { ViewTemplateType, ViewTemplateTypeList } from 'src/types/enum/ViewTemplateType'
import { ViewTemplateItemList } from 'src/resources/view-template/view-template-item/ViewTemplateItemList'
import { ServiceTypeList } from 'src/types/enum/ServiceType'

const Form = (props: any) => {
  const form = useFormContext()

  return (
    <>
      <TextInput source={'name'} label={'Название'} validate={[required()]} />
      <SelectInput source="serviceType" label={'Сервис'} choices={ServiceTypeList} />
      <SelectInput source={'type'} label={'Тип'} choices={ViewTemplateTypeList} />
      <BooleanInput source={'isDefault'} label={'По умолчанию'} />
    </>
  )
}
const ViewTemplateForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ type: ViewTemplateType.Main }}>
      <Form />
      {props.isEdit && <ViewTemplateItemList />}
    </SimpleForm>
  )
}
export default ViewTemplateForm
