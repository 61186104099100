import ListIcon from '@mui/icons-material/List'
import UnitList from 'src/resources/unit/UnitList'
import UnitEdit from 'src/resources/unit/UnitEdit'
import UnitCreate from 'src/resources/unit/UnitCreate'

export default {
  create: UnitCreate,
  edit: UnitEdit,
  list: UnitList,
  icon: ListIcon,
}
