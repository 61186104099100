import PersonIcon from '@mui/icons-material/Person'
import PartnerOfferList from './PartnerOfferList'
import PartnerOfferEdit from './PartnerOfferEdit'
import PartnerOfferCreate from './PartnerOfferCreate'

export default {
  create: PartnerOfferCreate,
  edit: PartnerOfferEdit,
  list: PartnerOfferList,
  icon: PersonIcon,
}
