import * as React from 'react'
import * as _ from 'lodash'
import get from 'lodash/get'
import { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import { useMemo } from 'react'
import styles from './index.module.scss'
import { ComplaintFieldsMap } from 'src/types/enum/ComplaintFields'
import { ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { format } from 'date-fns'
import { ComplaintResolveTypeList } from 'src/types/enum/ComplaintResolveType'
import { ComplaintReasonList } from 'src/types/enum/ComplaintReason'
import { IAsset } from 'src/components/types'
import { getMediaPath } from 'src/utils/media'
import { ComplaintSourceList } from 'src/types/enum/ComplaintSource'

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {}

const assetsFields = ['assetsAdded', 'assetsRemoved']

export const ComplaintChangedDataField = (props: Props) => {
  const { source } = props
  const record = useRecordContext(props)

  const value = useMemo(() => {
    const value = get(record, source ?? '')
    const finalValueObj = Array.isArray(value) ? value.reduce((acc, value) => ({ ...acc, ...value }), {}) : { ...value }

    if (value?.paymentData?.data?.orderId) {
      finalValueObj.orderId = value.paymentData?.data?.orderId
    }
    if (value.paymentData?.data?.orderNumber) {
      finalValueObj.orderNumber = value.paymentData?.data?.orderNumber
    }
    delete finalValueObj.paymentData
    //
    return finalValueObj
  }, [])

  const list = useMemo((): Array<{ label: string; value: string; key: string; isAsset: boolean }> => {
    if (Array.isArray(value)) {
      return []
    }
    const keys = Object.keys(value)
    const fieldsMap = ComplaintFieldsMap as any
    //
    return _.filter(
      keys.map((i) => {
        if (_.isNil(value[i])) {
          return null
        }
        return {
          label: fieldsMap[i as any],
          value: value[i],
          key: i,
          isAsset: assetsFields.includes(i),
        }
      }),
    ) as unknown as Array<{ label: string; value: string; key: string; isAsset: boolean }>
  }, [value])

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'admin':
      case 'user':
        return value?.name
      case 'unit':
        return `${value?.brand?.name} - ${value?.name}`
      case 'order':
        return value?.number
      case 'status':
      case 'prevStatus':
        return ComplaintStatusList.find((i) => i.id === value)?.name
      case 'resolveType':
        return ComplaintResolveTypeList.find((i) => i.id === value)?.name
      case 'reasonType':
        return ComplaintReasonList.find((i) => i.id === value)?.name
      case 'source':
        return ComplaintSourceList.find((i) => i.id === value)?.name
      case 'promoCode':
        return value?.code || ''
      case 'createdAt':
      case 'updatedAt':
      case 'resolvedAt':
        if (!value) {
          return ''
        }
        return format(new Date(value), 'dd.MM.y HH:mm xxx')
      default:
        return value ? value : ''
    }
  }

  const buildAssets = (assets: IAsset[]) => {
    return assets.map((asset) => {
      return (
        <img
          className={styles.image}
          src={`${getMediaPath(asset.source)}?preset=small&fpx=${asset.focalPoint?.x || '0.5'}&fpy=${asset.focalPoint?.y || '0.5'}`}
          alt=""
        />
      )
    })
  }

  return (
    <div className={styles.root}>
      {list
        .filter((i) => (assetsFields.includes(i.key) || (!Array.isArray(i.value) && i)) && !Array.isArray(i.label))
        .map((field, index) => (
          <div key={index}>
            <Typography component="span" variant="body2">
              {`${field.label ?? ''}: `}
              {field.isAsset && buildAssets(field.value as unknown as IAsset[])}
              {!field.isAsset && (formatValue(field.key, field.value) ?? '')}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default ComplaintChangedDataField
