import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, SelectField, SelectInput, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate, formatListValue } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { PartnerCommissionTypeList } from 'src/types/enum/PartnerCommissionType'
import { PartnerStatusList } from 'src/types/enum/PartnerStatus'
import { Datagrid } from 'src/components/list/Datagrid'
const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      name: 'Название',
      balance: 'Баланс',
      commissionType: 'Тип комиссии',
      commissionAmount: 'Комиссия',
      contactPerson: 'Контактное лицо',
      legalAddress: 'Юридическое адрес',
      legalInn: 'ИНН',
      legalBik: 'БИК',
      legalKpp: 'КПП',
      legalOgrn: 'ОГРН/ИП',
      legalOkpo: 'Телефон',
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,
      commissionType: formatListValue(item.commissionType, PartnerCommissionTypeList),
      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Partner',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <SelectInput source="status" label={'Статус'} choices={PartnerStatusList} />
      <SelectInput source="commissionType" label={'Тип коммисии'} choices={PartnerCommissionTypeList} fullWidth />
    </Filter>
  )
}

const PartnerList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Партнеры"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'name'} label={'Партнер'} />
        <TextField source={'balance'} label={'Баланс'} />
        <TextField source={'rating'} label={'Рейтинг'} />
        <SelectField source="commissionType" label={'Тип коммисии'} choices={PartnerCommissionTypeList} />
        <TextField source={'commissionAmount'} label={'Комиссия'} />
        <SelectField source={'status'} label={'Статус'} choices={PartnerStatusList} />
      </Datagrid>
    </List>
  )
}

export default PartnerList
