import * as React from 'react'
import Formatter from 'src/utils/formatters'
import { TabbedForm, FormTab } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return <FormTab label="Информация" path={'benefit'} {...props}></FormTab>
}
const RestaurantOrderForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab />
    </TabbedForm>
  )
}
export default RestaurantOrderForm
