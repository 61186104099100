import * as React from 'react'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import styles from './index.module.scss'
import { useState } from 'react'
import { getMediaPath } from 'src/utils/media'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

export interface Props extends FieldProps {
  src?: string
  title?: string
  classes?: object
}

const AssetField = (props: Props) => {
  const { className, classes: classesOverride, emptyText, source, src, title, ...rest } = props
  const record = useRecordContext(props)
  const sourceValue = get(record, source ?? '')
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const files = sourceValue ? (!Array.isArray(sourceValue) ? [sourceValue] : sourceValue) : []

  if (files.length === 0) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className}>
        {emptyText}
      </Typography>
    ) : (
      <div className={className} />
    )
  }

  const filesSources = files.map((i) => getMediaPath(i?.source))
  return (
    <ul className={styles.list}>
      {files.map((file, index) => {
        return (
          <li key={index}>
            {file.type !== 'IMAGE' ? (
              <a href={getMediaPath(files[photoIndex]?.source)} className={styles.link}>
                {file.name}
              </a>
            ) : (
              <img
                src={`${getMediaPath(file?.source)}?preset=tiny`}
                onClick={(e) => {
                  setPhotoIndex(index)
                  setIsOpen(true)
                  e.preventDefault()
                }}
                className={styles.image}
              />
            )}
          </li>
        )
      })}
      {isOpen && !!filesSources[photoIndex] && (
        <Lightbox
          open={true}
          index={photoIndex}
          slides={filesSources.map((i: string) => ({ src: i }))}
          close={() => setIsOpen(false)}
        />
      )}
    </ul>
  )
}

AssetField.displayName = 'ImageField'

export default AssetField
