import * as React from 'react'
import { SelectInput, TextInput, SimpleForm, required } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { range } from 'src/utils/array'
import UserInput from 'src/components/inputs/UserInput'
import OrderInput from 'src/components/inputs/OrderInput'
import UnitInput from 'src/components/inputs/UnitInput'
import { ReviewStatusList } from 'src/types/enum/ReviewStatus'

const Form = () => {
  const form = useFormContext()
  return (
    <>
      <SelectInput
        source={'mark'}
        label="Оценка"
        resettable={false}
        validate={[required()]}
        choices={range(1, 5, 1).map((i) => ({ id: i, name: `${i}` }))}
      />
      <SelectInput
        source={'status'}
        label="Статус"
        resettable={false}
        validate={[required()]}
        choices={ReviewStatusList}
      />
      <UserInput source="userId" label={'Клиент'} fullWidth />
      <OrderInput source="orderId" label={'Заказ'} fullWidth />
      <UnitInput source="unitId" label={'Заведение'} fullWidth validate={[required()]} />
      <TextInput source="userName" label={'Имя'} fullWidth />
      <TextInput source="text" label={'Отзыв'} fullWidth multiline />
      <TextInput source="answer" label={'Ответ'} fullWidth multiline />
    </>
  )
}
const ReviewForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <Form />
    </SimpleForm>
  )
}
export default ReviewForm
