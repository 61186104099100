import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import UnitSelectionForm from 'src/resources/unit-selection/UnitSelectionForm'
import { CustomEdit } from 'src/components/CustomEdit'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Подборка ресторанов {record.name}</span> : null)

const UnitSelectionEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <UnitSelectionForm isEdit={true} />
    </CustomEdit>
  )
}
export default UnitSelectionEdit
