import React, { ReactElement } from 'react'
import { EditButton, DeleteButton } from 'react-admin'
import ButtonGroup from '@mui/material/ButtonGroup'
interface Props {
  editButton?: ReactElement
  deleteButton?: ReactElement
}
const EditListButtons = (props: Props) => {
  return (
    <ButtonGroup size="small">
      {props.editButton ?? <EditButton label={''} />}
      {props.deleteButton ?? <DeleteButton label={''} />}
    </ButtonGroup>
  )
}

export default EditListButtons
