import * as React from 'react'
import { RecordContextProvider, TextInput, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}
export const ReviewAnswerModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update('review', { id: record.id, data: { ...data }, previousData: record })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'xs'}
        resource={'area'}
        title={`Ответ на отзыв № ${record.id}`}
        onClose={props.onClose}
        saveButtonLabel={'Ответить'}
        saveButtonIcon={<QuestionAnswerIcon />}
        save={handleSubmit}
      >
        <TextInput source="answer" label={'Ответ'} multiline />
      </ModalForm>
    </RecordContextProvider>
  )
}
