import * as React from 'react'
import styles from './index.module.scss'
import FormControlLabel from '@mui/material/FormControlLabel'
import classNames from 'classnames'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import { format, parse } from 'date-fns'
const moment = require('moment')
interface Props {
  placeholder?: string
  disabled?: boolean
  label?: string
  name?: string
  value: string
  onChange: (value: string | null) => void
}
const DayTypeInputItem = (props: Props) => {
  return (
    <FormControlLabel
      htmlFor={`${props.name}`}
      key={props.name}
      className={styles.label}
      sx={(theme) => ({})}
      label={''}
      control={
        <TimePicker
          minuteStep={5}
          showSecond={false}
          className={styles.timePicker}
          popupClassName={classNames(styles.popup)}
          placeholder={props.placeholder ?? props.label}
          inputReadOnly={false}
          disabled={props.disabled ?? false}
          value={props.value ? moment(parse(props.value, 'HH:mm', new Date())) : null}
          onChange={(date) => {
            if (date) {
              props.onChange(format(date.toDate(), 'HH:mm'))
            } else {
              props.onChange(null)
            }
          }}
        />
      }
    />
  )
}

export default DayTypeInputItem
