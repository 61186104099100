import * as React from 'react'
import { RecordContextProvider, required, SelectInput, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import { useTotalContext } from 'src/context/total_state'
import { PromoReserveCancelReasonList } from 'src/types/enum/PromoReserveStatus'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}
export const PromoReserveCancelModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const totalContext = useTotalContext()

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.update('promo-reserve/setCancelled', {
      id: record.id,
      data,
      previousData: record,
    })

    if (props.onClose) {
      props.onClose()
    }
    refresh()
    totalContext.fetchPromoReserve()
    //
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'xs'}
        resource={'cancelReason'}
        title={'Отменить бронь'}
        onClose={props.onClose}
        saveButtonLabel={'Подтвердить'}
        cancelButtonLabel={'Назад'}
        save={handleSubmit}
      >
        <SelectInput
          name={'cancelReason'}
          source={'cancelReason'}
          label={'Причина отмены'}
          choices={PromoReserveCancelReasonList}
          validate={required()}
        />
      </ModalForm>
    </RecordContextProvider>
  )
}
