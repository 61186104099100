export enum ViewTemplateItemType {
  SliderBrands = 'sliderBrands',
  SliderPromo = 'sliderPromo',
  ListPromo = 'listPromo',
  BannerPromo = 'bannerPromo',
  BannerBrand = 'bannerBrand',
  UnitSelection = 'unitSelection',
  UnitSelectionCategory = 'unitSelectionCategory',
}

export const ViewTemplateItemTypeList = [
  { id: ViewTemplateItemType.SliderPromo, name: 'Слайдер акций' },
  { id: ViewTemplateItemType.ListPromo, name: 'Список акций' },
  { id: ViewTemplateItemType.BannerPromo, name: 'Баннер акции' },
  { id: ViewTemplateItemType.SliderBrands, name: 'Слайдер брендов' },
  { id: ViewTemplateItemType.BannerBrand, name: 'Баннер бренда' },
  { id: ViewTemplateItemType.UnitSelection, name: 'Подборка' },
  { id: ViewTemplateItemType.UnitSelectionCategory, name: 'Категория подборок' },
]
