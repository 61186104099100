import * as React from 'react'
import { FC } from 'react'
import { Filter, List, TopToolbar, ListProps, TextField, TextInput, CreateButton } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { ModalButton } from 'src/components/Modal/ModalButton'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { PromoCodeImportModal } from 'src/resources/promo-code/PromoCodeImportModal'
import { PromoCodeGenerateModal } from 'src/resources/promo-code/PromoCodeGenerateModal'
import { Datagrid } from 'src/components/list/Datagrid'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'PromoCode',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="code:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
    </Filter>
  )
}

const PromoCodeList: FC<ListProps> = (props) => {
  const generateBtn = (
    <ModalButton label={'Генерация'} icon={<UploadFileIcon />} key={'generate'} modal={<PromoCodeGenerateModal />} />
  )
  const importBtn = (
    <ModalButton label={'Импорт'} icon={<UploadFileIcon />} key={'import'} modal={<PromoCodeImportModal />} />
  )

  return (
    <List
      {...props}
      filters={<_Filter />}
      actions={
        <TopToolbar>
          {generateBtn}
          {importBtn}
          <CreateButton />
        </TopToolbar>
      }
      title="Промокоды доставки"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Пока нет промокодов доставки'} actions={[<CreateButton />, generateBtn, importBtn]} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'code'} label={'Промокод'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <TextField source={'perCustomerUsageLimit'} label={'Лимит клиента'} />
        <TextField source={'usageLimit'} label={'Лимит'} />
      </Datagrid>
    </List>
  )
}

export default PromoCodeList
