export enum AccountingType {
  Act = 'Act',
  AccountingAccount = 'AccountingAccount',
  ReconciliationStatement = 'ReconciliationStatement',
  Report = 'Report',
}

export const AccountingTypeList = [
  { id: AccountingType.Act, name: 'Акт выполненных работ' },
  { id: AccountingType.AccountingAccount, name: 'Счет-фактура' },
  { id: AccountingType.ReconciliationStatement, name: 'Акт сверки взаиморасчетов' },
  { id: AccountingType.Report, name: 'Отчеты по заказам' },
]
