import ListIcon from '@mui/icons-material/List'
import ProductList from 'src/resources/product/ProductList'
import ProductEdit from 'src/resources/product/ProductEdit'
import ProductCreate from 'src/resources/product/ProductCreate'

export default {
  create: ProductCreate,
  edit: ProductEdit,
  list: ProductList,
  icon: ListIcon,
}
