import { PromoReserveStatus } from 'src/types/enum/PromoReserveStatus'
import { format } from 'date-fns'
import Converter from 'src/utils/converter'

export class PromoReserveUtils {
  static getColor(status: PromoReserveStatus): string {
    const colorsStatus = {
      red: '#E80012',
      red2: '#F83409',
      orange: '#FCC067',
      green1: '#9CCB3B',
      green2: '#6BD79D',
      green3: '#02AD84',
      blue1: '#0047FF',
      blue2: '#1867C0',
      blue3: '#258CFF',
      blue4: '#81B6F2',
      blue5: '#BBDBFF',
      gray: '#BDBDBD',
    }
    const colors = {
      [PromoReserveStatus.Created]: colorsStatus.orange,
      [PromoReserveStatus.Approved]: colorsStatus.green3,
      [PromoReserveStatus.WaitingForFillingTotalPrice]: colorsStatus.red,
      [PromoReserveStatus.FilledTotalPrice]: colorsStatus.green3,
      [PromoReserveStatus.Approved]: colorsStatus.green3,
      [PromoReserveStatus.Confirmation]: colorsStatus.blue2,
      [PromoReserveStatus.Cancelled]: colorsStatus.gray,
    }
    return colors[status]
  }
  static formatBookingTime({
    startAt,
    endAt,
    timezone,
  }: {
    startAt: string | null
    endAt: string | null
    timezone: number
  }) {
    if (!startAt || !endAt) {
      return ''
    }
    if (timezone) {
      return `${format(Converter.convertTimeToTimeZone(new Date(startAt), timezone), 'dd.MM.yy HH:mm')}-${format(Converter.convertTimeToTimeZone(new Date(endAt), timezone), 'HH:mm')}`
    } else {
      return `${format(new Date(startAt), 'dd.MM.yy HH:mm ')}-${format(new Date(endAt), 'HH:mm')} ${format(new Date(startAt), 'O')}`
    }
  }
}
