import * as React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { BooleanField, FieldProps, RaRecord } from 'react-admin'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const VisibilityField = (props: Props) => {
  return (
    <BooleanField
      TrueIcon={VisibilityIcon}
      FalseIcon={VisibilityOffIcon}
      valueLabelTrue="Опубликовано"
      valueLabelFalse="Не опубликовано"
      {...props}
    />
  )
}

export default VisibilityField
