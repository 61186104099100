import PersonIcon from '@mui/icons-material/Person'
import AccountingList from './AccountingList'
import AccountingEdit from './AccountingEdit'
import AccountingCreate from './AccountingCreate'

export default {
  create: AccountingCreate,
  edit: AccountingEdit,
  list: AccountingList,
  icon: PersonIcon,
}
