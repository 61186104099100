export enum PromoType {
  Sale = 'sale',
  EachN = 'eachN',
  MoneyGift = 'moneyGift',
  OnePlusOne = 'onePlusOne',
  Gift = 'gift',
  FixedPrice = 'fixedPrice',
}

export const PromoTypeList = [
  { id: PromoType.Sale, name: 'Процент на товары' },
  { id: PromoType.EachN, name: 'На N самых дешевых товаров в заказе' },
  { id: PromoType.MoneyGift, name: 'Сумма в подарок' },
  { id: PromoType.OnePlusOne, name: '1+1' },
  { id: PromoType.Gift, name: 'Блюдо в подарок' },
  { id: PromoType.FixedPrice, name: 'Фиксированная цена' },
]
