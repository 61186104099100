import ListIcon from '@mui/icons-material/List'
import RestaurantOrderList from 'src/resources/restaurant-order/RestaurantOrderList'
import RestaurantOrderEdit from 'src/resources/restaurant-order/RestaurantOrderEdit'
import RestaurantOrderCreate from 'src/resources/restaurant-order/RestaurantOrderCreate'

export default {
  create: RestaurantOrderCreate,
  edit: RestaurantOrderEdit,
  list: RestaurantOrderList,
  icon: ListIcon,
}
