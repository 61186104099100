import ListIcon from '@mui/icons-material/List'
import PartnerList from 'src/resources/partner/PartnerList'
import PartnerEdit from 'src/resources/partner/PartnerEdit'
import PartnerCreate from 'src/resources/partner/PartnerCreate'

export default {
  create: PartnerCreate,
  edit: PartnerEdit,
  list: PartnerList,
  icon: ListIcon,
}
