import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import CategoryForm from 'src/resources/category/CategoryForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Товарный раздел</span>

const CategoryCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <CategoryForm />
    </Create>
  )
}

export default CategoryCreate
