import React, { useState } from 'react'
import { useInput, FieldTitle } from 'ra-core'
import { Button, Typography } from '@mui/material'
import ModalMedia from 'src/components/ModalMedia'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { getMediaPath } from 'src/utils/media'
import { Labeled, InputHelperText } from 'ra-ui-materialui'
import { CommonInputProps } from 'react-admin'
import styles from './index.module.scss'
import { IAsset } from 'src/components/types'
import { AssetEditModal } from 'src/components/AssetEditModal'
import classNames from 'classnames'
import FormHelperText from '@mui/material/FormHelperText'
import { FileUploadAcceptType } from 'src/types'
import { arrayMoveImmutable } from 'array-move'
interface IAssetWithOrder {
  id?: number
  asset: IAsset
  assetsId: string
  order: number
}
export interface Props extends CommonInputProps {
  accept?: FileUploadAcceptType | FileUploadAcceptType[]
  maxSize?: number
  isMulti?: boolean
  showSetMain?: boolean
  onRemove?: Function
  maxFiles?: number
}

export const MediaOrderInput = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const {
    field,
    isRequired,
    fieldState,
    formState: { isSubmitted },
  } = useInput({
    ...props,
  })
  const { isTouched, error } = fieldState
  const value: IAssetWithOrder[] = field.value ?? []
  const [activeImage, setActiveImage] = useState<IAssetWithOrder | null>(value.length > 0 ? value[0] : null)
  const [modalOpened, setModalOpened] = useState(false)
  const [editAsset, setEditAsset] = useState<IAssetWithOrder | null>(null)

  const handleAddAsset = (records: IAsset[]) => {
    if (!value) {
      field.onBlur()
    }
    if (!props.isMulti && records.length > 0) {
      field.onChange({ asset: records[0], assetsId: records[0].id })
    } else {
      const fileIds = (value || []).map((i) => i.assetsId)
      const toAdd = records
        .filter((i) => !fileIds.includes(i.id))
        .map((i, index) => ({ asset: i, assetsId: i.id, order: value.length + index }))
      //
      field.onChange([...value, ...toAdd])
    }
    setModalOpened(false)
  }

  const handleSelect = (e: any, record: IAssetWithOrder) => {
    setAnchorEl(e.currentTarget)
    setActiveImage(record)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDelete = (record: IAssetWithOrder) => {
    if (!props.isMulti) {
      field.onChange(null)
    } else {
      const newAssets = (value as IAssetWithOrder[]).filter((file) => file.assetsId !== record.assetsId)
      field.onChange(newAssets.map((i, order) => ({ ...i, order })))
    }
    field.onBlur()

    setAnchorEl(null)
  }

  const handleShow = (record: IAssetWithOrder) => {
    setAnchorEl(null)
    setEditAsset(record)
  }

  const handleCloseEditAsset = (newAsset?: IAsset | null) => {
    if (newAsset) {
      if (props.isMulti) {
        const newAssets = (value as IAssetWithOrder[]).map((file) =>
          file.assetsId === newAsset.id ? { ...file, asset: newAsset } : file,
        )
        field.onChange(newAssets)
      } else {
        field.onChange(newAsset)
      }
    }
    setEditAsset(null)
  }

  const handleSort = (oldIndex: number, newIndex: number) => {
    if (newIndex < 0 || newIndex >= field.value.length) {
      return
    }
    field.onChange(
      (arrayMoveImmutable(field.value, oldIndex, newIndex) as any[]).map((i, order) => ({ ...i, order: order + 1 })),
    )
  }

  const handleMovePrev = (e: any, index: number) => {
    e.stopPropagation()
    handleSort(index, index - 1)
  }

  const handleMoveNext = (e: any, index: number) => {
    e.stopPropagation()
    handleSort(index, index + 1)
  }

  return (
    <div>
      <Labeled
        label={props.label}
        source={props.source}
        resource={props.resource}
        isRequired={isRequired}
        className={classNames('ra-input', `ra-input-${props.source}`, styles.root)}
      >
        <div>
          <Typography color={'textSecondary'}>
            <FieldTitle label={props.label} source={props.source} resource={props.resource} isRequired={isRequired} />
          </Typography>
          <div className={styles.previews}>
            {(value || []).map((file, index) => (
              <div key={file.assetsId}>
                <div
                  aria-controls={`asset-menu-${file.assetsId}`}
                  aria-haspopup="true"
                  onClick={(e) => handleSelect(e, file)}
                  className={styles.previewCard}
                >
                  <img
                    src={`${getMediaPath(file.asset?.source)}?preset=tiny&fpx=${file.asset?.focalPoint?.x || '0.5'}&fpy=${file.asset?.focalPoint?.y || '0.5'}`}
                    className={styles.preview}
                  />
                  <div className={styles.previewSortBtns}>
                    <div className={styles.previewSortPrev} onClick={(e) => handleMovePrev(e, index)}>
                      ←
                    </div>
                    <div className={styles.previewSortNext} onClick={(e) => handleMoveNext(e, index)}>
                      →
                    </div>
                  </div>
                </div>
                <Menu
                  id={`asset-menu-${file.assetsId}`}
                  anchorEl={activeImage?.assetsId === file.assetsId ? anchorEl : null}
                  keepMounted
                  open={activeImage?.assetsId === file.assetsId ? Boolean(anchorEl) : false}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={() => handleShow(file)}>Редактировать</MenuItem>
                  <MenuItem onClick={() => handleDelete(file)}>Удалить</MenuItem>
                </Menu>
              </div>
            ))}
          </div>

          <Box mt={1}>
            <Button variant="contained" size={'small'} color="primary" onClick={() => setModalOpened(true)}>
              {value.length > 0 ? (props.isMulti ? 'Добавить файл' : 'Изменить файл') : 'Добавить файл'}
            </Button>
          </Box>
          <ModalMedia
            fullWidth
            maxSize={props.maxSize}
            accept={Array.isArray(props.accept) ? props.accept : props.accept ? [props.accept] : undefined}
            isShown={modalOpened}
            onClose={() => setModalOpened(false)}
            isMulti={props.isMulti ?? false}
            maxFiles={props.maxFiles}
            onSelect={handleAddAsset}
          />
          <FormHelperText>
            <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={props.helperText} />
          </FormHelperText>
        </div>
      </Labeled>
      {editAsset != null && (
        <AssetEditModal refresh={false} isShown={true} id={editAsset?.assetsId} onClose={handleCloseEditAsset} />
      )}
    </div>
  )
}

MediaOrderInput.defaultProps = {
  accept: ['image/jpeg', 'image/png', 'image/svg+xml'],
  isMulti: false,
  showSetMain: false,
}
