import * as React from 'react'
import { isFunction } from 'lodash'
import { AutocompleteArrayInput, ReferenceArrayInput, FilterPayload } from 'react-admin'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  validate?: any
  fullWidth?: boolean
  filter?: FilterPayload
  helperText?: string
  filterToQueryField: string
  optionText?: (i: any) => string
}

const ProductsArrayInput = (props: Props) => {
  return (
    <ReferenceArrayInput
      allowEmpty={false}
      source={props.source}
      reference="product"
      variant={'outlined'}
      fullWidth={true}
      filter={props.filter}
      perPage={500}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        validate={props.validate}
        label={props.label}
        noOptionsText="-"
        helperText={props.helperText}
        filterToQuery={(q: string) => ({ [`${props.filterToQueryField}:$contL`]: q })}
        optionText={isFunction(props.optionText) ? props.optionText : (i) => (i ? `${i?.name} (${i?.vendorCode})` : '')}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </ReferenceArrayInput>
  )
}

export default ProductsArrayInput
