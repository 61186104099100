export enum OrderChangeSource {
  Aggregator = 'aggregator',
  PartnerWeb = 'admin',
  User = 'user',
  PartnerApi = 'partnerApi',
  PaymentApi = 'paymentApi',
}

export const OrderChangeSourceList = [
  { id: OrderChangeSource.Aggregator, name: 'Агрегатор' },
  { id: OrderChangeSource.PartnerWeb, name: 'Вендор админка' },
  { id: OrderChangeSource.User, name: 'Клиент' },
  { id: OrderChangeSource.PartnerApi, name: 'API заведения' },
  { id: OrderChangeSource.PaymentApi, name: 'API платежное' },
]
