import ListIcon from '@mui/icons-material/List'
import NotificationList from 'src/resources/notification/NotificationList'
import NotificationShow from 'src/resources/notification/NotificationShow'
import NotificationCreate from 'src/resources/notification/NotificationCreate'

export default {
  create: NotificationCreate,
  show: NotificationShow,
  list: NotificationList,
  icon: ListIcon,
}
