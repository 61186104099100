export enum Platform {
  iOS = 'ios',
  Android = 'android',
  Site = 'site',
}
export const PlatformList = [
  { id: Platform.iOS, name: 'iOS' },
  { id: Platform.Android, name: 'Android' },
  { id: Platform.Site, name: 'Сайт' },
]
