import * as React from 'react'
import { useRecordContext } from 'ra-core'
import { FieldProps, RaRecord } from 'react-admin'
import get from 'lodash/get'
import { useMemo } from 'react'
import Formatter from 'src/utils/formatters'
import { differenceInSeconds } from 'date-fns'
import { AreaStatus } from 'src/types/enum/AreaStatus'
interface AreaState {
  text: string
  subText?: string
  color: string
}
interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const AreaStatusField = (props: Props) => {
  const record = useRecordContext(props)
  const value = get(record, props.source ?? '')
  const status = get(record, props.source ?? '') as AreaStatus
  const pausedUntil = get(record, 'pausedUntil')
  const addDeliveryTime = get(record, 'addDeliveryTime')
  const state = useMemo<AreaState>(() => {
    const state: AreaState = { text: '', color: '' }

    if (pausedUntil && differenceInSeconds(new Date(pausedUntil), new Date()) > 0) {
      state.text = `На паузе ${Formatter.formatDateUntil(pausedUntil)}`
    } else if (addDeliveryTime > 0) {
      state.text = 'Активно'
      state.subText = `Доставка +${addDeliveryTime} мин.`
    } else {
      state.text = 'Активно'
    }

    return state
  }, [record])

  return (
    <>
      <div>{state.text}</div>
      {state.subText && <div>{state.subText}</div>}
    </>
  )
}

export default AreaStatusField
