import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import UnitForm from 'src/resources/unit/UnitForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новое Заведение</span>

const UnitCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <UnitForm />
    </Create>
  )
}

export default UnitCreate
