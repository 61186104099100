import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CreateButton } from 'react-admin'
interface Props {
  title?: string
  basePath?: string
  description?: string
  buttonText?: string
  hasCreate?: boolean
  actions?: ReactElement[]
}
const EmptyList = ({ basePath, title = 'Список пуст', hasCreate = true, description, buttonText, actions }: Props) => (
  <Box textAlign="center" m={3} flex={1}>
    <Typography variant="h6" paragraph>
      {title}
    </Typography>
    {description && <Typography variant="body1">{description}</Typography>}
    {hasCreate && (actions?.length ?? 0) === 0 && <CreateButton label={buttonText} resource={basePath} />}
    {(actions?.length ?? 0) > 0 && actions}
  </Box>
)

export default EmptyList
