import * as React from 'react'
import { useRecordContext } from 'ra-core'
import { FieldProps, RaRecord, FunctionField } from 'react-admin'
import get from 'lodash/get'
import Formatter from 'src/utils/formatters'
type Callback = (record: RaRecord) => string
interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const PhoneField = (props: Props) => {
  const record = useRecordContext(props)
  const value = get(record, props.source ?? '')
  return <FunctionField sx={{ whiteSpace: 'nowrap' }} {...props} render={(i: any) => Formatter.formatPhone(value)} />
}

export default PhoneField
