import * as _ from 'lodash'
import { useState } from 'react'
import VoucherCode from 'voucher-code-generator'
import {
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import UserInput from 'src/components/inputs/UserInput'
import OrderInput from 'src/components/inputs/OrderInput'
import UnitInput from 'src/components/inputs/UnitInput'
import { ComplaintStatus, ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { ComplaintReasonList } from 'src/types/enum/ComplaintReason'
import { ComplaintResolveTypeList } from 'src/types/enum/ComplaintResolveType'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { ComplaintSourceList } from 'src/types/enum/ComplaintSource'
import { PromoCodeGenerate } from './ComplaintPromoCodeGenerate'

const ComplaintForm = (props: any) => {
  const [generatePromoCode, setGeneratePromoCode] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState<string | null>(null)

  const onGeneratePromoCode = () => {
    setGeneratePromoCode(true)
    //
    if (_.isString(promoCode) && !_.isEmpty(promoCode)) {
      return promoCode
    }
    const newPromoCode = VoucherCode.generate({
      length: 6,
      count: 1,
      charset: VoucherCode.charset('alphabetic') + VoucherCode.charset('numbers'),
    })[0]
    //
    setPromoCode(newPromoCode)
    //
    return newPromoCode
  }

  return (
    <SimpleForm
      defaultValues={{
        status: ComplaintStatus.Created,
      }}
    >
      <ReferenceInput
        allowEmpty={props.allowEmpty}
        source="reviewId"
        reference="review"
        variant={'outlined'}
        filter={props.filter}
        sort={{ field: 'id', order: 'ASC' }}
        perPage={100}
      >
        <AutocompleteInput
          noOptionsText="-"
          filterToQuery={(q: string) => ({ 'id:$contL': q })}
          optionText={(i) => `№ ${i?.id || ''}`}
          label="Отзыв"
          fullWidth={true}
        />
      </ReferenceInput>
      <UserInput source="userId" label={'Клиент'} fullWidth />
      <OrderInput source="orderId" label={'Заказ'} fullWidth />
      <UnitInput source="unitId" label={'Заведение'} fullWidth validate={[required()]} />

      <SelectInput source={'source'} label={'Источник'} choices={ComplaintSourceList} validate={required()} />
      <SelectInput source={'status'} label={'Статус'} choices={ComplaintStatusList} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.status === ComplaintStatus.Resolved && (
            <SelectInput
              source={'resolveType'}
              label={'Решение'}
              choices={ComplaintResolveTypeList}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <TextInput source="adminComment" label={'Комментарий менеджера'} multiline />
      <NumberInput source="withholdingAmount" label={'Сумма удержания'} />
      <NumberInput source="appealAmount" label={'Сумма апеляции'} />
      <SelectInput source={'reasonType'} label={'Причина'} choices={ComplaintReasonList} />
      <TextInput source="text" label={'Текст жалобы'} fullWidth multiline />
      <MediaInput source={'assets'} isMulti={true} label={'Фотографии'} />
      <TextInput source="answer" label={'Ответ'} fullWidth multiline />
      <PromoCodeGenerate
        generatePromoCodeFlag={generatePromoCode}
        onGeneratePromoCode={onGeneratePromoCode}
        onDeletePromoCode={() => setGeneratePromoCode(false)}
        isCreation={props.isCreation}
      />
    </SimpleForm>
  )
}
export default ComplaintForm
