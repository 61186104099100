import React from 'react'
import Chip from '@mui/material/Chip'
import { PromoReserveStatus, PromoReserveStatusList } from 'src/types/enum/PromoReserveStatus'
import { PromoReserveUtils } from 'src/utils/PromoReserveUtils'
interface Props {
  status: PromoReserveStatus
  size?: 'small' | 'medium'
}

export const PromoReserveStatusChip = (props: Props) => {
  const name = PromoReserveStatusList.find((i) => i.id === props.status)?.name ?? ''
  const color = PromoReserveUtils.getColor(props.status)
  return <Chip label={name} size={props.size ?? 'small'} style={{ backgroundColor: color }} />
}
