import * as React from 'react'
import {
  FormDataConsumer,
  RecordContextProvider,
  SelectInput,
  TextInput,
  useDataProvider,
  useRefresh,
  NumberInput,
  Labeled,
  required,
  TextField,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { ComplaintStatus, ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { ComplaintResolveTypeList } from 'src/types/enum/ComplaintResolveType'
import OrderLinkField from 'src/components/fields/OrderLinkField'
import AssetField from 'src/components/fields/AssetField'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import { useTotalContext } from 'src/context/total_state'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}
export const ComplaintAnswerModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const totalContext = useTotalContext()

  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update('complaint', { id: record.id, data: { ...data }, previousData: record })
    refresh()
    totalContext.fetchComplaint()
    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={record}>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'xs'}
        resource={'area'}
        title={`Ответ на жалобу № ${record.id}`}
        onClose={props.onClose}
        saveButtonLabel={'Ответить'}
        saveButtonIcon={<QuestionAnswerIcon />}
        save={handleSubmit}
      >
        <AssetField source={'brand.logo'} />
        <Labeled label="Бренд">
          <TextField source={'unit.brand.name'} />
        </Labeled>
        <Labeled label="Партнер">
          <PartnerLinkField label={'Партнер'} />
        </Labeled>
        <Labeled label="Заведение">
          <UnitLinkField label={'Заведение'} />
        </Labeled>
        <Labeled label="Заказ">
          <OrderLinkField label={'Заказ'} />
        </Labeled>

        <SelectInput source={'status'} label={'Статус'} choices={ComplaintStatusList} />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.status === ComplaintStatus.Resolved && (
              <SelectInput
                source={'resolveType'}
                label={'Решение'}
                choices={ComplaintResolveTypeList}
                validate={[required()]}
              />
            )
          }
        </FormDataConsumer>
        <TextInput source="answer" label={'Ответ'} multiline />
        <TextInput source="adminComment" label={'Комментарий менеджера'} multiline />
        <NumberInput source="withholdingAmount" label={'Сумма удержания'} />
        <NumberInput source="appealAmount" label={'Сумма апеляции'} />
      </ModalForm>
    </RecordContextProvider>
  )
}
