import * as React from 'react'
import {
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { useRecordContext } from 'ra-core'
import { ViewTemplateItemType, ViewTemplateItemTypeList } from 'src/types/enum/ViewTemplateItemType'
import {
  ViewTemplateItemSource,
  ViewTemplateItemSourceBrandList,
  ViewTemplateItemSourcePromoList,
} from 'src/types/enum/ViewTemplateItemSource'
import { PromoTypeList } from 'src/types/enum/PromoType'
import EmojiInput from 'src/components/inputs/EmojiInput'
import { ViewTemplateItemViewBrandList, ViewTemplateItemViewPromoList } from 'src/types/enum/ViewTemplateItemView'
import { PromoGroupList } from 'src/types/enum/PromoGroup'

const isPromoType = (type: ViewTemplateItemType) =>
  [ViewTemplateItemType.SliderPromo, ViewTemplateItemType.BannerPromo, ViewTemplateItemType.ListPromo].includes(type)
interface Props {}

export const ViewTemplateItemForm = (props: Props) => {
  const record = useRecordContext()
  return (
    <SimpleForm>
      <TextInput source={'name'} label={'Заголовок'} />
      <EmojiInput source={'emoji'} label={'Эмоджи'} />
      <SelectInput source={'type'} label={'Тип'} choices={ViewTemplateItemTypeList} validate={[required()]} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => (
          <SelectInput
            source={'view'}
            label={'Вид карточек'}
            choices={isPromoType(formData.type) ? ViewTemplateItemViewPromoList : ViewTemplateItemViewBrandList}
            validate={[required()]}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          [
            ViewTemplateItemType.SliderBrands,
            ViewTemplateItemType.SliderPromo,
            ViewTemplateItemType.ListPromo,
            ViewTemplateItemType.BannerPromo,
            ViewTemplateItemType.BannerBrand,
          ].includes(formData.type) && (
            <SelectInput
              source={'source'}
              label={'Источник'}
              choices={isPromoType(formData.type) ? ViewTemplateItemSourcePromoList : ViewTemplateItemSourceBrandList}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          isPromoType(formData.type) &&
          formData.source === ViewTemplateItemSource.PromoTypeList && (
            <SelectArrayInput
              source={'promoTypes'}
              label={'Типы акций'}
              choices={PromoTypeList}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          isPromoType(formData.type) &&
          formData.source === ViewTemplateItemSource.PromoGroupList && (
            <SelectArrayInput
              source={'promoGroups'}
              label={'Группы акций'}
              choices={PromoGroupList}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === ViewTemplateItemType.UnitSelection && (
            <ReferenceInput
              source="unitSelectionId"
              reference="unit-selection"
              variant={'outlined'}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={100}
            >
              <AutocompleteInput
                validate={[required()]}
                noOptionsText="-"
                filterToQuery={(q: string) => ({ 'name:$contL': q })}
                optionText={(i) => `${i?.name || ''}`}
                label="Подборка"
                fullWidth
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === ViewTemplateItemType.UnitSelectionCategory && (
            <ReferenceInput
              source="unitSelectionCategoryId"
              reference="unit-selection-category"
              variant={'outlined'}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={100}
            >
              <AutocompleteInput
                validate={[required()]}
                noOptionsText="-"
                filterToQuery={(q: string) => ({ 'name:$contL': q })}
                optionText={(i) => `${i?.name || ''}`}
                label="Категория подборок"
                fullWidth
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <NumberInput source={'sort'} label={'Сортировка'} />
    </SimpleForm>
  )
}
