import * as React from 'react'
import { SimpleForm, NumberInput, TextInput, required, RadioButtonGroupInput, FormDataConsumer } from 'react-admin'
import { PromoKind } from 'src/types/enum/PromoKind'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'
import PromoUnitInput from 'src/components/inputs/PromoUnitInput'
import PromoUnitGroupInput from 'src/components/inputs/PromoUnitGroupInput'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'

enum PromoCodeType {
  Global = 'Global',
  PromoUnit = 'PromoUnit',
  PromoUnitGroup = 'PromoUnitGroup',
}

export const PromoTypeList = [
  { id: PromoCodeType.Global, name: 'Глобальный' },
  { id: PromoCodeType.PromoUnit, name: 'Акция заведения' },
  { id: PromoCodeType.PromoUnitGroup, name: 'Группы акций заведения' },
]

type Props = {
  promoCodeType: PromoCodeType
}

const PromoInputs = ({ promoCodeType }: Props) => {
  return (
    <>
      {promoCodeType === PromoCodeType.Global && (
        <PromoServiceDeliveryInput resettable={true} source="promoId" filter={{ kind: PromoKind.Personal }} fullWidth />
      )}
      {promoCodeType === PromoCodeType.PromoUnit && (
        <PromoUnitInput
          resettable={true}
          source="promoUnitId"
          filter={{ 'promo.kind': PromoKind.Personal }}
          fullWidth
        />
      )}
      {promoCodeType === PromoCodeType.PromoUnitGroup && (
        <PromoUnitGroupInput
          resettable={true}
          source="promoUnitGroupId"
          filter={{ 'promo.kind': PromoKind.Personal }}
          fullWidth
        />
      )}
    </>
  )
}

const PromoCodeForm = (props: any) => {
  const record = useRecordContext()

  return (
    <SimpleForm {...props} redirect={'list'}>
      {props.isCreation ? (
        <RadioButtonGroupInput
          source="promoCodeType"
          label={'Тип промокода'}
          choices={PromoTypeList}
          defaultValue={PromoCodeType.Global}
          row={false}
          validate={[required()]}
        />
      ) : (
        <Typography variant={'overline'} mb={1}>
          Тип промокода: {PromoTypeList.find((el) => el.id === record.promoCodeType)?.name}
        </Typography>
      )}
      <TextInput source="code" label={'Промокод'} fullWidth validate={[required()]} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => <PromoInputs promoCodeType={formData.promoCodeType} />}
      </FormDataConsumer>
      <NumberInput source="perCustomerUsageLimit" label={'Лимит для одного клиента'} helperText={''} fullWidth />
      <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
    </SimpleForm>
  )
}
export default PromoCodeForm
