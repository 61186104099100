import * as React from 'react'
import { SimpleForm, required, SelectInput, TextInput, Toolbar, SaveButton } from 'react-admin'
import { ApplicationItemList } from 'src/types/enum/Application'

export const AppSettingsForm = (props: any) => {
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      toolbar={
        <Toolbar {...props}>
          <SaveButton />
        </Toolbar>
      }
    >
      <SelectInput source={'application'} label={'Приложение'} choices={ApplicationItemList} validate={required()} />
      <TextInput source={'minRequiredVersion'} label={'Минимальная допустимая версия'}></TextInput>
      <TextInput source={'currentVersion'} label={'Текущая версия'}></TextInput>
    </SimpleForm>
  )
}
