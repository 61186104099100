import * as React from 'react'
import { FC, useRef } from 'react'
import { NumberInput, required } from 'react-admin'
import Box from '@mui/material/Box'
import { IFocalPoint } from 'src/components/types'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { ServiceType } from 'src/types/enum/ServiceType'

type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
  record?: any
}
export const UnitSelectionItemEditForm: FC<any> = (props) => {
  const { record } = props
  const currentEditRef = useRef()
  return (
    <Box>
      <RestaurantInput serviceType={ServiceType.Restaurants} source={'restaurantId'} label={'Ресторан'} />
      <NumberInput source="order" label={'Сортировка'} fullWidth helperText={false} variant={'outlined'} />
    </Box>
  )
}
const requiredValidate = [required()]
