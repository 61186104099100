import * as React from 'react'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { Button } from '@mui/material'

export interface ConfirmDialogProps {
  title?: string
  content?: string
  confirmButton?: string
  cancelButton?: string
  onClose?: () => void
  onConfirm: () => void
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const handleConfirm = async () => {
    await props.onConfirm()
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={true} onClose={props.onClose}>
      <DialogTitle id="confirmation-dialog-title">{props.title || 'Вы уверены'}</DialogTitle>
      <DialogContent dividers>{props.content ?? ''}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleConfirm} color="primary">
          {props.confirmButton || 'Подтвердить'}
        </Button>
        <Button onClick={props.onClose} color="secondary">
          {props.cancelButton || 'Отмена'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog
