import * as React from 'react'
import styles from './index.module.scss'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Formatter from 'src/utils/formatters'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

interface Props {
  errors: string[]
  totalUploaded: number
  onClose: () => void
}

const ModalMediaErrors = ({ errors, totalUploaded, onClose }: Props) => {
  return (
    <Dialog fullScreen={true} fullWidth={true} open={true} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <DialogTitle title={'Файлы'} className={styles.dialogTitle}>
        <div className={styles.heading}>
          <Typography variant="h6" className={styles.title}>
            Ошибка загрузки {errors.length} {Formatter.pluralize(errors.length, 'файл', 'файлов', 'файла')}
          </Typography>
          {totalUploaded > 0 && (
            <Typography variant="subtitle1" className={styles.title}>
              {totalUploaded} {Formatter.pluralize(totalUploaded, 'файл', 'файла', 'файлов')} загружены успешно
            </Typography>
          )}
        </div>
        <IconButton onClick={() => onClose()}>
          <CloseIcon className={styles.close} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent} onClick={(e) => e.stopPropagation()}>
        <Stack spacing={2} className={styles.errorsList}>
          {errors.map((i) => (
            <Alert variant="filled" severity="error">
              {i}
            </Alert>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
export default ModalMediaErrors
