import PersonIcon from '@mui/icons-material/Person'
import PageList from './PageList'
import PageEdit from './PageEdit'
import PageCreate from './PageCreate'

export default {
  create: PageCreate,
  edit: PageEdit,
  list: PageList,
  icon: PersonIcon,
}
