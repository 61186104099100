import * as React from 'react'
import { useDataProvider, useGetOne, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { IAsset } from 'src/components/types'
import { AssetEditForm } from 'src/components/AssetEditForm'
interface Props {
  refresh?: boolean
  isShown: boolean
  id: string
  onClose: (data?: IAsset | null) => void
}
export const AssetEditModal = (props: Props) => {
  const { data: record } = useGetOne('asset', { id: props.id })
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update('asset', { id: record.id, data, previousData: record })
    if (props.refresh) {
      refresh()
    }
    if (props.onClose) {
      props.onClose(res.data || data)
    }
    return true
  }

  return (
    <ModalForm
      {...props}
      isShown={props.isShown}
      defaultValues={{ preview: 'medium' }}
      fullWidth={true}
      maxWidth={'lg'}
      title={'Изменить файл'}
      onClose={props.onClose}
      save={handleSubmit}
      record={record}
    >
      {record && <AssetEditForm record={record} />}
    </ModalForm>
  )
}
AssetEditModal.defaults = {
  refresh: true,
}
