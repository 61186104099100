import * as React from 'react'
import { RecordContextProvider, useDataProvider, useRefresh, FileInput, FileField } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
interface Props {
  isShown?: boolean
  id?: number
  mainRecord: Record<any, any>
  onClose?: () => void
}
export const AreaImportModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.create(`unit/import-areas/${props.mainRecord.id}`, { data: { ...data } })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Импорт координат зон доставки'}
        onClose={props.onClose}
        save={handleSubmit}
      >
        <FileInput
          source="file"
          label={'Файл импорта'}
          helperText={'Поддерживаемый формат KML. Импортируется только Polygon'}
          multiple={false}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </ModalForm>
    </RecordContextProvider>
  )
}
