import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { useRecordContext } from 'ra-core'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
  sourcePhone?: string
  withPhone?: boolean
}

const RestaurantLinkField = (props: Props) => {
  const record = useRecordContext(props)
  return (
    <LinkField
      link={(record) => `/restaurant/${get(record, props.source ?? 'restaurant.id')}`}
      source={props.source ?? 'restaurant.id'}
      value={(record) =>
        props.mainSource ? get(record, props.mainSource ?? 'restaurant')?.name : record?.restaurant?.name
      }
      label={props.label}
      icon={
        props.withPhone ? (
          <PhoneCallActionField
            source={props.sourcePhone ?? `${props.mainSource ?? 'restaurant'}.phone`}
            phone={
              get(record, props.mainSource ?? 'restaurant').contactsPhone ??
              get(record, props.mainSource ?? 'restaurant').phone
            }
            onlyIcon={true}
          />
        ) : undefined
      }
    />
  )
}

export default RestaurantLinkField
