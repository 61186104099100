import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import TariffForm from 'src/resources/tariff/TariffForm'
import { CustomEdit } from 'src/components/CustomEdit'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Тариф {record.name}</span> : null)

const TariffEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <TariffForm isCreation={false} />
    </CustomEdit>
  )
}
export default TariffEdit
