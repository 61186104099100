import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import PartnerForm from 'src/resources/partner/PartnerForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Партнер</span>

const PartnerCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <PartnerForm isCreation />
    </Create>
  )
}

export default PartnerCreate
