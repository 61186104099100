import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import ViewTemplateForm from 'src/resources/view-template/ViewTemplateForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Шаблон вывода</span>

const ViewTemplateCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <ViewTemplateForm />
    </Create>
  )
}

export default ViewTemplateCreate
