import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import PenaltyForm from 'src/resources/penalty/PenaltyForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Штраф</span>

const PenaltyCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <PenaltyForm />
    </Create>
  )
}

export default PenaltyCreate
