import * as React from 'react'
import * as _ from 'lodash'
import VoucherCode from 'voucher-code-generator'
import {
  FormDataConsumer,
  NumberInput,
  RecordContextProvider,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import UserInput from 'src/components/inputs/UserInput'
import OrderInput from 'src/components/inputs/OrderInput'
import { ComplaintStatus, ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { ComplaintResolveTypeList } from 'src/types/enum/ComplaintResolveType'
import { ComplaintReasonList } from 'src/types/enum/ComplaintReason'
import UnitInput from 'src/components/inputs/UnitInput'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { ComplaintSourceList } from 'src/types/enum/ComplaintSource'
import Stack from '@mui/material/Stack'
import { useState } from 'react'
import { PromoCodeGenerate } from '../ComplaintPromoCodeGenerate'

interface Props {
  refresh?: boolean
  isShown?: boolean
  orderId?: number | string
  unitId?: number
  userId?: string
  onClose?: () => void
}

export const ComplaintCreateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  //
  const [generatePromoCode, setGeneratePromoCode] = useState<boolean>(false)
  const [promoCode, setPromoCode] = useState<string | null>(null)

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.create('complaint', { data: { ...data } })
    //
    refresh()
    notify('Жалоба создана', { type: 'success' })

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  const onGeneratePromoCode = () => {
    setGeneratePromoCode(true)
    //
    if (_.isString(promoCode) && !_.isEmpty(promoCode)) {
      return promoCode
    }
    const newPromoCode = VoucherCode.generate({
      length: 6,
      count: 1,
      charset: VoucherCode.charset('alphabetic') + VoucherCode.charset('numbers'),
    })[0]
    //
    setPromoCode(newPromoCode)
    //
    return newPromoCode
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'xs'}
        resource={'area'}
        title={'Создать жалобу'}
        defaultValues={{
          orderId: props.orderId,
          unitId: props.unitId,
          userId: props.userId,
          status: ComplaintStatus.Created,
        }}
        onClose={props.onClose}
        saveButtonLabel={'Создать'}
        save={handleSubmit}
      >
        <UserInput source="userId" label={'Клиент'} fullWidth />
        <OrderInput source="orderId" label={'Заказ'} fullWidth />
        <UnitInput source="unitId" label={'Заведение'} fullWidth validate={[required()]} />
        <SelectInput source={'source'} label={'Источник'} choices={ComplaintSourceList} validate={required()} />
        <SelectInput source={'status'} label={'Статус'} choices={ComplaintStatusList} />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.status === ComplaintStatus.Resolved && (
              <SelectInput
                source={'resolveType'}
                label={'Решение'}
                choices={ComplaintResolveTypeList}
                validate={[required()]}
              />
            )
          }
        </FormDataConsumer>
        <TextInput source="text" label={'Текст жалобы'} fullWidth multiline />
        <SelectInput source={'reasonType'} label={'Причина'} choices={ComplaintReasonList} />
        <TextInput source="adminComment" label={'Комментарий менеджера'} multiline />
        <NumberInput source="withholdingAmount" label={'Сумма удержания'} />
        <NumberInput source="appealAmount" label={'Сумма апеляции'} />
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <MediaInput source={'assets'} isMulti={true} label={'Фотографии'} />
        </Stack>
        <TextInput source="answer" label={'Ответ'} fullWidth multiline />
        <PromoCodeGenerate
          generatePromoCodeFlag={generatePromoCode}
          onGeneratePromoCode={onGeneratePromoCode}
          onDeletePromoCode={() => setGeneratePromoCode(false)}
          isCreation
        />
      </ModalForm>
    </RecordContextProvider>
  )
}
