import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import PromoCodeForm from 'src/resources/promo-code/PromoCodeForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Промокод доставки</span>

const PromoCodeCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <PromoCodeForm isCreation />
    </Create>
  )
}

export default PromoCodeCreate
