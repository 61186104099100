import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import UnitSelectionForm from 'src/resources/unit-selection/UnitSelectionForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новая Подборка ресторанов</span>

const UnitSelectionCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <UnitSelectionForm />
    </Create>
  )
}

export default UnitSelectionCreate
