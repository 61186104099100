export enum TariffType {
  DeliveryOrder = 'deliveryOrder',
  RestaurantOrder = 'restaurantOrder',
  CoffeeOrder = 'coffeeOrder',
  BanquetOrder = 'banquetOrder',
}
export const TariffTypeList = [
  { id: TariffType.DeliveryOrder, name: 'Доставка заказ' },
  { id: TariffType.RestaurantOrder, name: 'Рестораны заказ' },
  { id: TariffType.CoffeeOrder, name: 'Кофейни заказ' },
  { id: TariffType.BanquetOrder, name: 'Банкеты заказ' },
]
