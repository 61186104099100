import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import { PageForm } from 'src/resources/page/PageForm'

interface TitleProps {
  record?: any
}

const Title: FC<TitleProps> = ({ record }) => <span>Новая Текстовая Страница</span>

const PageCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <PageForm />
    </Create>
  )
}

export default PageCreate
