import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import PromoUnitGroupForm from './PromoUnitGroupForm'

const PromoUnitGroupCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<span>Новая группа</span>}>
      <PromoUnitGroupForm isCreation />
    </Create>
  )
}

export default PromoUnitGroupCreate
