import React, { ComponentProps, ReactElement, useMemo } from 'react'
import * as _ from 'lodash'
import { useInput } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'
import { useGetList } from 'react-admin'
import { PromoTypeList } from 'src/types/enum/PromoType'
import { IPromo } from 'src/types/interfaces/IPromo'
import PromoCardWithDetails from 'src/components/Promo/PromoCardWithDetails'
import styles from './index.module.scss'
import { PromoKind, PromoKindList } from 'src/types/enum/PromoKind'

export interface IPromoGroup {
  id: string
  name: string
  kind: PromoKind
  items: IPromo[]
}

export interface Props {
  label?: string | ReactElement
  source: string
  fullWidth?: boolean

  helperText?: ComponentProps<typeof InputHelperText>['helperText']
  record?: Record<any, any>
  resource?: string
  variant?: string
  onChange?: (val: any) => void
  [key: string]: any
}

const PromoServiceDeliverySelectPageInput = (props: Props) => {
  const { fullWidth = true, helperText, label, source, resource, variant, ...rest } = props

  const {
    field: { value, onChange },
  } = useInput({ source, ...rest })

  const {
    data,
    isLoading,
    error: errorList,
  } = useGetList('promo-service-delivery', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'sort', order: 'ASC' },
    filter: { published: true },
  })

  const handleChange = (promo: IPromo) => {
    onChange(promo.id)
    if (props.onChange) {
      props.onChange(promo)
    }
  }

  const groups = useMemo<IPromoGroup[]>(() => {
    const groups: IPromoGroup[] = []

    for (const group of PromoTypeList) {
      const items = data?.filter((i) => i.type === group.id) ?? []
      const kinds = _.uniq(data?.map((i) => i.kind))

      if (items.length > 0) {
        if (kinds.length > 1) {
          for (let i = 0; i < kinds.length; i++) {
            groups.push({
              id: `${kinds[i]}-${group.id}`,
              kind: kinds[i],
              name: `${PromoKindList.find((el) => el.id === kinds[i])?.name} - ${group.name}`,
              items: items.filter((promo) => promo.kind === kinds[i]),
            })
          }
        } else {
          groups.push({ id: group.id, kind: kinds[0].kind, name: group.name, items })
        }
      }
    }
    return groups.sort((a, b) => (a.kind === PromoKind.Public ? -1 : 1)).filter((group) => !_.isEmpty(group.items))
  }, [data])

  if (!data && isLoading) {
    return <div>Загрузка...</div>
  }

  return (
    <div className={styles.root}>
      {groups.map((group) => (
        <div key={group.id} className={styles.group}>
          <div className={styles.groupName}>{group.name}</div>
          <div className={styles.items}>
            {group.items.map((i) => (
              <PromoCardWithDetails key={i.id} selected={value === i.id} promo={i} onClick={() => handleChange(i)} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PromoServiceDeliverySelectPageInput
