import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const PromoCodeLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) => `/promo-code/${get(record, props.source ?? 'promoCode.id')}`}
      source={props.source ?? 'promoCode.id'}
      value={(record) =>
        props.mainSource ? get(record, props.mainSource ?? 'promoCode')?.code : record?.promoCode?.code
      }
      label={props.label}
    />
  )
}

export default PromoCodeLinkField
