import * as React from 'react'

import styles from './index.module.scss'

import { PromoIcon } from 'src/types/enum/PromoIcon'
import { PromoColor } from 'src/types/enum/PromoColor'
import { PromoTemplate } from 'src/types/enum/PromoTemplate'
import { useMemo } from 'react'
import OnePlusOneSvg from 'src/components/svg/OnePlusOneSvg'
import PercentSvg from 'src/components/svg/PercentSvg'
import ScooterSvg from 'src/components/svg/ScooterSvg'
import RubleSvg from 'src/components/svg/RubleSvg'
import GiftSvg from 'src/components/svg/GiftSvg'

interface Props {
  image?: any
  imageLarge?: any
  icon: PromoIcon
  color: PromoColor
  badgeColor: PromoColor
  template: PromoTemplate
  badge?: string
  name?: string
  text?: string
  bigText?: string
  shortDesc?: string
}

const PromoColorImageBigCard = (props: Props) => {
  const icon = useMemo(() => {
    const color = props.badgeColor
    switch (props.icon) {
      case PromoIcon.OnePlusOne:
        return <OnePlusOneSvg color={color} />
      case PromoIcon.Ruble:
        return <RubleSvg color={color} />
      case PromoIcon.Scooter:
        return <ScooterSvg color={color} />
      case PromoIcon.Percent:
        return <PercentSvg color={color} />
      case PromoIcon.Gift:
        return <GiftSvg color={color} />
    }
  }, [props.icon])
  const isGradient = props.color?.includes(';')

  return (
    <div className={styles.root}>
      <div
        className={styles.header}
        style={{
          ...(props.color
            ? {
                background: isGradient
                  ? `linear-gradient(125.99deg, ${props.color.split(';')[0]} -2.47%, ${props.color.split(';')[1]} 86.22%)`
                  : props.color,
              }
            : {}),
        }}
      >
        {props.imageLarge && <img src={props.imageLarge.link} className={styles.image} />}
        {!props.imageLarge && <div />}
        <div className={styles.name}>{props.bigText}</div>
        <div className={styles.desc}>{props.shortDesc}</div>
        <div className={styles.background}>
          <img src="/img/promoBg/promoBg.png" srcSet="/img/promoBg/promoBg@2x.png 2x, /img/promoBg/promoBg@3x.png 3x" />
        </div>
      </div>
      <div className={styles.page}>
        {props.text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: props.text! }}></div>}
      </div>
    </div>
  )
}
export default PromoColorImageBigCard
