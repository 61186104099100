import * as React from 'react'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { useRecordContext } from 'ra-core'
import { FieldProps, RaRecord, useCreatePath, useResourceContext } from 'react-admin'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  type?: 'show' | 'edit'
  resource?: string
}

const IdShowButtonField = (props: Props) => {
  const resource = useResourceContext(props)
  const record = useRecordContext(props)
  const createPath = useCreatePath()
  const value = get(record, props.source ?? '')
  return (
    <Link to={createPath({ type: props.type || 'edit', resource: props.resource ?? resource, id: record.id })}>
      {value}
    </Link>
  )
}

export default IdShowButtonField
