export enum PromoReserveChangeSource {
  Aggregator = 'aggregator',
  Partner = 'partner',
  PartnerWeb = 'admin',
  User = 'user',
  PaymentApi = 'paymentApi',
}

export const PromoReserveChangeSourceList = [
  { id: PromoReserveChangeSource.Aggregator, name: 'Агрегатор' },
  { id: PromoReserveChangeSource.Partner, name: 'Вендор приложение' },
  { id: PromoReserveChangeSource.PartnerWeb, name: 'Вендор админка' },
  { id: PromoReserveChangeSource.User, name: 'Клиент' },
  { id: PromoReserveChangeSource.PaymentApi, name: 'API платежное' },
]
