import * as React from 'react'
import * as _ from 'lodash'
import { RecordContextProvider, required, TextInput, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}

export const AddNoteToUserModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.create('notes-to-user', {
      data: {
        ...data,
        userId: record.id,
      },
    })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  const validateNote = (value: string): string | null => {
    if (!_.isString(value) || _.isEmpty(value.trim())) {
      return 'Это поле обязательно к заполнению'
    }
    if (value.length < 3) {
      return 'Не менее 3х символов'
    }
    return null
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'xs'}
        resource={'notes-to-user'}
        title={`Добавить пометку о клиенте ID ${record.id} ${record.name || ''}`}
        onClose={props.onClose}
        saveButtonLabel={'Добавить'}
        saveButtonIcon={<QuestionAnswerIcon />}
        save={handleSubmit}
      >
        <TextInput name={'note'} source="note" label={'Пометка'} validate={[required(), validateNote]} multiline />
      </ModalForm>
    </RecordContextProvider>
  )
}
