import * as React from 'react'
import { FC } from 'react'
import { EditProps, useResourceContext } from 'react-admin'
import { ArticleForm } from 'src/resources/article/ArticleForm'
import { CustomEdit } from 'src/components/CustomEdit'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => {
  const resource = useResourceContext()
  const isNews = resource === 'article-news'
  return record ? (
    <span>
      {isNews ? 'Новость' : 'Статья'} {record.name}
    </span>
  ) : null
}
const ArticleEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect={'list'} mutationMode="pessimistic" title={<Title />}>
      <ArticleForm />
    </CustomEdit>
  )
}
export default ArticleEdit
