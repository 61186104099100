export enum DeliveryKind {
  Disabled = 'disabled',
  OwnService = 'ownService',
  AggregatorService = 'aggregatorService',
}

export const DeliveryKindList = [
  { id: DeliveryKind.Disabled, name: 'Не доставляем' },
  { id: DeliveryKind.OwnService, name: 'Использую свою службу доставки' },
  // {id: DeliveryKind.AggregatorService, name: 'Использую службу доставки Агргегатора'},
]
