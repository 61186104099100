import { SelectInput } from 'react-admin'
import * as React from 'react'
import { useMemo } from 'react'
import { range } from 'src/utils/array'

interface Props {
  source: string
  label?: string
  fullWidth?: boolean
  type: 'minute' | 'hour' | 'day'
  min?: number
  max: number
  step: number
  showEmptyOption?: boolean
  validate?: any
}
const TimeSelectInput = (props: Props) => {
  const options = useMemo(() => {
    const numbers = range(props.min ?? props.step, props.max + props.step, props.step)
    return numbers.map((i) => ({ id: i, name: `${i}` }))
  }, [props.type, props.max, props.min])
  return <SelectInput variant={'outlined'} choices={options} {...props} />
}

export default TimeSelectInput
