import ListIcon from '@mui/icons-material/List'
import OrderList from 'src/resources/order/OrderList'
import OrderEdit from 'src/resources/order/OrderEdit'
import Index from 'src/resources/order/OrderShow'

export default {
  edit: OrderEdit,
  show: Index,
  list: OrderList,
  icon: ListIcon,
}
