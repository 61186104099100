import * as React from 'react'
import { required, SimpleForm, TextInput, NumberInput, SelectInput, FormDataConsumer } from 'react-admin'
import { SubscriptionType, SubscriptionTypeList } from 'src/types/enum/SubscriptionType'

const SubscriptionDescriptionForm = (props: any) => {
  return (
    <SimpleForm>
      <TextInput source={'key'} label="Ключ" validate={[required()]} />
      <TextInput source={'description'} label="Описание" multiline={true} />
      <SelectInput source={'type'} label="Тип" choices={SubscriptionTypeList} validate={[required()]} />
      <NumberInput source={'price'} label="Цена" validate={[required()]} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.type === SubscriptionType.Subscription && (
            <NumberInput source={'days'} label="Кол-во дней подписки" validate={[required()]} />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => (
          <NumberInput
            source={'firstTimePrice'}
            label={formData.type === SubscriptionType.OneAction ? 'Цена первой покупки' : 'Цена первого месяца'}
            helperText={
              formData.type === SubscriptionType.OneAction
                ? ''
                : 'Если поле пустое - то триала не будет, если 0 то первый месяц бесплатно'
            }
          />
        )}
      </FormDataConsumer>
    </SimpleForm>
  )
}
export default SubscriptionDescriptionForm
