import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, SelectField, DateField, TextInput, usePermissions } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { AdminRoleList } from 'src/types/enum/AdminRole'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Admin',
  })
}

const _Filter = (props: any) => {
  const { permissions } = usePermissions()
  return (
    <Filter {...props}>
      <TextInput source="email:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <IfCanAccess source={'partnerId'} label={'Партнер'} aggregator action={'show'}>
        <PartnerInput source={'partnerId'} label={'Партнер'} alwaysOn={true} />
      </IfCanAccess>
    </Filter>
  )
}

const AdminList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Администраторы"
      sort={{ field: 'name', order: 'ASC' }}
      empty={
        <EmptyList
          title={'Нет администраторов'}
          description={'Вы можете добавить админа'}
          buttonText={'Добавить администратора'}
        />
      }
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="name" label={'Имя'} />
        <TextField source="email" label={'Email'} />
        <SelectField source="role" label={'Роль'} choices={AdminRoleList} />
        <IfCanAccess aggregator action={'show'}>
          <TextField source="partner.name" label={'Партнер'} />
        </IfCanAccess>
        <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default AdminList
