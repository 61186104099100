import * as React from 'react'
import {
  TextInput,
  required,
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  NumberInput,
  minLength,
  maxLength,
  ReferenceManyField,
  Pagination,
  DateField,
  TextField,
} from 'react-admin'
import FormGroup from 'src/components/FormGroup'
import { PartnerStatus, PartnerStatusList } from 'src/types/enum/PartnerStatus'
import { PartnerCommissionTypeList } from 'src/types/enum/PartnerCommissionType'
import { MultiEmailInput } from 'src/components/inputs/MultiEmailInput'
import InnInput, { IInnInputCompany } from 'src/components/inputs/InnInput'
import { useFormContext } from 'react-hook-form'
import RelatedList from 'src/components/list/RelatedList'
import { useRecordContext } from 'ra-core'
import PartnerTariffDescriptionField from 'src/components/fields/PartnerTariffDescriptionField'

type Props = {
  isCreation: boolean
}

const PartnerTariffs = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="tariff"
      target="partnerId"
      sort={{ field: 'updatedAt', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'tariff'}
        hasCreate={false}
        emptyTitle={'У партнера нет идивидуальных тарифов'}
        bulkActionButtons={false}
        rowClick={'edit'}
      >
        <TextField source={'name'} label={'Название'} />
        <PartnerTariffDescriptionField source={'descriptions'} label={'Настройки'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <DateField source="createdAt" label={'Время создания'} showTime={true} />
        <DateField source="updatedAt" label={'Время обновления'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const InfoTab = (props: Props) => {
  const form = useFormContext()

  const handleChangeInn = (company: IInnInputCompany | null) => {
    if (!company) {
      return
    }
    if (company?.kpp) {
      form.setValue('legalKpp', company.kpp)
    }
    if (company?.name) {
      form.setValue('name', company.name)
    }
    if (company?.address) {
      form.setValue('legalAddress', company.address)
    }
    if (company?.ogrn) {
      form.setValue('legalOgrn', company.ogrn)
    }
    if (company?.okpo) {
      form.setValue('legalOkpo', company.okpo)
    }
    if (company?.legalType) {
      form.setValue('legalType', company.legalType)
    }
  }

  return (
    <>
      <FormGroup title={'Юридические данные'}>
        {/* Partner details */}
        <InnInput
          name="legalInn"
          source="legalInn"
          label={'ИНН'}
          fullWidth
          onChange={handleChangeInn}
          validate={[
            required('Обязательно для заполнения'),
            minLength(10, 'Минимальное кол-во 10'),
            maxLength(12, 'Максимальное кол-во 12'),
          ]}
        />

        <TextInput name="name" source="name" label={'Юридическое лицо'} validate={[required()]} fullWidth />
        <TextInput name="legalAddress" source="legalAddress" label={'Юридическое адрес'} fullWidth />
        <TextInput name="legalBik" source="legalBik" label={'БИК'} fullWidth />
        <TextInput name="legalKpp" source="legalKpp" label={'КПП'} fullWidth />
        <TextInput name="legalOgrn" source="legalOgrn" label={'ОГРН/ИП'} fullWidth />
        <TextInput name="legalOkpo" source="legalOkpo" label={'ОКПО'} fullWidth />
        {/* Bank details */}
        <TextInput name={'bankBik'} source="bankBik" label={'БИК Банка'} fullWidth />
        <TextInput name={'bank'} source="bank" label={'Банк'} fullWidth />
        <TextInput name={'bankAccount'} source="bankAccount" label={'Расчетный счет'} fullWidth />
        <TextInput
          name={'bankCorrespondentAccount'}
          source="bankCorrespondentAccount"
          label={'Корреспондентский счет банка'}
          fullWidth
        />
        <TextInput name={'bankInn'} source="bankInn" label={'ИНН Банка'} fullWidth />
        <TextInput name={'bankLegalAddress'} source="bankLegalAddress" label={'Юридический адрес банка'} fullWidth />
      </FormGroup>
      <FormGroup title={'Контакты'}>
        <TextInput name={'contactPerson'} source="contactPerson" label={'Контактное лицо'} fullWidth />
        <TextInput name={'phone'} source="phone" label={'Номер телефона'} fullWidth />
        <MultiEmailInput source={'contactEmails'} label={'Перечень email для уведомлений'} />
      </FormGroup>

      <FormGroup title={'Коммисия'}>
        <SelectInput
          source="commissionType"
          label={'Тип коммисии'}
          choices={PartnerCommissionTypeList}
          validate={[required()]}
          fullWidth
        />
        <NumberInput
          name={'commissionAmount'}
          source="commissionAmount"
          label={'Размер комиссии'}
          validate={[required()]}
          fullWidth
        />
      </FormGroup>
      <FormGroup title={'Статус'}>
        <RadioButtonGroupInput source="status" label={false} choices={PartnerStatusList} row={false} />
      </FormGroup>
      {!props.isCreation && <PartnerTariffs />}
    </>
  )
}

const PartnerForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ status: PartnerStatus.Draft }}>
      <InfoTab isCreation={props.isCreation} />
    </SimpleForm>
  )
}

export default PartnerForm
