import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  useGetIdentity,
  useGetOne,
  useResourceContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import { IPromo } from 'src/types/interfaces/IPromo'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { PromoUnitStatus, PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import PromoServiceRestaurantsSelectPageInput from 'src/components/inputs/PromoServiceRestaurantsSelectPageInput'
import PromoCardWithDetails from 'src/components/Promo/PromoCardWithDetails'
import { AdminRole, AggregatorAdminRoles } from 'src/types/enum/AdminRole'
import { ServiceType } from 'src/types/enum/ServiceType'
import { RecordChanges, RecordType } from 'src/components/RecordChangeList'

interface DetailsProps {
  serviceType: ServiceType
  isCreation: boolean
}

const Details = (props: DetailsProps) => {
  const form = useFormContext()
  const { data: promo } = useGetOne<IPromo>(`promo-service-${props.serviceType}-public`, {
    id: form.getValues('promoId'),
  })

  return (
    <Box>
      <IfCanAccess aggregator action={'show'}>
        <SelectInput source={'status'} label={'Статус'} choices={PromoUnitStatusList} />
      </IfCanAccess>

      {promo && <PromoCardWithDetails promo={promo} />}

      <DateTimeInput label="Время начала действия" source="startsAt" variant={'outlined'} />
      <DateTimeInput label="Время окончания действия" source="endsAt" variant={'outlined'} />

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {() => promo?.settings.canSchedule && <BooleanInput source="hasSchedule" label={'Есть расписание'} fullWidth />}
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          promo?.settings.canSchedule &&
          formData.hasSchedule && <WorkScheduleMultiTimeInput source={'schedule'} label={'Расписание'} />
        }
      </FormDataConsumer>
    </Box>
  )
}

enum FormStep {
  Partner = 'partner',
  Unit = 'unit',
  Promo = 'promo',
  Details = 'details',
}

const PromoTab = (props: DetailsProps) => {
  const form = useFormContext()
  const { data } = useGetIdentity()
  const [step, setStep] = useState<FormStep>(form.getValues('promoId') ? FormStep.Details : FormStep.Partner)
  const stepRef = useRef<FormStep>(step)

  const handleChangePromo = (_: IPromo) => {
    setStep(FormStep.Details)
  }

  const handleChangeUnit = (_val: number) => {
    setStep(FormStep.Promo)
    form.setValue('promoId', null)
  }

  const handleChangePartner = (val: number) => {
    setStep(FormStep.Unit)
    form.reset()
    form.setValue('partnerId', val)
    form.setValue('promoId', null)
    form.setValue('restaurantId', null)
  }

  useEffect(() => {
    if (data?.role && !AggregatorAdminRoles.includes(data?.role as AdminRole)) {
      if (stepRef.current === FormStep.Partner) {
        setStep(FormStep.Unit)
      }
    }
  }, [data?.role])

  return (
    <>
      <IfCanAccess aggregator action={'show'}>
        <PartnerInput
          resettable={true}
          fullWidth={true}
          source="partnerId"
          label={'Партнер'}
          onChange={handleChangePartner}
          validate={required()}
        />
      </IfCanAccess>
      {[FormStep.Promo, FormStep.Unit, FormStep.Details].includes(step) && (
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) => (
            <RestaurantInput
              source={'restaurantId'}
              serviceType={props.serviceType}
              fullWidth={true}
              resettable={false}
              allowEmpty={false}
              onChange={handleChangeUnit}
              filter={{ ...(formData.partnerId ? { partnerId: formData.partnerId } : {}) }}
              validate={[required()]}
            />
          )}
        </FormDataConsumer>
      )}

      {step == FormStep.Promo && (
        <PromoServiceRestaurantsSelectPageInput
          serviceType={props.serviceType}
          source={'promoId'}
          onChange={handleChangePromo}
        />
      )}
      {step == FormStep.Details && <Details {...props} />}
      {!props.isCreation && (
        <RecordChanges
          recordType={RecordType.PromoRestaurant}
          statusList={PromoUnitStatusList}
          additionalMap={[
            { key: 'startsAt', label: 'Время начала действия', isDate: true },
            { key: 'endsAt', label: 'Время окончания действия', isDate: true },
          ]}
        />
      )}
    </>
  )
}

const PromoRestaurantForm = (props: any) => {
  const resource = useResourceContext()

  const serviceType = useMemo<ServiceType>(() => {
    if (resource === 'promo-restaurant') {
      return ServiceType.Restaurants
    }
    return resource.replace('promo-restaurant-', '').replace('promo-', '') as ServiceType
  }, [resource])

  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ status: PromoUnitStatus.Active }}>
      <PromoTab serviceType={serviceType} isCreation={props.isCreation} />
    </SimpleForm>
  )
}

export default PromoRestaurantForm
