import * as React from 'react'
import { useRecordContext } from 'ra-core'
import { FieldProps, RaRecord } from 'react-admin'
import get from 'lodash/get'
type Callback = (record: RaRecord) => string
interface Props<RecordType extends RaRecord = any> extends FieldProps {
  link: string | Callback
  value?: string
}

const LinkExternalField = (props: Props) => {
  const record = useRecordContext(props)
  const value = get(record, props.source ?? '')
  return (
    <a
      target={'_blank'}
      href={encodeURI(
        (typeof props.link === 'function' ? props.link(record as any) : props.link)?.replaceAll(
          '$id',
          record['id'] as string,
        ),
      )}
      rel="noreferrer"
    >
      {value}
    </a>
  )
}

export default LinkExternalField
