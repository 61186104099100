import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import ComplaintForm from 'src/resources/complaint/ComplaintForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новая Жалоба</span>

const ComplaintCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <ComplaintForm isCreation />
    </Create>
  )
}

export default ComplaintCreate
