import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import RestaurantOrderForm from 'src/resources/restaurant-order/RestaurantOrderForm'
import { CustomEdit } from 'src/components/CustomEdit'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Заказ лок ресторана {record.name}</span> : null)

const RestaurantOrderEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <RestaurantOrderForm />
    </CustomEdit>
  )
}
export default RestaurantOrderEdit
