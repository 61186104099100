import * as React from 'react'
import { TabbedForm, FormTab, SelectInput } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import { OrderStatusList } from 'src/types/enum/OrderStatus'
import { PaymentStatusList } from 'src/types/enum/PaymentStatus'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import { DeliveryMethodList } from 'src/types/enum/DeliveryMethod'
import Formatter from 'src/utils/formatters'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <FormTab label="Заказ" path={'benefit'} {...props}>
      <SelectInput source="status" label={'Статус заказа'} choices={OrderStatusList} fullWidth />
      <SelectInput source="paymentStatus" label={'Статус оплаты'} choices={PaymentStatusList} fullWidth />
      <SelectInput source="paymentMethod" label={'Способ оплаты'} choices={PaymentMethodList} fullWidth />
      <SelectInput source="deliveryMethod" label={'Способ доставки'} choices={DeliveryMethodList} fullWidth />
    </FormTab>
  )
}
const OrderForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab />
    </TabbedForm>
  )
}
export default OrderForm
