import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const BanquetLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) => `/banquet/${get(record, props.source ?? 'banquetId')}/show`}
      source={props.source ?? 'banquet.id'}
      value={(record) => (props.mainSource ? get(record, props.mainSource ?? 'banquet')?.id : record?.banquetId)}
      label={props.label}
    />
  )
}

export default BanquetLinkField
