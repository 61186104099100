import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, FunctionField, TextField, SearchInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { Datagrid } from 'src/components/list/Datagrid'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'UnitSelection',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <SearchInput source="name:$contL" variant={'outlined'} alwaysOn={true} resettable={true} />
    </Filter>
  )
}

const UnitSelectionList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Подборка ресторанов"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'category.name'} label={'Категория'} />
        <FunctionField source={'innerName'} label={'Название'} render={(order: any) => order.innerName ?? order.name} />
      </Datagrid>
    </List>
  )
}

export default UnitSelectionList
