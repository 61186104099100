import * as React from 'react'
import { useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonProps } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import { useTranslate } from 'ra-core'
import { useTiptapEditor } from 'ra-input-rich-text'
import ModalMedia from 'src/components/ModalMedia'
import { IAsset } from 'src/components/types'
export const RichTextInputImageButton = (props: Omit<ToggleButtonProps, 'value'>) => {
  const editor = useTiptapEditor()
  const translate = useTranslate()
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const label = translate('ra.tiptap.blockquote', {
    _: 'Blockquote',
  })

  useEffect(() => {
    const handleUpdate = () => {
      setIsActive(editor && editor.isActive('blockquote'))
    }

    if (editor) {
      editor.on('update', handleUpdate)
      editor.on('selectionUpdate', handleUpdate)
    }

    return () => {
      if (editor) {
        editor.off('update', handleUpdate)
        editor.off('selectionUpdate', handleUpdate)
      }
    }
  }, [editor])
  const handleSelectAsset = (records: IAsset[]) => {
    if (records.length === 0) {
      return
    }
    const record = records[0]
    setShowMediaModal(false)
    const imagePath = `/api/asset/files/${record.source}?preset=text&fpx=${record.focalPoint?.x || '0.5'}&fpy=${record.focalPoint?.y || '0.5'}`
    const f = editor.chain().focus() as any
    f.setImage({ src: imagePath }).run()
  }
  return (
    <>
      <ToggleButton
        aria-label={label}
        title={label}
        {...props}
        disabled={!editor?.isEditable}
        onClick={() => setShowMediaModal(true)}
        selected={isActive}
        value="quote"
      >
        <ImageIcon fontSize="inherit" />
      </ToggleButton>
      {showMediaModal && (
        <ModalMedia
          isShown={showMediaModal}
          onClose={() => setShowMediaModal(false)}
          onSelect={handleSelectAsset}
          isMulti={false}
        />
      )}
    </>
  )
}
