import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const UserLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) => `/user/${get(record, props.source ?? 'user.id')}/show`}
      source={props.source ?? 'user.id'}
      value={(record) => (props.mainSource ? get(record, props.mainSource ?? 'user')?.name : record?.user?.name)}
      label={props.label}
    />
  )
}

export default UserLinkField
