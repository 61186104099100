import * as React from 'react'
import { TabbedForm, FormTab, TextInput, required, BooleanInput } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import Formatter from 'src/utils/formatters'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <FormTab label="Товарный раздел" path={'benefit'} {...props}>
      <TextInput source="name" label={'Название'} validate={[required()]} fullWidth onChange={handleNameChange} />
      <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
      <BooleanInput source="published" label={'Активен'} fullWidth />
    </FormTab>
  )
}
const CategoryForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'} defaultValues={{ published: true }}>
      <InfoTab />
    </TabbedForm>
  )
}
export default CategoryForm
