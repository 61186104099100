import ListIcon from '@mui/icons-material/List'
import PromoList from 'src/resources/promo-service-delivery/PromoList'
import PromoEdit from 'src/resources/promo-service-delivery/PromoEdit'
import PromoCreate from 'src/resources/promo-service-delivery/PromoCreate'

export default {
  create: PromoCreate,
  edit: PromoEdit,
  list: PromoList,
  icon: ListIcon,
}
