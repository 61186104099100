import * as React from 'react'
import { FC } from 'react'
import { EditProps, TopToolbar, usePermissions } from 'react-admin'
import UnitForm from 'src/resources/unit/UnitForm'
import { CustomEdit } from 'src/components/CustomEdit'
import { ModalButton } from 'src/components/Modal/ModalButton'
import PauseIcon from '@mui/icons-material/Pause'
import { UnitPauseModal } from 'src/resources/unit/UnitPauseModal'
import Box from '@mui/material/Box'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import { UnitAddDeliveryTimeModal } from 'src/resources/unit/UnitAddDeliveryTimeModal'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { UnitBlockedModal } from 'src/resources/unit/UnitBlockedModal'
import { Lock } from 'mdi-material-ui'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'

const Actions = () => {
  const { permissions } = usePermissions()

  return (
    <TopToolbar sx={{ alignItems: 'center' }}>
      <IfCanAccess aggregator action={'show'}>
        <PhoneCallActionField source={'phone'} />
      </IfCanAccess>
      <ModalButton label={'Пауза'} icon={<PauseIcon />} modal={<UnitPauseModal resource={'unit'} />} />
      <Box ml={3}>
        <ModalButton
          label={'Время доставки'}
          icon={<MoreTimeIcon />}
          key={'import'}
          modal={<UnitAddDeliveryTimeModal resource={'unit'} />}
        />
      </Box>
      <IfCanAccess aggregator resource={'unit'} action={'edit'}>
        <Box ml={3}>
          <ModalButton label={'Заблокировать'} icon={<Lock />} modal={<UnitBlockedModal />} />
        </Box>
      </IfCanAccess>
    </TopToolbar>
  )
}
const UnitEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" actions={<Actions />}>
      <UnitForm isEdit />
    </CustomEdit>
  )
}
export default UnitEdit
