import React from 'react'
import { CommonInputProps } from 'react-admin'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import {
  RichTextInput as RaRichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
} from 'ra-input-rich-text'
import { RichTextInputImageButton } from 'src/components/inputs/RichTextInput/RichTextInputImageButton'

export interface Props extends CommonInputProps {}
export const RichTextInput = (props: Props) => {
  return (
    <RaRichTextInput
      editorOptions={{
        extensions: [
          StarterKit,
          Underline,
          Link,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
        ],
      }}
      toolbar={
        <RichTextInputToolbar>
          <LevelSelect />
          <FormatButtons />
          <ListButtons />
          <LinkButtons />
          <QuoteButtons />
          <ClearButtons />
          <RichTextInputImageButton />
        </RichTextInputToolbar>
      }
      {...props}
    />
  )
}
