import ListIcon from '@mui/icons-material/List'
import SubscriptionDescriptionList from 'src/resources/subscription-description/SubscriptionDescriptionList'
import SubscriptionDescriptionEdit from 'src/resources/subscription-description/SubscriptionDescriptionEdit'
import SubscriptionDescriptionCreate from 'src/resources/subscription-description/SubscriptionDescriptionCreate'

export default {
  create: SubscriptionDescriptionCreate,
  edit: SubscriptionDescriptionEdit,
  list: SubscriptionDescriptionList,
  icon: ListIcon,
}
