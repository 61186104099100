import { AutocompleteInput, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { omit } from 'lodash'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  partnerId?: number
  validate?: any
  onChange?: (val: any) => void
  fullWidth?: boolean
}

const OrderInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source="orderId"
      reference="order"
      variant={'outlined'}
      sort={{ field: 'id', order: 'ASC' }}
      perPage={10}
      enableGetChoices={({ q }) => q && q.length >= 3}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'number:$endsL': q })}
        optionText={(i) => `№ ${i.number ?? i.id}`}
        label="Заказ"
        onChange={props.onChange}
        validate={props.validate}
        fullWidth={props.fullWidth}
      />
    </ReferenceInput>
  )
}

export default OrderInput
