import * as React from 'react'
import { AppBar, AppBarProps } from 'react-admin'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styles from './index.module.scss'

export const MyAppBar = (props: AppBarProps) => {
  return (
    <AppBar
      elevation={1}
      sx={{
        '& .RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      }}
      {...props}
    >
      <img className={styles.logo} src={'/img/logo.svg'} alt={'logo'} />
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title"></Typography>
      </Box>
    </AppBar>
  )
}
