import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, DateField, TextField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PhoneField from 'src/components/fields/PhoneField'
import { Datagrid } from 'src/components/list/Datagrid'
const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'User',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="phone:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
    </Filter>
  )
}

const UserList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<_Filter />}
      title="Клиенты"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} hasCreate={false} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'show'}>
        <TextField type={'show'} source={'id'} label={'ID'} />
        <PhoneField source={'phone'} label={'Телефон'} />
        <TextField source={'name'} label={'Имя'} />
        <DateField source="createdAt" label={'Регистрация'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default UserList
