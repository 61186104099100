import * as React from 'react'
import { Show, TextField, SimpleShowLayout, DateField, Pagination, ReferenceManyField, SelectField } from 'react-admin'
import { useRecordContext } from 'ra-core'
import Box from '@mui/material/Box'
import RelatedList from 'src/components/list/RelatedList'
import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { NotificationAttemptStatusList } from 'src/types/enum/NotificationAttemptStatus'
import { NotificationChannelList } from 'src/types/enum/NotificationChannel'

const NotificationAttempts = () => {
  const record = useRecordContext()
  return (
    <ReferenceManyField
      reference="notification-attempt"
      target="notificationId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'notification-attempt'}
        hasCreate={false}
        emptyTitle={'Пока нет попыток отправки'}
        bulkActionButtons={false}
      >
        <SelectField source={'channel'} label={'Канал'} choices={NotificationChannelList} />
        <SelectField source={'status'} label={'Статус'} choices={NotificationAttemptStatusList} />
        <DateField source="createdAt" label={'Создан'} showTime={true} />
        <DateField source="completedAt" label={'Отправлен'} showTime={true} />
        <DateField source="deliveredAt" label={'Доставлен'} showTime={true} />
        <DateField source="openedAt" label={'Открыт'} showTime={true} />
        <TextField source={'error'} label={'Ошибка'} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}
const Index = () => {
  return (
    <Show emptyWhileLoading>
      <SimpleShowLayout>
        <TextField source={'name'} label={'Имя'} />
        <Header>Попытки отправки</Header>
        <NotificationAttempts />
      </SimpleShowLayout>
    </Show>
  )
}
export default Index
