import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  required,
  FormDataConsumer,
  useCreate,
  useUpdate,
  useGetIdentity,
} from 'react-admin'
import { ChangeEventHandler, useMemo } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { AdminRole, AdminRoleList } from 'src/types/enum/AdminRole'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import UnitArrayInput from 'src/components/inputs/UnitArrayInput'
import RestaurantAllArrayInput from 'src/components/inputs/RestaurantAllArrayInput'
import PartnerArrayInput from 'src/components/inputs/PartnerArrayInput'
import { useRecordContext } from 'ra-core'
import { useNavigate } from 'react-router-dom'
import { USER_DATA_STORAGE_KEY } from 'src/common/providers/AuthProvider'

const InfoTab = (props: any) => {
  const form = useFormContext()

  const acceptedRolesForSelectingEntities = useMemo(() => [AdminRole.PartnerAdmin], [])

  const handleRoleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const role = e.target.value as AdminRole

    if (![AdminRole.PartnerUnitAdmin, AdminRole.PartnerBrandAdmin].includes(role)) {
      form.setValue('unitsIds', [])
      form.setValue('restaurantsIds', [])
    }
    if (
      ![
        AdminRole.AdminPartner,
        AdminRole.SupportPartner,
        AdminRole.PartnerAdmin,
        AdminRole.PartnerUnitAdmin,
        AdminRole.PartnerBrandAdmin,
      ].includes(role)
    ) {
      form.setValue('partnerId', null)
    }
  }

  const handlePartnerChange: ChangeEventHandler<HTMLInputElement> = () => {
    form.setValue('unitsIds', [])
    form.setValue('restaurantsIds', [])
  }

  return (
    <FormTab label="Информация" path={'benefit'} {...props}>
      <SelectInput
        source="role"
        label={'Роль'}
        choices={AdminRoleList}
        fullWidth
        variant={'outlined'}
        onChange={(e) => handleRoleChange(e)}
        validate={required()}
      />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          [
            AdminRole.AdminPartner,
            AdminRole.SupportPartner,
            AdminRole.PartnerAdmin,
            AdminRole.PartnerUnitAdmin,
            AdminRole.PartnerBrandAdmin,
          ].includes(formData.role) && <PartnerInput source={'partnerId'} fullWidth onChange={handlePartnerChange} />
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          acceptedRolesForSelectingEntities.includes(formData.role) && (
            <PartnerArrayInput source="partnersIds" label={'Партнеры'} fullWidth />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          [AdminRole.PartnerUnitAdmin, AdminRole.PartnerBrandAdmin].includes(formData.role) && (
            <UnitArrayInput
              source="unitsIds"
              label={'Заведения доставки'}
              fullWidth
              filter={{ partnerId: formData.partnerId }}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          acceptedRolesForSelectingEntities.includes(formData.role) && (
            <RestaurantAllArrayInput
              source="restaurantsIds"
              label={'Рестораны'}
              fullWidth
              filter={{ 'partnerId:$in': formData.partnersIds }}
            />
          )
        }
      </FormDataConsumer>

      <TextInput source="email" label={'Email'} fullWidth variant={'outlined'} validate={required()} />
      <TextInput source="name" label={'Имя'} fullWidth variant={'outlined'} />

      <TextInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth />
      <IfCanAccess aggregator action={'edit'}>
        <TextInput
          source="settings.callerId"
          label={'Caller ID'}
          helperText={'ID оператора в телефонии'}
          variant={'outlined'}
          fullWidth
        />
      </IfCanAccess>
    </FormTab>
  )
}

const AdminForm = (props: any) => {
  const { identity, refetch } = useGetIdentity()
  const record = useRecordContext()
  const [create] = useCreate()
  const [update] = useUpdate()
  const navigate = useNavigate()

  const postSave = async (data: FieldValues) => {
    if (props.isCreation) {
      await create('admin', { data })
    } else {
      await update('admin', { id: record.id, previousData: record, data })
      //
      if (record.id === identity?.id) {
        localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify({ id: record.id, name: data?.name || record.name }))

        if (refetch) {
          await refetch()
        }
      }
    }
    navigate(-1)
  }

  return (
    <TabbedForm {...props} redirect={'list'} onSubmit={postSave}>
      <InfoTab />
    </TabbedForm>
  )
}

export default AdminForm
