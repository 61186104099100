import ListIcon from '@mui/icons-material/List'
import PromoRestaurantList from 'src/resources/promo-restaurant/PromoRestaurantList'
import PromoRestaurantEdit from 'src/resources/promo-restaurant/PromoRestaurantEdit'
import PromoRestaurantCreate from 'src/resources/promo-restaurant/PromoRestaurantCreate'

export default {
  create: PromoRestaurantCreate,
  edit: PromoRestaurantEdit,
  list: PromoRestaurantList,
  icon: ListIcon,
}
