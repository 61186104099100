import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps, useResourceContext } from 'react-admin'
import PromoRestaurantForm from 'src/resources/promo-restaurant/PromoRestaurantForm'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => {
  const resource = useResourceContext()
  return <span>{ServiceTypeDictionary.getPromoRestaurant(resource)?.create}</span>
}
const PromoRestaurantCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <PromoRestaurantForm isCreation />
    </Create>
  )
}

export default PromoRestaurantCreate
