export enum NotificationAttemptStatus {
  Created = 'created',
  ToSent = 'toSent',
  InProgress = 'inProgress',
  Completed = 'completed',
  Delivered = 'delivered',
  Opened = 'opened',
  Error = 'error',
}

export const NotificationAttemptStatusList = [
  { id: NotificationAttemptStatus.Created, name: 'Создан' },
  { id: NotificationAttemptStatus.ToSent, name: 'К отправке' },
  { id: NotificationAttemptStatus.InProgress, name: 'Отправляется' },
  { id: NotificationAttemptStatus.Completed, name: 'Отправлен' },
  { id: NotificationAttemptStatus.Delivered, name: 'Доставлен' },
  { id: NotificationAttemptStatus.Opened, name: 'Открыт' },
  { id: NotificationAttemptStatus.Error, name: 'Ошибка' },
]
