import React, { ComponentProps, ReactElement } from 'react'
import { useInput } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'
import { useGetList } from 'react-admin'
import { IPromo } from 'src/types/interfaces/IPromo'
import PromoCardWithDetails from 'src/components/Promo/PromoCardWithDetails'
import styles from './index.module.scss'
import { ServiceType } from 'src/types/enum/ServiceType'

export interface Props {
  label?: string | ReactElement
  source: string
  fullWidth?: boolean

  helperText?: ComponentProps<typeof InputHelperText>['helperText']
  record?: Record<any, any>
  resource?: string
  variant?: string
  onChange?: (val: any) => void
  [key: string]: any
  serviceType?: ServiceType
}

const PromoServiceRestaurantsSelectPageInput = (props: Props) => {
  const { fullWidth = true, helperText, label, source, resource, variant, ...rest } = props

  const {
    field: { value, onChange },
  } = useInput({ source, ...rest })
  const {
    data,
    isLoading,
    error: errorList,
    ...rests
  } = useGetList(`promo-service-${props.serviceType}-public`, {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'sort', order: 'ASC' },
  })
  const handleChange = (promo: IPromo) => {
    onChange(promo.id)
    if (props.onChange) {
      props.onChange(promo)
    }
  }
  if (!data && isLoading) {
    return <div>Загрузка...</div>
  }

  return (
    <div className={styles.root}>
      <div>Выберите скидку</div>
      {data?.map((i) => (
        <PromoCardWithDetails key={i.id} selected={value === i.id} promo={i} onClick={() => handleChange(i)} />
      ))}
    </div>
  )
}

export default PromoServiceRestaurantsSelectPageInput
