import { FileUploadAcceptType } from 'src/types'

export default class Converter {
  static getFileUploadAccept(type: FileUploadAcceptType): { [key: string]: string[] } {
    const images = { 'image/*': ['.jpeg', '.jpg', '.png'] }
    const videos = { 'video/mp4': ['.mp4'], 'video/mpeg': ['.mpeg'], 'video/x-msvideo': ['.avi'] }
    const scans = { 'application/pdf': ['.pdf'] }
    const pdf = { 'application/pdf': ['.pdf'] }
    const docs = {
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
      'application/rtf': ['.rtf'],
    }

    const archives = {
      'application/vnd.rar': ['.rar'],
      'application/x-7z-compressed': ['.7z'],
      'application/zip': ['.zip'],
    }
    switch (type) {
      case FileUploadAcceptType.Image:
        return images
      case FileUploadAcceptType.Scan:
        return { ...images, ...scans }
      case FileUploadAcceptType.Document:
        return { ...scans, ...docs }
      case FileUploadAcceptType.Media:
        return { ...images, ...videos }
      case FileUploadAcceptType.Archives:
        return archives
      case FileUploadAcceptType.Pdf:
        return pdf
      default:
        return {}
    }
  }

  static convertTimeToTimeZone(now: Date, utcOffset: number): Date {
    if (utcOffset !== -now.getTimezoneOffset()) {
      return new Date(now.getTime() + (utcOffset + now.getTimezoneOffset()) * 60000)
    }
    return now
  }
}
