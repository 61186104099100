import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import { CustomEdit } from 'src/components/CustomEdit'
import PromoCodeSubscriptionForm from 'src/resources/promo-code-subscription/PromoCodeSubscriptionForm'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Промокод доставки {record.code}</span> : null)

const PromoCodeSubscriptionEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <PromoCodeSubscriptionForm />
    </CustomEdit>
  )
}
export default PromoCodeSubscriptionEdit
