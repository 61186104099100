import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps, useResourceContext } from 'react-admin'
import ArticleCategoryForm from 'src/resources/article-category/ArticleCategoryForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => {
  const resource = useResourceContext()
  switch (resource) {
    case 'article-category-news':
      return <span>Новая Категория статей</span>
    case 'article-category-knowledge':
      return <span>Новая Категория Базы знаний</span>
    case 'course-category':
      return <span>Новая Категория курсов</span>
  }
  return <span>Новая Категория статей</span>
}

const ArticleCategoryCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <ArticleCategoryForm />
    </Create>
  )
}

export default ArticleCategoryCreate
