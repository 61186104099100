import React, { MouseEventHandler } from 'react'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import { useInput } from 'ra-core'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styles from './index.module.scss'
import { IFocalPoint } from 'src/components/types'
import { CommonInputProps } from 'react-admin'

export interface Props extends CommonInputProps {
  onSetup: (value: IFocalPoint, onChange: (value: IFocalPoint | null) => void) => void
}

export const FocalPointInput = (props: Props) => {
  const { parse, resource, source, validate, onSetup, ...rest } = props

  const { field } = useInput({
    source,
    validate,
    ...rest,
  })
  const value = field.value

  const handleSetup: MouseEventHandler = (e) => {
    props.onSetup(value, field.onChange)
  }
  const handleDelete = () => {
    field.onChange(null)
  }

  return (
    <div className={styles.root}>
      <Typography color={'textSecondary'} variant={'body2'}>
        {props.label}
      </Typography>
      <Box display={'flex'} p={0} alignItems={'center'}>
        <ControlCameraIcon fontSize={'small'} />
        <Typography color={'textPrimary'} variant={'body2'}>
          {value ? `(${value.x}, ${value.y})` : 'Не установлено'}
        </Typography>
      </Box>
      <Box display={'flex'} p={0} alignItems={'center'} mt={1}>
        <Button variant="outlined" size="small" color="primary" onClick={handleSetup}>
          Установить Центр
        </Button>
        {value && (
          <Button variant="outlined" size="small" color="secondary" onClick={handleDelete}>
            Удалить
          </Button>
        )}
      </Box>
    </div>
  )
}

FocalPointInput.defaultProps = {}
