import { required, SelectInput } from 'react-admin'
import * as React from 'react'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
  validate?: any
  helperText?: string | false
}

const LanguageInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      source={props.source ?? 'languageCode'}
      label={'Язык'}
      variant={'outlined'}
      choices={[
        { id: 'ru', name: 'RU' },
        { id: 'en', name: 'EN' },
      ]}
      validate={props.validate ?? required()}
      helperText={props.helperText}
    />
  )
}

export default LanguageInput
