import ListIcon from '@mui/icons-material/List'
import ReviewList from 'src/resources/review/ReviewList'
import ReviewEdit from 'src/resources/review/ReviewEdit'
import ReviewCreate from 'src/resources/review/ReviewCreate'

export default {
  create: ReviewCreate,
  edit: ReviewEdit,
  list: ReviewList,
  icon: ListIcon,
}
