import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CreateButton } from 'react-admin'

const RelatedEmptyList = ({
  basePath,
  title = 'List empty',
  record,
  data,
  ids,
  description,
  buttonText,
  createButton,
  to,
  hasCreate = true,
}: any) => (
  <Box textAlign="center" m={4}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">{description}</Typography>
    {hasCreate && !createButton && <CreateButton label={buttonText} resource={basePath} to={to} />}
    {hasCreate &&
      createButton &&
      React.cloneElement(createButton, {
        record,
        data,
        ids,
      })}
  </Box>
)
export default RelatedEmptyList
