import ListIcon from '@mui/icons-material/List'
import CountryList from 'src/resources/country/CountryList'
import CountryEdit from 'src/resources/country/CountryEdit'
import CountryCreate from 'src/resources/country/CountryCreate'

export default {
  create: CountryCreate,
  edit: CountryEdit,
  list: CountryList,
  icon: ListIcon,
}
