export enum ProductDeliveryType {
  All = 'all',
  Delivery = 'delivery',
  TakeAway = 'takeAway',
}

export const ProductDeliveryTypeList = [
  { id: ProductDeliveryType.All, name: 'Все' },
  { id: ProductDeliveryType.Delivery, name: 'Только доставка' },
  { id: ProductDeliveryType.TakeAway, name: 'Только самовывоз' },
]
