import ListIcon from '@mui/icons-material/List'
import RestaurantList from 'src/resources/restaurant/RestaurantList'
import RestaurantEdit from 'src/resources/restaurant/RestaurantEdit'
import RestaurantCreate from 'src/resources/restaurant/RestaurantCreate'

export default {
  create: RestaurantCreate,
  edit: RestaurantEdit,
  list: RestaurantList,
  icon: ListIcon,
}
