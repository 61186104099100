import * as React from 'react'
import {
  DateTimeInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  RecordContextProvider,
  required,
  useDataProvider,
  useRefresh,
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import Formatter from 'src/utils/formatters'
import { differenceInSeconds } from 'date-fns'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { CircularProgress } from '@mui/material'
import { ChangeEventHandler, useState } from 'react'
import Spacer from 'src/components/Spacer'
import { UnitStatus } from 'src/types/enum/UnitStatus'
import { Lock, LockOpen } from 'mdi-material-ui'
interface FormProps {
  record: any
  refresh: () => void
  onClose?: () => void
}
const Form = (props: FormProps) => {
  const { record, refresh, onClose } = props
  const form = useFormContext()
  const dataProvider = useDataProvider()
  const [isLoading, setIsLoading] = useState(false)
  const handleCancel = async () => {
    setIsLoading(true)
    const res = await dataProvider.update('unit', { id: record.id, data: { setBlocked: false }, previousData: record })
    setIsLoading(false)
    refresh()
    if (onClose) {
      onClose()
    }
  }
  const handleChangeBlockType: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.value === 'forever') {
      form.setValue('blockedUntil', null)
    }
  }
  return (
    <>
      {(record.status === UnitStatus.Blocked ||
        (record.blockedUntil && differenceInSeconds(new Date(record.blockedUntil), new Date()) > 0)) && (
        <>
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={handleCancel}
                startIcon={isLoading ? <CircularProgress size={18} thickness={2} /> : <LockOpen />}
              >
                Разблокировать
              </Button>
            }
          >
            Сейчас заблокировано{record.blockedUntil ? ` до ${Formatter.formatDateUntil(record.blockedUntil)}` : ''}
          </Alert>
          <Spacer />
        </>
      )}
      <RadioButtonGroupInput
        source="blockedType"
        label={'Заблокировать на'}
        onChange={handleChangeBlockType}
        validate={[required()]}
        choices={[
          { id: 'time', name: 'На время' },
          { id: 'forever', name: 'Навсегда' },
        ]}
        fullWidth
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.blockedType == 'time' && (
            <DateTimeInput
              label="Заблокировать до"
              source="blockedUntil"
              variant={'outlined'}
              fullWidth={true}
              validate={[required()]}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.blockedUntil && (
            <Typography variant="body2" align={'center'}>
              Ресторан будет заблокирован до {Formatter.formatDateUntil(formData.blockedUntil)}
            </Typography>
          )
        }
      </FormDataConsumer>
    </>
  )
}
interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}
export const UnitBlockedModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update('unit', {
      id: record.id,
      data: { ...data, setBlocked: true },
      previousData: record,
    })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        fullWidth={true}
        maxWidth={'xs'}
        resource={'area'}
        title={'Заблокировать'}
        onClose={props.onClose}
        saveButtonLabel={'Заблокировать'}
        saveButtonIcon={<Lock />}
        save={handleSubmit}
      >
        <Form record={record} refresh={refresh} onClose={props.onClose} />
      </ModalForm>
    </RecordContextProvider>
  )
}
