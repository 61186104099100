import * as React from 'react'
import { useMemo } from 'react'
import * as _ from 'lodash'
import get from 'lodash/get'
import { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import styles from './index.module.scss'
import { format } from 'date-fns'
import { IAsset } from 'src/components/types'
import { getMediaPath } from 'src/utils/media'
import { RecordChangeSourceList, RecordFieldsMap } from 'src/types/enum/RecordChange'

export type AdditionalMap = {
  key: string
  label: string
  choices?: { id: string; name: string }[]
  valueBuilder?: (value: any) => string
  isDate?: boolean
}

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {
  statusList?: { id: string; name: string }[]
  additionalMap?: AdditionalMap[]
}

const assetsFields = ['assetsAdded', 'assetsRemoved', 'photosAdded', 'photosRemoved']

export const RecordChangedDataField = (props: Props) => {
  const { source } = props
  const record = useRecordContext(props)

  const recordFieldsMap = useMemo(
    () =>
      _.assign(
        _.cloneDeep(RecordFieldsMap),
        props.additionalMap?.reduce((acc, el) => ({ ...acc, [el.key]: el.label }), {}) ?? {},
      ),
    [props.additionalMap],
  )

  const value = useMemo(() => {
    const value = get(record, source ?? '')
    //
    return Array.isArray(value) ? value.reduce((acc, value) => ({ ...acc, ...value }), {}) : { ...value }
  }, [])

  const list = useMemo((): Array<{ label: string; value: string; key: string; isAsset: boolean }> => {
    if (Array.isArray(value)) {
      return []
    }
    const keys = Object.keys(value)
    const fieldsMap = recordFieldsMap as any
    //
    return _.filter(
      keys.map((i) => {
        if (_.isNil(value[i])) {
          return null
        }
        if (['location'].includes(i)) {
          return null
        }
        return {
          label: fieldsMap[i as any],
          value: value[i],
          key: i,
          isAsset: assetsFields.includes(i),
        }
      }),
    ) as unknown as Array<{ label: string; value: string; key: string; isAsset: boolean }>
  }, [value])

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'admin':
      case 'user':
        return value?.name
      case 'unit':
        return value?.brand?.name ? `${value?.brand?.name} - ${value?.name}` : value?.name
      case 'order':
        return value?.number
      case 'status':
      case 'prevStatus':
        return props.statusList?.find((i) => i.id === value)?.name ?? ''
      case 'source':
        return RecordChangeSourceList.find((i) => i.id === value)?.name
      case 'created':
        return 'Создан'
      case 'createdAt':
      case 'updatedAt':
        if (!value) {
          return ''
        }
        return format(new Date(value), 'dd.MM.y HH:mm xxx')
      default:
        if (props.additionalMap) {
          const mapper = props.additionalMap.find((el) => el.key === key)

          if (mapper) {
            if (mapper.choices) {
              return mapper.choices.find((i) => i.id === value)?.name ?? ''
            }
            if (mapper.valueBuilder) {
              const formattedValue = mapper.valueBuilder(value)

              if (Array.isArray(formattedValue) || _.isObject(formattedValue) || _.isNil(formattedValue)) {
                return 'Некоректное(неотформатированное) значение'
              }
              return formattedValue
            }
            if (mapper.isDate && _.isDate(new Date(value))) {
              return format(new Date(value), 'dd.MM.y HH:mm xxx')
            }
          }
        }
        if (_.isPlainObject(value) && 'id' in value && 'name' in value) {
          return value.name
        }
        if (_.isBoolean(value)) {
          return value ? 'Да' : 'Нет'
        }
        return value ? value : ''
    }
  }

  const formatAndValidateValue = (key: string, value: any): string => {
    try {
      const formattedValue = formatValue(key, value)
      //
      if (!_.isString(formattedValue)) {
        return 'Некоректное(неотформатированное) значение'
      }
      return formattedValue
    } catch (err) {
      console.error(`При неотформатированное ${key} произошла ошибка:`, err)
      //
      return 'Ошибка форматирования'
    }
  }

  const buildAssets = (assets: IAsset[]) => {
    return assets.map((asset) => {
      return (
        <img
          className={styles.image}
          src={`${getMediaPath(asset.source)}?preset=small&fpx=${asset.focalPoint?.x || '0.5'}&fpy=${asset.focalPoint?.y || '0.5'}`}
          alt=""
        />
      )
    })
  }

  return (
    <div className={styles.root}>
      {list
        .filter((i) => !Array.isArray(i.label))
        .map((field, index) => (
          <div key={index}>
            <Typography component="span" variant="body2" whiteSpace={'pre-wrap'}>
              {`${field.label ?? ''}: `}
              {field.isAsset && buildAssets(field.value as unknown as IAsset[])}
              {!field.isAsset && (formatAndValidateValue(field.key, field.value) ?? '')}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default RecordChangedDataField
