import { AutocompleteArrayInput, ReferenceArrayInput, FilterPayload } from 'react-admin'
import * as React from 'react'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
  filter?: FilterPayload
}

const CategoriesArrayInput = (props: Props) => {
  return (
    <ReferenceArrayInput
      allowEmpty={false}
      source={props.source}
      reference="category"
      variant={'outlined'}
      fullWidth={true}
      perPage={500}
      sort={{ field: 'name', order: 'ASC' }}
      filter={props.filter}
    >
      <AutocompleteArrayInput
        label={props.label}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        source="id"
        optionText={(i) => (i ? `${i?.name}` : '')}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        disableCloseOnSelect={true}
      />
    </ReferenceArrayInput>
  )
}

export default CategoriesArrayInput
