export enum NotificationType {
  preOrderCreated = 'preOrderCreated',
  orderCreated = 'orderCreated',
  orderCancelled = 'orderCancelled',
  orderPreparing = 'orderPreparing',
  orderDelivering = 'orderDelivering',
  orderDelivered = 'orderDelivered',
  orderFeedbackRemind = 'orderFeedbackRemind',
  authPinCode = 'authPinCode',
  promoCode = 'promoCode',
}

export const NotificationTypeList = [
  { id: NotificationType.preOrderCreated, name: 'Предзаказа создан' },
  { id: NotificationType.orderCreated, name: 'Заказ создан' },
  { id: NotificationType.orderCancelled, name: 'Заказ отменен' },
  { id: NotificationType.orderPreparing, name: 'Заказ готовится' },
  { id: NotificationType.orderDelivering, name: 'Заказ доставляется' },
  { id: NotificationType.orderDelivered, name: 'Заказ доставлен' },
  { id: NotificationType.orderFeedbackRemind, name: 'Заказ доставлен (отзыв)' },
  { id: NotificationType.authPinCode, name: 'СМС otp' },
  { id: NotificationType.promoCode, name: 'Прмокод' },
]
