export enum PromoGroup {
  PercentAll = 'percentAll',
  PercentCategory = 'percentCategory',
  MoneyGift = 'moneyGift',
}

export const PromoGroupList = [
  { id: PromoGroup.PercentAll, name: 'Процент на все' },
  { id: PromoGroup.PercentCategory, name: 'Процент на категорию' },
  { id: PromoGroup.MoneyGift, name: 'Сумма в подарок' },
]
