import * as React from 'react'
import { isValidElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import { canAccess, Permissions, useAuthenticated } from '@glm/ra-rbac'
import { ResourceProps, ResourceContextProvider, usePermissions } from 'react-admin'
/**
 * Override of react-admin's Resource component with RBAC built-in
 *
 * This component takes the same props as react-admin's Resource.
 *
 * Users must have the 'create' permission on the resource to see the create page.
 * Users must have the 'show' permission on the resource to see the show page.
 * Users must have the 'edit' permission on the resource to see the edit page.
 * Users must have the 'list' permission on the resource to see the list page.
 */
export const Resource = (props: ResourceProps) => {
  const { create: Create, edit: Edit, list: List, name, show: Show } = props
  const { isLoading: isAuthenticationLoading } = useAuthenticated()
  const { isLoading: isPermissionsLoading, permissions } = usePermissions()
  // if the authProvider hasn't returned, no need to render
  if (isAuthenticationLoading || isPermissionsLoading) {
    return null
  }
  return (
    <ResourceContextProvider value={name}>
      <Routes>
        {Create &&
          canAccess({
            permissions,
            action: 'create',
            resource: name,
            // @ts-ignore
          }) && <Route path="create/*" element={isValidElement(Create) ? Create : <Create />} />}
        {Show &&
          canAccess({
            permissions,
            action: 'show',
            resource: name,
            // @ts-ignore
          }) && <Route path=":id/show/*" element={isValidElement(Show) ? Show : <Show />} />}
        {Edit &&
          canAccess({
            permissions,
            action: 'edit',
            resource: name,
            // @ts-ignore
          }) && <Route path=":id/*" element={isValidElement(Edit) ? Edit : <Edit />} />}
        {List &&
          canAccess({
            permissions,
            action: 'list',
            resource: name,
            // @ts-ignore
          }) && <Route path="/*" element={isValidElement(List) ? List : <List />} />}
      </Routes>
    </ResourceContextProvider>
  )
}

Resource.raName = 'Resource'

Resource.registerResource = (
  { create, edit, icon, list, name, options, show, recordRepresentation }: ResourceProps,
  permissions: Permissions,
) => ({
  name,
  options,
  recordRepresentation,
  hasList:
    !!list &&
    canAccess({
      permissions,
      action: 'list',
      resource: name,
    }),
  hasCreate:
    !!create &&
    canAccess({
      permissions,
      action: 'create',
      resource: name,
    }),
  hasEdit:
    !!edit &&
    canAccess({
      permissions,
      action: 'edit',
      resource: name,
    }),
  hasShow:
    !!show &&
    canAccess({
      permissions,
      action: 'show',
      resource: name,
    }),
  icon,
})
