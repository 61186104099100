import ListIcon from '@mui/icons-material/List'
import UnitSelectionList from 'src/resources/unit-selection/UnitSelectionList'
import UnitSelectionEdit from 'src/resources/unit-selection/UnitSelectionEdit'
import UnitSelectionCreate from 'src/resources/unit-selection/UnitSelectionCreate'

export default {
  create: UnitSelectionCreate,
  edit: UnitSelectionEdit,
  list: UnitSelectionList,
  icon: ListIcon,
}
