import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import PartnerInput from 'src/components/inputs/PartnerInput'
import PriceField from 'src/components/fields/PriceField'
import OrderInput from 'src/components/inputs/OrderInput'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import OrderLinkField from 'src/components/fields/OrderLinkField'
import { Datagrid } from 'src/components/list/Datagrid'
const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Penalty',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <IfCanAccess source={'partnerId'} label={'Партнер'} alwaysOn aggregator action={'show'}>
        <PartnerInput source={'partnerId'} label={'Партнер'} alwaysOn />
      </IfCanAccess>
      <OrderInput source="orderId" label={'Заказ'} alwaysOn />
    </Filter>
  )
}

const PenaltyList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Штрафы"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <IfCanAccess aggregator action={'show'}>
          <PartnerLinkField source="partner.id" label={'Партнер'} />
        </IfCanAccess>
        <OrderLinkField label={'№ заказа'} />
        <TextField source={'reason'} label={'Причина'} />
        <PriceField source={'total'} label={'Сумма'} />
      </Datagrid>
    </List>
  )
}

export default PenaltyList
