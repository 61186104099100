import * as React from 'react'
import { FC, useRef } from 'react'
import { NumberInput, required, TextInput } from 'react-admin'
import Box from '@mui/material/Box'
import { IFocalPoint } from 'src/components/types'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { FileUploadAcceptType } from 'src/types'

type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
  record?: any
}
export const RestaurantMenuItemEditForm: FC<any> = (props) => {
  const { record } = props
  const currentEditRef = useRef()
  return (
    <Box>
      <TextInput source={'name'} label={'Название'} />
      <NumberInput source="order" label={'Сортировка'} fullWidth helperText={false} variant={'outlined'} />
      <MediaInput source={'file'} label={'Файл меню'} accept={FileUploadAcceptType.Scan} />
    </Box>
  )
}
const requiredValidate = [required()]
