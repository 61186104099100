export enum ComplaintResolveType {
  PromoCode = 'created',
  RefundOrder = 'refundOrder',
  RefundOrderItem = 'refundOrderItem',
  NoCompensation = 'noCompensation',
}

export const ComplaintResolveTypeList = [
  { id: ComplaintResolveType.PromoCode, name: 'Промокод' },
  { id: ComplaintResolveType.RefundOrder, name: 'Возврат ДС за заказ' },
  { id: ComplaintResolveType.RefundOrderItem, name: 'Возврат ДС за позицию' },
  { id: ComplaintResolveType.NoCompensation, name: 'Без компенсации' },
]
