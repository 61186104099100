import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, TextInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import PriceField from 'src/components/fields/PriceField'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import RegionArrayInput from 'src/components/inputs/RegionArrayInput'
import PartnerArrayInput from 'src/components/inputs/PartnerArrayInput'
import RestaurantArrayInput from 'src/components/inputs/RestaurantArrayInput'
import { Datagrid } from 'src/components/list/Datagrid'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'UnitLocalOrder',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" alwaysOn={true} />
      <TextInput
        source="id:$eq&user.phone:$contL&partnerNumber:$contL"
        label={'Поиск'}
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />
      <RegionArrayInput source={'restaurant.regionId:$in'} label={'Регионы'} />
      <PartnerArrayInput source={'partnerId:$in'} label={'Партнеры'} />
      <RestaurantArrayInput source={'restaurantId:$in'} label={'Заведения'} alwaysOn={true} />
    </Filter>
  )
}

const RestaurantOrderList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Заказы ресторанов"
      sort={{ field: 'id', order: 'DESC' }}
      hasCreate={false}
      empty={<EmptyList title={'Пока нет заказов'} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'partnerNumber'} label="№ заказа" />
        <PriceField source={'total'} label={'Сумма'} />
        <PartnerLinkField source={'partner.id'} label={'Партнер'} />
        <RestaurantLinkField source={'restaurant.id'} label={'Ресторан'} />

        <DateField source="createdAt" label={'Создан'} showTime={true} />
        <PhoneCallActionField source={'user.phone'} label={'Телефон'} />
      </Datagrid>
    </List>
  )
}

export default RestaurantOrderList
