export const AdminRoles = [
  { id: 'admin', name: 'Администратор' },
  { id: 'restaurantManager', name: 'Ресторан менеджер' },
  { id: 'manager', name: 'Контент менеджер' },
]
export interface IUser {
  id: number
  name: string
}

export enum CountryCode {
  Ru = 'ru',
}

export enum FileUploadAcceptType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Scan = 'scan',
  Media = 'media',
  Archives = 'archives',
  Pdf = 'pdf',
}
