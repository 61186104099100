export enum NotificationChannel {
  Push = 'push',
  Alert = 'alert',
  Email = 'email',
  Sms = 'sms',
}

export const NotificationChannelList = [
  { id: NotificationChannel.Push, name: 'Push' },
  { id: NotificationChannel.Alert, name: 'Алерт' },
  { id: NotificationChannel.Email, name: 'Email' },
  { id: NotificationChannel.Sms, name: 'Sms' },
]
