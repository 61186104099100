import React, { FC, ReactElement, useState, MouseEventHandler } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'

import MenuItem from '@mui/material/MenuItem'

interface Props {
  children?: ReactElement | string
  label?: string
  type?: 'iconButton' | 'button'
  icon?: ReactElement
  modal?: ReactElement
  onClose?: () => void
}

export const MenuModalButton: FC<Props> = (props) => {
  const { label, icon, children, modal, type = 'button' } = props
  const [showDialog, setShowDialog] = useState(false)

  const handleClick: MouseEventHandler = (e) => {
    setShowDialog(true)
    e.stopPropagation()
    e.preventDefault()
    if (props.onClose) {
      props.onClose()
    }
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  return (
    <>
      <MenuItem onClick={handleClick}>
        {icon ?? ''}
        {children ?? ''}
      </MenuItem>
      {showDialog &&
        modal &&
        React.cloneElement(modal, {
          ...props,
          onClose: handleCloseClick,
          isShown: showDialog,
        })}
    </>
  )
}

export const MenuModalEditButton = (props: Props) => (
  <MenuModalButton label={'ra.action.edit'} icon={<CreateIcon />} {...props} />
)
export const MenuModalCreateButton = (props: Props) => (
  <MenuModalButton label={'ra.action.create'} icon={<AddIcon />} {...props} />
)
