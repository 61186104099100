import { OrderStatus } from 'src/types/enum/OrderStatus'

export class OrderUtils {
  static getColor(status: OrderStatus): string {
    const colorsStatus = {
      red: '#E80012',
      red2: '#F83409',
      orange: '#FCC067',
      green1: '#9CCB3B',
      green2: '#6BD79D',
      green3: '#02AD84',
      blue1: '#0047FF',
      blue2: '#1867C0',
      blue3: '#258CFF',
      blue4: '#81B6F2',
      blue5: '#BBDBFF',
      gray: '#BDBDBD',
    }
    const colors = {
      [OrderStatus.Arrived]: colorsStatus.green2,
      [OrderStatus.Assigned]: colorsStatus.blue4,
      [OrderStatus.Canceled]: colorsStatus.gray,
      [OrderStatus.Confirmed]: colorsStatus.blue4,
      [OrderStatus.Delivered]: colorsStatus.green3,
      [OrderStatus.Delivering]: colorsStatus.green1,
      [OrderStatus.OnHold]: colorsStatus.red2,
      [OrderStatus.Paused]: colorsStatus.red,
      [OrderStatus.Preparing]: colorsStatus.blue3,
      [OrderStatus.Ready]: colorsStatus.blue1,
      [OrderStatus.Received]: colorsStatus.orange,
      [OrderStatus.Returned]: colorsStatus.gray,
      [OrderStatus.Sending]: colorsStatus.blue5,
      [OrderStatus.Sent]: colorsStatus.blue2,
      [OrderStatus.Waiting]: colorsStatus.gray,
    }
    return colors[status]
  }
}
