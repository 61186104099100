import * as React from 'react'
import { FC, useRef, useState } from 'react'
import styles from './index.module.scss'
import { required, SelectInput, TextInput } from 'react-admin'
import Grid from '@mui/material/Grid'
import CancelIcon from '@mui/icons-material/Cancel'
import FocalPoint from 'src/components/fields/FocalPoint'
import { FocalPointInput } from 'src/components/inputs/FocalPointInput'
import { getMediaPath } from 'src/utils/media'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { IFocalPoint } from 'src/components/types'
type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
  record?: any
}

const ProductTitle: FC<ProductTitleProps> = ({ record }) => <span>Файл {record.name}</span>

export const AssetEditForm: FC<any> = (props) => {
  const { record } = props
  const [preview, setPreview] = useState('medium')
  const imageRef = useRef<HTMLImageElement | null>(null)
  const newPointRef = useRef<IFocalPoint | null>(null)
  const updateFocalPointCb = useRef<UpdateCallback | null>(null)
  const [setupFocalPoint, setSetupFocalPoint] = useState<boolean>(false)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [newFocalPoint, setNewFocalPoint] = useState<IFocalPoint | null>(null)
  const handleChangePreview = (e: any) => {
    setPreview(e.target.value)
  }
  const handleChangeFocalPoint = (x: number, y: number) => {
    newPointRef.current = { x: x / 100, y: y / 100 }
  }
  const handleCancelSetFocalPoint = () => {
    setSetupFocalPoint(false)
  }
  const handleSetFocalPoint = () => {
    if (updateFocalPointCb.current) {
      updateFocalPointCb.current(newPointRef.current)
    }
    setSetupFocalPoint(false)
    setNewFocalPoint(newPointRef.current)
  }
  const handleEnableSetupFocalPoint = async (value: IFocalPoint, onChange: UpdateCallback) => {
    if (preview !== 'medium') {
      setPreview('medium')
    }
    setNewFocalPoint(value)
    updateFocalPointCb.current = onChange

    setTimeout(() => {
      setImageWidth(imageRef.current?.width ?? 0)
      setImageHeight(imageRef.current?.height ?? 0)
      setSetupFocalPoint(true)
    }, 400)
  }
  return (
    <Grid container>
      <Grid item xs={8}>
        <div className={styles.imageView}>
          <div className={styles.imageWrapper}>
            <div className={styles.image}>
              <img
                ref={imageRef}
                src={`${getMediaPath(record.source)}?preset=${preview}&fpx=${newFocalPoint?.x || record.focalPoint?.x || '0.5'}&fpy=${newFocalPoint?.y || record.focalPoint?.y || '0.5'}`}
                alt=""
              />
              {setupFocalPoint && (
                <FocalPoint
                  width={imageWidth}
                  height={imageHeight}
                  x={newFocalPoint?.x}
                  y={newFocalPoint?.y}
                  onChange={handleChangeFocalPoint}
                />
              )}
            </div>
            {setupFocalPoint && (
              <div className={styles.imageToolbar}>
                <IconButton aria-label="delete" onClick={handleCancelSetFocalPoint}>
                  <CancelIcon />
                </IconButton>
                <Button variant="contained" color="primary" onClick={handleSetFocalPoint}>
                  Установить
                </Button>
              </div>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Box pl={3}>
          <TextInput
            source="name"
            label={'Название'}
            fullWidth
            helperText={false}
            variant={'outlined'}
            validate={requiredValidate}
          />
          <Box mt={0}>
            <Typography color={'textSecondary'} variant={'body2'}>
              Исходный файл:
            </Typography>
            <Link to={getMediaPath(record.source)} target={'_blank'}>
              Скачать файл
            </Link>
          </Box>

          <Box mt={2}>
            <FocalPointInput source={'focalPoint'} label={'Координаты центра:'} onSetup={handleEnableSetupFocalPoint} />
          </Box>
          <Box mt={2}>
            <TextInput source="authorName" label={'Автор'} fullWidth helperText={false} variant={'outlined'} />

            <SelectInput
              source="preview"
              fullWidth
              defaultValue={preview}
              label={'Размер'}
              helperText={'Для отображения слева'}
              onChange={handleChangePreview}
              variant={'outlined'}
              choices={[
                { id: 'tiny', name: 'Маленькое превью' },
                { id: 'thumb', name: 'Превью' },
                { id: 'small', name: 'Меленький' },
                { id: 'medium', name: 'Средний' },
                { id: 'large', name: 'Большой' },
                { id: 'fullSize', name: 'Оригинал' },
              ]}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
const requiredValidate = [required()]
