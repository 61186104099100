import ListIcon from '@mui/icons-material/List'
import UnitSelectionCategoryList from 'src/resources/unit-selection-category/UnitSelectionCategoryList'
import UnitSelectionCategoryEdit from 'src/resources/unit-selection-category/UnitSelectionCategoryEdit'
import UnitSelectionCategoryCreate from 'src/resources/unit-selection-category/UnitSelectionCategoryCreate'

export default {
  create: UnitSelectionCategoryCreate,
  edit: UnitSelectionCategoryEdit,
  list: UnitSelectionCategoryList,
  icon: ListIcon,
}
