import * as React from 'react'
import { TextInput, required, SimpleForm, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import FormGroup from 'src/components/FormGroup'
import Formatter from 'src/utils/formatters'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (
    <>
      <TextInput source="name" label={'Название'} validate={[required()]} fullWidth onChange={handleNameChange} />
      <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
      <FormGroup title={'Товарные разделы'}>
        <ReferenceArrayInput
          filterToQuery={(searchText: string) => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
          allowEmpty={false}
          source="categoriesIds"
          reference="category"
          variant={'outlined'}
          fullWidth={true}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput source="id" label={'Выбранные разделы'} optionText={(i) => (i ? `${i?.name}` : '')} />
        </ReferenceArrayInput>
      </FormGroup>
    </>
  )
}
const ProductCategoryForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <InfoTab />
    </SimpleForm>
  )
}
export default ProductCategoryForm
