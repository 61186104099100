import ListIcon from '@mui/icons-material/List'
import PromoCodeList from 'src/resources/promo-code-subscription/PromoCodeSubscriptionList'
import PromoCodeEdit from 'src/resources/promo-code-subscription/PromoCodeSubscriptionEdit'
import PromoCodeCreate from 'src/resources/promo-code-subscription/PromoCodeSubscriptonCreate'

export default {
  create: PromoCodeCreate,
  edit: PromoCodeEdit,
  list: PromoCodeList,
  icon: ListIcon,
}
