import * as React from 'react'
import { useMemo } from 'react'
import get from 'lodash/get'
import { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import styles from './index.module.scss'
import { PartnerCommissionTypeList } from 'src/types/enum/PartnerCommissionType'

const FieldsMap = {
  minAmount: 'От',
  maxAmount: 'До',
  commissionType: 'Тип комиссии',
  commission: 'Комиссия',
  serviceFee: 'Сервисный сбор',
} as const

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {}

export const PartnerTariffDescriptionField = (props: Props) => {
  const { source } = props
  const record = useRecordContext(props)

  const descriptions = useMemo(() => {
    return get(record, source ?? '') as Array<{ [Key in keyof typeof FieldsMap]: number | string }>
  }, [record, source])

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'commissionType':
        return PartnerCommissionTypeList.find((el) => el.id === value)?.name ?? ''
      default:
        return value
    }
  }

  return (
    <div className={styles.root}>
      {descriptions.map((description, index) => {
        const descriptionKeys = Object.keys(description)

        return (
          <div key={index}>
            <Typography component="span" variant="body2" whiteSpace={'pre-wrap'}>
              {descriptionKeys.map((key, descriptionKeysIndex) => {
                return (
                  ((FieldsMap as any)[key] ?? '') +
                  ': ' +
                  (formatValue(key, (description as any)[key]) ?? '') +
                  (descriptionKeysIndex === descriptionKeys.length - 1 ? '.' : ', ')
                )
              })}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export default PartnerTariffDescriptionField
