import * as React from 'react'

import { BooleanField, FieldProps, RaRecord } from 'react-admin'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const YesOrNoField = (props: Props) => {
  return <BooleanField valueLabelTrue="Да" valueLabelFalse="Нет" {...props} />
}

export default YesOrNoField
