import { SelectInput } from 'react-admin'
import * as React from 'react'
import { useMemo } from 'react'

interface Props {
  source: string
  label?: string
  fullWidth?: boolean
  showEmptyOption?: boolean
  validate?: any
}
const TimeZoneSelectInput = (props: Props) => {
  const options = useMemo(
    () =>
      [
        { name: '+00:00', id: 0 },
        { name: '+03:00', id: 10800 },
        { name: '+01:00', id: 3600 },
        { name: '+02:00', id: 7200 },
        { name: '-10:00', id: -36000 },
        { name: '-09:00', id: -32400 },
        { name: '-04:00', id: -14400 },
        { name: '-03:00', id: -10800 },
        { name: '-05:00', id: -18000 },
        { name: '-06:00', id: -21600 },
        { name: '-07:00', id: -25200 },
        { name: '-08:00', id: -28800 },
        { name: '-02:00', id: -7200 },
        { name: '-01:00', id: -3600 },
        { name: '-03:30', id: -12600 },
        { name: '+11:00', id: 39600 },
        { name: '+07:00', id: 25200 },
        { name: '+10:00', id: 36000 },
        { name: '+05:00', id: 18000 },
        { name: '+13:00', id: 46800 },
        { name: '+06:00', id: 21600 },
        { name: '+12:00', id: 43200 },
        { name: '+04:00', id: 14400 },
        { name: '+08:00', id: 28800 },
        { name: '+09:00', id: 32400 },
        { name: '+05:30', id: 19800 },
        { name: '+04:30', id: 16200 },
        { name: '+05:45', id: 20700 },
        { name: '+03:30', id: 12600 },
        { name: '+06:30', id: 23400 },
        { name: '+10:30', id: 37800 },
        { name: '+09:30', id: 34200 },
        { name: '+08:45', id: 31500 },
        { name: '+13:45', id: 49500 },
        { name: '+14:00', id: 50400 },
        { name: '-09:30', id: -34200 },
        { name: '-11:00', id: -39600 },
      ]
        .map((i) => ({ ...i, id: i.id / 60 }))
        .sort((a, b) => (a.id > b.id ? 1 : -1)),
    [],
  )
  return <SelectInput variant={'outlined'} choices={options} {...props} />
}

export default TimeZoneSelectInput
