import * as React from 'react'
import { isEmail, isEmail as isEmailFn } from 'src/components/inputs/MultiEmailInput/isEmail'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { FocusEvent, ReactNode, useState } from 'react'
import { InputHelperText } from 'ra-ui-materialui'

import { styled } from '@mui/material/styles'
import { FieldTitle, useInput } from 'ra-core'
import { CommonInputProps, RaRecord, TextFieldProps } from 'react-admin'

const initialEmailAddress = (emails?: string[]) => {
  if (typeof emails === 'undefined') return []

  const validEmails = emails.filter((email) => isEmail(email))
  return validEmails
}

export function MultiEmailInput(props: AutocompleteInputProps) {
  const {
    delimiter = '[ ,;]',
    validateEmail,
    debounce: debounceDelay = 250,
    defaultValue,
    emptyText,
    emptyValue = '',
    field: fieldOverride,
    format,
    helperText,
    id: idOverride,
    inputText,
    isRequired: isRequiredOverride,
    label,
    margin,
    fieldState: fieldStateOverride,
    formState: formStateOverride,
    multiple = false,
    onBlur,
    parse,
    shouldRenderSuggestions,
    setFilter,
    size,
    source,
    TextFieldProps,
    validate,
    variant,
    enable,
    ...rest
  } = props

  const [filterValue, setFilterValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [emails, setEmails] = useState<string[]>(() => initialEmailAddress([]))
  const {
    id,
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    id: idOverride,
    field: fieldOverride,
    fieldState: fieldStateOverride,
    formState: formStateOverride,
    isRequired: isRequiredOverride,
    onBlur,
    parse,
    format,
    source,
    validate,
    ...rest,
  })
  const [inputValue, setInputValue] = React.useState(field.value)
  const [spinning, setSpinning] = React.useState(false)

  const findEmailAddress = React.useCallback(
    (value: string, isEnter?: boolean) => {
      const validEmails: string[] = []
      let inputValue = ''
      const re = new RegExp(delimiter, 'g')
      const isEmail = validateEmail || isEmailFn

      const addEmails = (email: string) => {
        for (let i = 0, l = emails.length; i < l; i++) {
          if (emails[i] === email) {
            return false
          }
        }
        validEmails.push(email)
        return true
      }

      if (value !== '') {
        if (re.test(value)) {
          const splitData = value.split(re).filter((n) => {
            return n !== '' && n !== undefined && n !== null
          })

          // @ts-ignore
          const arr = [...new Set(splitData)]

          do {
            const validateResult = isEmail('' + arr[0])

            if (typeof validateResult === 'boolean') {
              if (validateResult) {
                addEmails('' + arr.shift())
              } else {
                if (arr.length === 1) {
                  inputValue = '' + arr.shift()
                } else {
                  arr.shift()
                }
              }
            } else {
              // handle promise
              setSpinning(true)

              if (validateEmail?.(value) === true) {
                addEmails('' + arr.shift())
                setSpinning(false)
              } else {
                if (arr.length === 1) {
                  inputValue = '' + arr.shift()
                } else {
                  arr.shift()
                }
              }
            }
          } while (arr.length)
        } else {
          if (enable && !enable({ emailCnt: emails.length })) {
            //   onDisabled?.()
            return
          }

          if (isEnter) {
            const validateResult = isEmail(value)
            if (typeof validateResult === 'boolean') {
              if (validateResult) {
                addEmails(value)
              } else {
                inputValue = value
              }
            } else {
              // handle promise
              setSpinning(true)
              if (validateEmail?.(value) === true) {
                addEmails(value)
                setSpinning(false)
              } else {
                inputValue = value
              }
            }
          } else {
            inputValue = value
          }
        }
      }

      setEmails([...emails, ...validEmails])
      setFilterValue(inputValue)
      if (validEmails.length) {
        field.onChange?.([...emails, ...validEmails])
      }

      if (inputValue !== inputValue) {
        //  onChangeInput?.(inputValue)
      }
    },
    [delimiter, emails, enable, field.onChange, validateEmail],
  )

  const onChangeInputValue = React.useCallback(
    (value: string) => {
      findEmailAddress(value)

      //   onChange?.(value)
    },
    [findEmailAddress, field.onChange],
  )

  const removeEmail = React.useCallback(
    (index: number, isDisabled?: boolean) => {
      if (isDisabled) {
        return
      }

      const _emails = [...emails.slice(0, index), ...emails.slice(index + 1)]
      setEmails(_emails)
      field.onChange?.(_emails)
    },
    [emails, field.onChange],
  )

  const handleOnKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'Enter':
          e.preventDefault()
          break
        case 'Backspace':
          if (!(e.currentTarget as any).value) {
            removeEmail(emails.length - 1, false)
          }
          break
        default:
      }
    },
    [emails.length, removeEmail],
  )

  const handleOnKeyup = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
          findEmailAddress((e.target as any).value, true)
          break
        default:
      }
    },
    [findEmailAddress],
  )

  const handleOnChange = React.useCallback(
    (e: React.SyntheticEvent, newValue: string, reason: 'input' | 'reset' | 'clear') => {
      if (e?.type === 'change') {
        onChangeInputValue(newValue)
      }
    },
    [onChangeInputValue],
  )

  const handleOnBlur = React.useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      findEmailAddress(e.target.value, true)
      e.preventDefault()
      e.stopPropagation()
      onBlur?.()
    },
    [findEmailAddress, onBlur],
  )

  const handleOnFocus = React.useCallback(() => {
    setFocused(true)
  }, [])
  const renderHelperText = helperText !== false || ((isTouched || isSubmitted) && invalid)
  const isOptionEqualToValue = (option: any, value: any) => {
    return String(option) === String(value)
  }
  return (
    <StyledAutocomplete
      value={field.value ?? []}
      inputValue={filterValue}
      open={false}
      multiple
      isOptionEqualToValue={isOptionEqualToValue}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onInputChange={handleOnChange}
      onKeyDown={handleOnKeydown}
      onKeyUp={handleOnKeyup}
      onChange={(e, val, eventName: string) => {
        if (eventName === 'removeOption') {
          setEmails(val as string[])
          field.onChange?.(val)
        }
      }}
      onClose={() => null}
      options={[]}
      fullWidth={props.fullWidth}
      renderInput={(params) => (
        <TextField
          name={field.name}
          label={<FieldTitle label={label} source={source} isRequired={isRequired} />}
          error={(isTouched || isSubmitted) && invalid}
          helperText={
            renderHelperText ? (
              <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
            ) : null
          }
          margin={margin as any}
          variant={variant as any}
          className={AutocompleteInputClasses.textField}
          {...(TextFieldProps as any)}
          {...params}
          size={size as any}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={`${option}`}
            sx={{
              '.MuiSvgIcon-root': {
                // FIXME: Workaround to allow choices deletion
                // Maybe related to storybook and mui using different versions of emotion
                zIndex: 100,
              },
            }}
            size="small"
            {...getTagProps({ index })}
            key={`${option}`}
          />
        ))
      }
    />
  )
}
const PREFIX = 'RaAutocompleteInput'

export const AutocompleteInputClasses = {
  textField: `${PREFIX}-textField`,
}
const StyledAutocomplete = styled(Autocomplete, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${AutocompleteInputClasses.textField}`]: {
    minWidth: theme.spacing(20),
  },
}))

export interface AutocompleteInputProps<
  OptionType extends any = RaRecord,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
> extends Omit<CommonInputProps, 'source'> {
  children?: ReactNode
  debounce?: number
  emptyText?: string
  emptyValue?: any
  filterToQuery?: (searchText: string) => any
  inputText?: (option: any) => string
  setFilter?: (value: string) => void
  shouldRenderSuggestions?: any
  // Source is optional as AutocompleteInput can be used inside a ReferenceInput that already defines the source
  source: string
  TextFieldProps?: TextFieldProps
  validateEmail?: (email: string) => boolean
  delimiter?: string
  enable?: ({ emailCnt }: { emailCnt: number }) => boolean
  multiple?: boolean
  size?: string
  fullWidth?: boolean
}
