import * as React from 'react'
import get from 'lodash/get'
import { FieldProps, RaRecord } from 'react-admin'
import { Typography } from '@mui/material'
import { useRecordContext } from 'ra-core'
import Formatter from 'src/utils/formatters'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const PriceField = (props: Props) => {
  const record = useRecordContext(props)
  const price = get(record, props.source ?? '')
  return (
    <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
      {typeof price === 'undefined' ? '' : Formatter.formatPrice(price)}
    </Typography>
  )
}

export default PriceField
