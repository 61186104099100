import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SearchInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import TransTextField from 'src/components/fields/TransTextField'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'

const _Filter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="translations.name:$contL" variant={'outlined'} alwaysOn={true} resettable={true} />
  </Filter>
)

const PageList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Текстовые страницы"
      exporter={false}
      empty={
        <EmptyList
          title={'Нет тестовых страниц'}
          description={'Вы можете добавить текстовую страницу'}
          buttonText={'Добавить текстовую страницу'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <VisibilityField source={'published'} label={'Опубликовано'} />
        <TransTextField source="name" label={'Название'} />
        <TextField source="key" label={'Ключ'} />

        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default PageList
