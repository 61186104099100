import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { ROLES } from 'src/roles'
import { getPermissionsFromRoles } from '@glm/ra-rbac'

export const AUTH_TOKEN_STORAGE_KEY = 'auth-token'
export const USER_DATA_STORAGE_KEY = 'userData'

export const AuthProvider = {
  login: ({ username, password }: any) => {
    const request = new Request(`${process.env.REACT_APP_API_URL || ''}/admin/api/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    //
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((data) => {
        Cookies.set(AUTH_TOKEN_STORAGE_KEY, data.token.accessToken)
        localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(data.user))
      })
  },
  logout: () => {
    Cookies.remove(AUTH_TOKEN_STORAGE_KEY)
    return Promise.resolve()
  },
  checkAuth: (params: any) => {
    if (window.location.pathname === '/singup') {
      return Promise.resolve()
    }
    if (!Cookies.get(AUTH_TOKEN_STORAGE_KEY)) {
      return Promise.reject({ redirectTo: '/login' })
    }
    return Promise.resolve()
  },
  checkError: (error: any) => {
    const status = error.status
    if (status === 401) {
      Cookies.remove(AUTH_TOKEN_STORAGE_KEY)
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: () => {
    try {
      const token = Cookies.get(AUTH_TOKEN_STORAGE_KEY)

      if (!token) {
        return Promise.reject()
      }
      const decoded: { role: string } = jwt_decode(token)
      //
      return Promise.resolve(
        getPermissionsFromRoles({
          roleDefinitions: ROLES,
          userRoles: [decoded.role],
        }),
      )
    } catch (e) {
      return Promise.reject()
    }
  },
  getIdentity: () => {
    try {
      const token = Cookies.get(AUTH_TOKEN_STORAGE_KEY)
      const userData = JSON.parse(localStorage.getItem(USER_DATA_STORAGE_KEY) ?? '{}')

      if (!token) {
        return Promise.reject()
      }
      const decoded: { role: string; id: number } = jwt_decode(token)
      //
      return Promise.resolve({
        id: decoded.id,
        role: decoded.role,
        fullName: userData?.name,
      })
    } catch (e) {
      return Promise.reject()
    }
  },
}
