import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import ReviewForm from 'src/resources/review/ReviewForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Отзыв</span>

const ReviewCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <ReviewForm />
    </Create>
  )
}

export default ReviewCreate
