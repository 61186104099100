import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import UnitSelectionCategoryForm from 'src/resources/unit-selection-category/UnitSelectionCategoryForm'
import { CustomEdit } from 'src/components/CustomEdit'

interface TitleProps {
  record?: any
}
const Title: FC<TitleProps> = ({ record }) => (record ? <span>Категория подборок ресторанов {record.name}</span> : null)

const UnitSelectionCategoryEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title />}>
      <UnitSelectionCategoryForm />
    </CustomEdit>
  )
}
export default UnitSelectionCategoryEdit
