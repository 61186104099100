import * as React from 'react'
import { ChangeEventHandler } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  FormTab,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useResourceContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import FormGroup from 'src/components/FormGroup'
import { FacilityTypeList } from 'src/types/enum/FacilityType'
import { UnitStatusList } from 'src/types/enum/UnitStatus'
import Formatter from 'src/utils/formatters'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { MultiEmailInput } from 'src/components/inputs/MultiEmailInput'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { PriceRatingList } from 'src/types/enum/PriceRating'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import { RestaurantMenuItemList } from 'src/resources/restaurant/menu/RestaurantMenuItemList'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'
import { MediaOrderInput } from 'src/components/inputs/MediaOrderInput'
import { RecordChanges } from 'src/components/RecordChangeList'
import { RecordType } from 'src/components/RecordChangeList'

const ScheduleDays = {
  0: 'Вс',
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
} as const

const InfoTab = (props: any) => {
  const resource = useResourceContext()

  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
    form.setValue('innerName', e.currentTarget.value)
  }

  return (
    <FormTab {...props} label={'Заведение'}>
      <FormGroup title={'Данные'}>
        <TextInput
          source="name"
          label={'Название'}
          validate={[required()]}
          fullWidth
          onChange={handleNameChange}
          helperText={'Может выводиться в каталоге'}
        />
        <TextInput
          source="innerName"
          label={'Внутреннее название'}
          fullWidth
          onChange={handleNameChange}
          helperText={'Выводиться только в ЛК Партнера'}
        />
        <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
        <IfCanAccess source={'status'} label={'Статус'} aggregator action={'edit'}>
          <SelectInput source={'status'} label={'Статус'} choices={UnitStatusList} validate={[required()]} />
        </IfCanAccess>

        <TextInput source="vendorCode" label={'Код у бренда'} fullWidth />
        <IfCanAccess source={'partnerId'} label={'Партнер'} aggregator action={'edit'}>
          <PartnerInput source={'partnerId'} label={'Партнер'} validate={[required()]} />
        </IfCanAccess>
        <SelectInput
          source="priceRating"
          label={'Сегмент'}
          choices={PriceRatingList}
          validate={[required()]}
          fullWidth
        />
        <NumberInput source="averageBill" label={'Средний чек'} fullWidth />
        <TextInput source="rating" label={'Рейтинг'} fullWidth />
        <RadioButtonGroupInput
          source="facilityType"
          label={'Тип заведения'}
          choices={FacilityTypeList}
          row={false}
          validate={[required()]}
        />
        <BooleanInput source="published" label={'Видимость'} fullWidth />
        <RichTextInput source="about" label={'Описание'} fullWidth />
      </FormGroup>
      <FormGroup title={'Товарные разделы'}>
        <CategoriesArrayInput
          filter={{ serviceType: ServiceTypeDictionary.convertResource(resource) }}
          source={'categoriesIds'}
          label={'Выбранные разделы'}
        />
      </FormGroup>

      <FormGroup title={'Медиа'}>
        <MediaInput source={'image'} label={'Обложка'} />
        <MediaOrderInput source={'photos'} isMulti={true} label={'Фотографии'} />
      </FormGroup>
      <FormGroup title={'Адрес'}>
        <ReferenceInput
          allowEmpty={false}
          source="regionId"
          reference="region"
          filterToQuery={(searchText: string) => ({ ...(searchText ? { 'name||$eq': searchText } : {}) })}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={100}
        >
          <AutocompleteInput optionText={(i) => `${i?.name || ''}`} label="Город" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="address" label={'Адрес'} validate={[required()]} fullWidth />
        <TextInput
          source="location"
          label={'Координаты'}
          validate={[required()]}
          helperText={'Через запуятую, например 55.616351,36.431444'}
          parse={(value) => {
            const parts = value.split(',')
            if (parts.length < 2 || !parts[0] || !parts[1]) {
              return value
            }
            return { lat: parts[0].trim(), lng: parts[1].trim() }
          }}
          format={(value) => {
            if (value?.lat && value.lng) {
              return `${value.lat},${value.lng}`
            }
            return value
          }}
          multiline={true}
          variant={'outlined'}
          fullWidth={true}
        />
      </FormGroup>
      <FormGroup title={'Контакты'}>
        <TextInput source="contactPerson" label={'Контактное лицо'} fullWidth />
        <TextInput source="contactsPhone" label={'Номер телефона'} fullWidth />
        <TextInput source="phone" label={'Номер телефона (публичный)'} fullWidth />
        <MultiEmailInput source={'contactEmails'} label={'Перечень email для уведомлений'} />
      </FormGroup>
      <FormGroup title={'Режим работы'}>
        <WorkScheduleMultiTimeInput source={'schedule'} label={false} />
      </FormGroup>
      {props.isEdit && (
        <RecordChanges
          recordType={RecordType.Restaurant}
          statusList={UnitStatusList}
          additionalMap={[
            { key: 'slug', label: 'URI' },
            { key: 'priceRating', label: 'Сегмент', choices: PriceRatingList },
            { key: 'contactEmails', label: 'Перечень email для уведомлений' },
            { key: 'contactsPhone', label: 'Номер телефона (публичный)' },
            { key: 'vendorCode', label: 'Код у бренда' },
            { key: 'averageBill', label: 'Средний чек' },
            {
              key: 'categories',
              label: 'Товарные разделы',
              valueBuilder: (value): string => {
                if (Array.isArray(value)) {
                  return value.map((el) => el.name).join(', ')
                }
                return value
              },
            },
            {
              key: 'schedule',
              label: 'Режим работы',
              valueBuilder: (values) => {
                return Object.keys(values)
                  .map((key) => {
                    const parsedKey = parseInt(key)
                    let valueString = `${ScheduleDays[parsedKey as keyof typeof ScheduleDays]}:`

                    valueString += Object.values(values[parsedKey])
                      .map((scheduleDayItem: any) => {
                        let dayItemString = ''

                        if (scheduleDayItem.from) {
                          dayItemString += ` От ${scheduleDayItem.from}`
                        }
                        if (scheduleDayItem.to) {
                          dayItemString += ` До ${scheduleDayItem.to}`
                        }
                        return dayItemString
                      })
                      .join(' => ')
                    //
                    return valueString
                  })
                  .join('\n')
              },
            },
          ]}
        />
      )}
    </FormTab>
  )
}

const MenuTab = (props: any) => {
  return (
    <FormTab {...props} label={'Меню'}>
      <RestaurantMenuItemList {...props} />
    </FormTab>
  )
}

const RestaurantForm = (props: any) => {
  return (
    <TabbedForm defaultValues={{}}>
      <InfoTab isEdit={props.isEdit} />
      {props.isEdit && <MenuTab />}
    </TabbedForm>
  )
}
export default RestaurantForm
