import * as React from 'react'
import { ReactElement, useState } from 'react'
import { Form, Toolbar } from 'react-admin'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { FieldValues } from 'react-hook-form'
import Box from '@mui/material/Box'
import { useTranslate } from 'ra-core'
import ContentSave from '@mui/icons-material/Save'
import { styled } from '@mui/material/styles'
import { Button, CircularProgress } from '@mui/material'
import styles from './index.module.scss'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

interface ModalFormProps {
  children?: ReactElement | ReactElement[] | undefined
  isShown: boolean
  isLoading?: boolean
  onClose?: () => void
  title?: string
  fullScreen?: boolean
  fullWidth?: boolean
  record?: any
  resource?: string
  maxWidth?: any
  contentClassName?: string
  defaultValues?: any
  saveButtonLabel?: string
  saveButtonIcon?: ReactElement
  cancelButtonLabel?: string
  save: (data: FieldValues) => Promise<boolean>
}

const ModalForm = (props: ModalFormProps) => {
  const [saving, setSaving] = useState(false)
  const handleSave = async (data: FieldValues) => {
    setSaving(true)
    await props.save(data)
    setSaving(false)
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog
        fullScreen={props.fullScreen}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.isShown}
        scroll={'paper'}
        onClose={(e) => {
          props.onClose?.()
        }}
        aria-label={props.title}
      >
        {props.title && (
          <DialogTitle sx={{ display: 'flex' }}>
            <div className={styles.title}>{props.title}</div>
            <IconButton
              size={'small'}
              onClick={(e) => {
                e.stopPropagation()
                props.onClose?.()
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        {!props.isLoading && (
          <Form
            record={props.record}
            defaultValues={props.defaultValues}
            resource={props.resource}
            onSubmit={handleSave}
            className={styles.form}
          >
            <ModalFormView
              saveButtonLabel={props.saveButtonLabel}
              saveButtonIcon={props.saveButtonIcon}
              cancelButtonLabel={props.cancelButtonLabel}
              handleClose={props.onClose}
            >
              {props.children}
            </ModalFormView>
          </Form>
        )}
      </Dialog>
    </div>
  )
}
ModalForm.defaultTypes = {
  fullWidth: true,
}

interface ModalFormViewProps {
  basePath?: string
  children?: ReactElement | ReactElement[]
  className?: string
  handleClose?: () => void
  handleSubmit?: (data: FieldValues) => void // passed by react-final-form
  invalid?: boolean
  pristine?: boolean
  record?: any
  resource?: string
  contentClassName?: string
  saving?: boolean
  submitOnEnter?: boolean
  toolbar?: ReactElement
  undoable?: boolean
  validate?: (data: FieldValues) => void
  saveButtonLabel?: string
  saveButtonIcon?: ReactElement
  cancelButtonLabel?: string
  variant?: string
  margin?: string
}

const ModalFormView = ({
  basePath,
  children,
  className,
  handleSubmit,
  handleClose,
  invalid,
  margin,
  pristine,
  record,
  resource,
  saving,
  submitOnEnter,
  toolbar,
  contentClassName,
  undoable,
  variant,
  saveButtonLabel,
  saveButtonIcon,
  cancelButtonLabel,
  ...rest
}: ModalFormViewProps) => {
  const translate = useTranslate()
  const labelSave = 'ra.action.save'
  const displayedLabelSave = saveButtonLabel ?? (labelSave && translate(labelSave, { _: labelSave }))
  const labelCancel = 'ra.action.cancel'
  const displayedLabelCancel = cancelButtonLabel ?? (labelCancel && translate(labelCancel, { _: labelCancel }))
  return (
    <>
      <DialogContent className={contentClassName}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flexStart'}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '0px' }}>
        {toolbar &&
          React.cloneElement(toolbar, {
            handleClose,
            basePath,
            saveButtonLabel,
            handleSubmit,
            invalid,
            pristine,
            record,
            resource,
            saving,
            submitOnEnter,
            undoable,
          })}
        {!toolbar && (
          <Toolbar className={styles.toolbar}>
            <StyledButton
              variant={'contained'}
              type={'submit'}
              color={'primary'}
              onClick={(e) => {
                e.stopPropagation()
              }}
              aria-label={displayedLabelSave}
              disabled={false}
            >
              {saving ? <CircularProgress size={18} thickness={2} /> : saveButtonIcon ?? <ContentSave />}
              {displayedLabelSave}
            </StyledButton>
            <Button
              variant={'contained'}
              type={'button'}
              color={'info'}
              aria-label={displayedLabelCancel}
              disabled={false}
              onClick={(e) => {
                e.stopPropagation()
                handleClose?.()
              }}
            >
              {displayedLabelCancel}
            </Button>
          </Toolbar>
        )}
      </DialogActions>
    </>
  )
}
const PREFIX = 'RaSaveButton'
const StyledButton = styled(Button, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  position: 'relative',
  ['& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root']: {
    marginRight: theme.spacing(1),
  },
  ['& .MuiSvgIcon-root, & .MuiIcon-root']: {
    fontSize: 18,
  },
}))

ModalFormView.defaultProps = {
  submitOnEnter: true,
}
export default ModalForm
