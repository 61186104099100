import React, { ComponentProps, ReactElement } from 'react'
import { useInput, FieldTitle } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'
import Slider from '@mui/material/Slider'
import { FormHelperText, FormControl, InputLabel } from '@mui/material'
import Box from '@mui/material/Box'

export interface Props {
  label?: string | ReactElement
  source: string
  fullWidth?: boolean
  helperText?: ComponentProps<typeof InputHelperText>['helperText']
  record?: Record<any, any>
  resource?: string
  variant?: string

  [key: string]: any
}

const SliderInput = (props: Props) => {
  const { fullWidth = true, helperText, label, source, resource, variant, ...rest } = props

  const {
    id,
    isRequired,
    field: { value, onChange },
    fieldState: { error, isTouched },
  } = useInput({ source, ...rest })

  return (
    <div>
      <FormControl error={!!(isTouched && error)} className="ra-rich-text-input">
        <InputLabel shrink htmlFor={id}>
          <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
        </InputLabel>
        <Box mt={1}>
          <Slider
            defaultValue={value ?? 30}
            getAriaValueText={(value) => `${value} мин.`}
            valueLabelDisplay="auto"
            step={5}
            marks
            min={5}
            max={60}
            onChange={onChange}
          />
        </Box>
        <FormHelperText error={!!error} className={!!error ? 'ra-rich-text-input-error' : ''}>
          <InputHelperText
            error={error as any}
            helperText={helperText || 'Можно также менять стрелками'}
            touched={isTouched}
          />
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default SliderInput
