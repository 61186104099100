import ListIcon from '@mui/icons-material/List'
import AdminList from 'src/resources/admin/AdminList'
import AdminEdit from 'src/resources/admin/AdminEdit'
import AdminCreate from 'src/resources/admin/AdminCreate'

export default {
  create: AdminCreate,
  edit: AdminEdit,
  list: AdminList,
  icon: ListIcon,
}
