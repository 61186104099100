export enum FacilityType {
  Restaurant = 'restaurant',
  Kitchen = 'kitchen',
  Bakery = 'bakery',
  Cafe = 'cafe',
}
export const FacilityTypeList = [
  { id: FacilityType.Restaurant, name: 'Ресторан' },
  { id: FacilityType.Kitchen, name: 'Кухня' },
  { id: FacilityType.Bakery, name: 'Пекарня' },
  { id: FacilityType.Cafe, name: 'Кафе' },
]
