export enum PartnerStatus {
  Draft = 'draft',
  Review = 'review',
  Active = 'active',
  Blocked = 'blocked',
  Archived = 'archived',
}

export const PartnerStatusList = [
  { id: PartnerStatus.Draft, name: 'Черновик' },
  { id: PartnerStatus.Review, name: 'На согласовании' },
  { id: PartnerStatus.Active, name: 'Активен' },
  { id: PartnerStatus.Blocked, name: 'Заблокирован' },
  { id: PartnerStatus.Archived, name: 'Архив' },
]
