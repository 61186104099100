import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, SelectField, TextField, DateField, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import OrderLinkField from 'src/components/fields/OrderLinkField'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import UserField from 'src/components/fields/UserField'
import UserInput from 'src/components/inputs/UserInput'
import OrderInput from 'src/components/inputs/OrderInput'
import UnitInput from 'src/components/inputs/UnitInput'
import { useRecordContext } from 'ra-core'
import { ModalButton } from 'src/components/Modal/ModalButton'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { ComplaintAnswerModal } from 'src/resources/complaint/ComplaintAnswerModal'
import { ComplaintStatus, ComplaintStatusList } from 'src/types/enum/ComplaintStatus'
import { ComplaintReasonList } from 'src/types/enum/ComplaintReason'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import { colors } from 'src/style'
import { ComplaintSourceList } from 'src/types/enum/ComplaintSource'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Complaint',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <UserInput source="userId" label={'Клиент'} />
      <OrderInput source="orderId" label={'Заказ'} />
      <UnitInput alwaysOn={true} resettable={true} source="unitId" label={'Заведение'} />
      <SelectInput source={'status'} label={'Статус'} choices={ComplaintStatusList} />
      <SelectInput source={'reasonType'} label={'Причина'} choices={ComplaintReasonList} />
      <SelectInput source={'source'} label={'Источник'} choices={ComplaintSourceList} />
      <DateRangeFilterInput source={'createdAt:$between'} label="Даты" />
    </Filter>
  )
}

const AnswerField = (props: any) => {
  const record = useRecordContext()
  return record.answer && record.status === ComplaintStatus.Resolved ? (
    <span>Есть ответ</span>
  ) : (
    <ModalButton label={'Ответить'} icon={<QuestionAnswerIcon />} key={'import'} modal={<ComplaintAnswerModal />} />
  )
}

export const DatagridRowProps = {
  rowStyle: (record: any, index: number) => {
    return {
      ...(record.status === ComplaintStatus.Resolved ? { backgroundColor: colors.rowBgGreen } : {}),
      ...(record.status === ComplaintStatus.PostPoned ? { backgroundColor: colors.rowBgRed } : {}),
    }
  },
}
const ComplaintList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Жалобы"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'show'} data-cy="complaint-list" {...DatagridRowProps}>
        <TextField source={'id'} label={'ID'} type={'show'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
        <OrderLinkField label={'Заказ'} />
        <TextField label={'Бренд'} source={'unit.brand.name'} />
        <UnitLinkField label={'Заведение'} />
        <SelectField source={'source'} label={'Источник'} choices={ComplaintSourceList} />
        <SelectField source={'status'} label={'Статус'} choices={ComplaintStatusList} />
        <SelectField source={'reasonType'} label={'Причина'} choices={ComplaintReasonList} />
        <UserField
          source={'userId'}
          sourceId={'userId'}
          sourceName={'user.name'}
          sourcePhone={'user.phone'}
          label={'Клиент'}
        />
        <DateField source="resolvedAt" label={'Решено'} showTime={true} />
        <TextField source={'admin.name'} label={'Админ'} />
        <TextField source={'text'} label={'Жалоба'} />
        <AnswerField label={'Ответ'} source={'answer'} />
      </Datagrid>
    </List>
  )
}

export default ComplaintList
