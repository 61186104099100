import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const OrderLinkField = (props: Props) => {
  return (
    <LinkField
      link={({ order }) => `/order/${order?.id}`}
      source={props.source ?? 'order.number'}
      value={({ order }) => order?.number ?? order?.id}
      label={props.label}
    />
  )
}

export default OrderLinkField
