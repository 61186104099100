export const RecordFieldsMap = {
  user: 'Клиент',
  admin: 'Менеджер',
  created: 'Статус',
  unit: 'Заведение',
  address: 'Адрес',
  partner: 'Партнер',
  region: 'Регион',
  rating: 'Рейтинг',
  restaurant: 'Ресторан',
  promo: 'Акция',
  name: 'Название',
  innerName: 'Внутренее название',
  about: 'Описание',
  timezone: 'Часовый пояс',
  facilityType: 'Тип заведения',
  published: 'Видимость',
  phone: 'Номер телефона',
  contactPerson: 'Контакнтое лицо',
  adminComment: 'Комментарий менеджера',
  assetsAdded: 'Добавлены фотографии',
  assetsRemoved: 'Удалены фотографии',
  photosAdded: 'Добавлены фотографии',
  photosRemoved: 'Удалены фотографии',
  source: 'Источник',
  prevStatus: 'Прошлый статус',
  status: 'Статус',
  createdAt: 'Время создания',
  updatedAt: 'Время изменения',
}

export enum RecordChangeSource {
  Aggregator = 'aggregator',
  Partner = 'partner',
  PartnerWeb = 'partnerWeb',
  User = 'user',
}

export const RecordChangeSourceList = [
  { id: RecordChangeSource.Aggregator, name: 'Агрегатор' },
  { id: RecordChangeSource.Partner, name: 'Вендор приложение' },
  { id: RecordChangeSource.PartnerWeb, name: 'Вендор админка' },
  { id: RecordChangeSource.User, name: 'Клиент' },
]
