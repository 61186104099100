import Divider, { DividerProps } from '@mui/material/Divider'

interface SpacerProps extends DividerProps {
  space?: string
}

export default function Spacer(props: SpacerProps) {
  const { space, sx, ...rest } = props
  const direction = rest?.orientation === 'vertical' ? 'borderRightWidth' : 'borderBottomWidth'
  return <Divider sx={{ borderColor: 'transparent', ...sx, ...{ [direction]: space ? space : '15px' } }} {...rest} />
}
