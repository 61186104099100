import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useInput, FieldTitle } from 'ra-core'
import styles from './index.module.scss'
import { useTranslate } from 'react-admin'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { FormControl, FormHelperText, InputLabel, IconButton, Button } from '@mui/material'
import { InputHelperText } from 'ra-ui-materialui'
import CancelIcon from '@mui/icons-material/Cancel'

const EmojiInput = ({ ...fieldProps }) => {
  const {
    options,
    label,
    source,
    format,
    parse,
    resource,
    helperText,
    fullWidth = true,
    variant,
    margin = 'dense',
    className,
    providerOptions,
    validate,
  } = fieldProps
  const translate = useTranslate()

  const {
    id,
    isRequired,
    field: { value, onChange },
    fieldState: { error, isTouched },
  } = useInput({ source, format, parse, validate })

  const [showPicker, setShowPicker] = useState(false)

  const addEmoji = (emoji: any) => {
    onChange(emoji.native)
    setShowPicker(false)
  }
  const togglePicker = () => {
    setShowPicker(!showPicker)
  }
  const handleDelete = () => {
    onChange(null)
  }
  return (
    <FormControl error={!!(isTouched && error)} className="ra-rich-text-input" margin={margin}>
      <InputLabel shrink htmlFor={id}>
        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      </InputLabel>
      <div className={styles.root}>
        {value && (
          <div className={styles.emoji} onClick={togglePicker}>
            {value}
          </div>
        )}
        {value && (
          <IconButton aria-label="delete" size={'small'} onClick={handleDelete}>
            <CancelIcon color={'error'} />
          </IconButton>
        )}
        {!value && (
          <Button variant="contained" color={'primary'} size={'small'} onClick={togglePicker}>
            +
          </Button>
        )}
      </div>
      {showPicker && (
        <div className={styles.picker}>
          <Picker data={data} onEmojiSelect={addEmoji} title={'Выберите'} />
        </div>
      )}
      <FormHelperText error={!!error} className={!!error ? 'ra-rich-text-input-error' : ''}>
        <InputHelperText error={error as any} helperText={helperText} touched={isTouched} />
      </FormHelperText>
    </FormControl>
  )
}

EmojiInput.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
}

EmojiInput.defaultProps = {
  input: {},
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
}
export default EmojiInput
