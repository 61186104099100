import ListIcon from '@mui/icons-material/List'
import PromoCodeList from 'src/resources/promo-code/PromoCodeList'
import PromoCodeEdit from 'src/resources/promo-code/PromoCodeEdit'
import PromoCodeCreate from 'src/resources/promo-code/PromoCodeCreate'

export default {
  create: PromoCodeCreate,
  edit: PromoCodeEdit,
  list: PromoCodeList,
  icon: ListIcon,
}
