export enum PromoTemplate {
  White = 'White',
  WithBackground = 'WithBackground',
  WithImage = 'WithImage',
  WithImageNegative = 'WithImageNegative',
  WithImageGradient = 'WithImageGradient',
}

export const PromoTemplateList = [
  // {id: PromoTemplate.White, name: 'Белая'},
  // {id: PromoTemplate.WithBackground, name: 'С фоном'},
  // {id: PromoTemplate.WithImage, name: 'Цветная с картинкой'},
  { id: PromoTemplate.WithImageGradient, name: 'Градинентная с картинкой' },
  //  {id: PromoTemplate.WithImageNegative, name: 'Цветная с увеличенной картинкой'},
]
