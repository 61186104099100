import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { PageForm } from 'src/resources/page/PageForm'

const PageEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <PageForm />
    </Edit>
  )
}
export default PageEdit
