import { Datagrid as RaDatagrid, DatagridConfigurableProps } from 'react-admin'
import { Box } from '@mui/material'
import { useMeasure } from 'react-use'

const INNER_PADDING = 0

export const Datagrid = (props: DatagridConfigurableProps) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  return (
    <Box
      ref={ref}
      sx={
        {
          //  overflowX: 'auto',
          // width: width - INNER_PADDING,
        }
      }
    >
      <RaDatagrid
        bulkActionButtons={false}
        {...props}
        sx={{
          overflowX: 'auto',
          width: width - INNER_PADDING,
        }}
      >
        {props.children}
      </RaDatagrid>
    </Box>
  )
}
