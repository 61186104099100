import * as React from 'react'
import { FC } from 'react'
import { EditProps } from 'react-admin'
import BrandForm from 'src/resources/brand/BrandForm'
import { CustomEdit } from 'src/components/CustomEdit'

const BrandEdit: FC<EditProps> = (props) => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic">
      <BrandForm />
    </CustomEdit>
  )
}
export default BrandEdit
