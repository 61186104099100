import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, SelectInput, TextField, TextInput, SelectField } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import TransTextField from 'src/components/fields/TransTextField'
import { ArticleTypeList } from 'src/types/enum/ArticleType'
import VisibilityField from 'src/components/fields/VisibilityField'
import { getTranslation } from 'src/utils/translation'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      published: 'Опубликовано',
      name: 'Название',
      categoryType: 'Тип',
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,
      name: getTranslation(item)?.name,
      categoryType: ArticleTypeList.find((i) => i.id === item.categoryType)?.name || '',
      published: item.published ? 'Да' : 'Нет',
      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'ArticleCategory',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="translations.name:$contL" label={'Поиск'} variant={'outlined'} alwaysOn />
      <SelectInput source={'categoryType'} label={'Тип'} choices={ArticleTypeList} />
    </Filter>
  )
}

const ArticleCategoryList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Категории"
      sort={{ field: 'translations.name', order: 'ASC' }}
      filter={{ 'translations.languageCode': 'ru' }}
      empty={
        <EmptyList
          title={'Нет Категорий'}
          description={'Вы можете добавить категорию'}
          buttonText={'Добавить категории'}
        />
      }
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <VisibilityField source={'published'} label={''} />
        <TransTextField source={'name'} label={'Название'} />
        <TextField source={'uri'} label={'URI'} />
        <SelectField source={'categoryType'} label={'Тип'} choices={ArticleTypeList} />
      </Datagrid>
    </List>
  )
}

export default ArticleCategoryList
