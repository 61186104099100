import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, FunctionField, SelectField, DateField, TextInput } from 'react-admin'

import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { ApiIntegrationSyncStatusList } from 'src/types/enum/ApiIntegrationSyncStatus'
import { ApiIntegrationSyncTypeList } from 'src/types/enum/ApiIntegrationSyncType'
import PartnerInput from 'src/components/inputs/PartnerInput'
import BrandInput from 'src/components/inputs/BrandInput'
import UnitInput from 'src/components/inputs/UnitInput'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'ApiIntegrationSync',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id:$eq" label={'ID'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <PartnerInput label={'Партнер'} />
      <BrandInput label={'Бренд'} resettable={true} />
      <UnitInput label={'Заведение'} resettable={true} />
    </Filter>
  )
}

const ApiIntegrationSyncList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<_Filter />}
      title="API синхронизации"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <SelectField source={'status'} label={'Статус'} choices={ApiIntegrationSyncStatusList} />
        <SelectField source={'type'} label={'Тип'} choices={ApiIntegrationSyncTypeList} />
        <FunctionField
          source={'progress'}
          label={'Прогресс'}
          render={(value: any) => `${value.progress ? `${value.progress}%` : '-'}`}
        />

        <TextField source={'partner.name'} label={'Партнер'} />
        <TextField source={'brand.name'} label={'Бренд'} />
        <TextField source={'unit.name'} label={'Заведение'} />
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
        <DateField source={'startedAt'} label={'Старт'} showTime={true} />
        <DateField source={'finishedAt'} label={'Финиш'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default ApiIntegrationSyncList
