export enum ComplaintSource {
  Website = 'Website',
  MobileApp = 'MobileApp',
  Phone = 'Phone',
  GooglePlay = 'GooglePlay',
  AppStore = 'AppStore',
}

export const ComplaintSourceList = [
  { id: ComplaintSource.Website, name: 'Сайт' },
  { id: ComplaintSource.MobileApp, name: 'Мобильное приложение' },
  { id: ComplaintSource.Phone, name: 'Телефон' },
  { id: ComplaintSource.GooglePlay, name: 'Google Play' },
  { id: ComplaintSource.AppStore, name: 'App Store' },
]
