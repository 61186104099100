import * as React from 'react'
import { useRecordContext } from 'ra-core'

import { FunctionField, FieldProps, RaRecord } from 'react-admin'
import { getTranslation } from 'src/utils/translation'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}
const TransTextField = (props: Props) => {
  const record = useRecordContext(props)
  const translation = getTranslation(record as any)
  const translationValue = translation ? get(translation, props.source ?? '') : ''
  return <FunctionField {...props} source={props.source} render={() => translationValue} />
}

export default TransTextField
