import * as React from 'react'
import { Pagination, TextField, ReferenceManyField } from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import { ModalCreateButton, ModalEditButton } from 'src/components/Modal/ModalButton'
import { useRecordContext } from 'ra-core'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import { UnitSelectionItemCreateModal } from 'src/resources/unit-selection/items/UnitSelectionItemCreateModal'
import { UnitSelectionItemEditModal } from 'src/resources/unit-selection/items/UnitSelectionItemEditModal'

interface Props {
  record?: any
}

export const UnitSelectionItemList = (props: Props) => {
  const record = useRecordContext()
  return (
    <ReferenceManyField
      {...props}
      reference="unit-selection-item"
      target="unitSelectionId"
      sort={{ field: 'order', order: 'ASC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        bulkActionButtons={false}
        resource={'course-module'}
        emptyTitle={'Нет ресторанов'}
        actions={<ModalCreateButton modal={<UnitSelectionItemCreateModal mainRecord={record} refresh />} />}
        createButton={<ModalCreateButton modal={<UnitSelectionItemCreateModal mainRecord={record} refresh />} />}
      >
        <UnitLinkField label={'Ресторан'} />
        <TextField source={'order'} label={'Сортировка'} />
        <EditListButtons
          editButton={<ModalEditButton modal={<UnitSelectionItemEditModal mainRecord={record} refresh />} />}
        />
      </RelatedList>
    </ReferenceManyField>
  )
}
