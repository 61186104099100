import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { useYMaps } from '@pbe/react-yandex-maps'
import styles from './index.module.scss'
import IconButton from '@mui/material/IconButton'
import { IFocalPoint } from 'src/components/types'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { CommonInputProps } from 'react-admin'
import { useInput } from 'ra-core'

type UpdateCallback = (value: IFocalPoint | null) => void

enum GeoType {
  Polygon = 'polygon',
  Circle = 'circle',
}

interface GeoValue {
  type: string
  coordinates: number[] | number[][]
  radius: number
}

interface GeoObjectRef {
  vIndex: number
}

interface Props extends CommonInputProps {
  record?: any
}

export const DeliveryGeoInput: FC<any> = (props) => {
  const { field } = useInput({
    ...props,
  })
  const mapDivRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<any>(null)
  const initRef = useRef<boolean>(false)
  const ymaps = useYMaps(['Map', 'geoObject.addon.editor', 'Monitor', 'Polygon', 'Circle', 'coordSystem.geo'])
  const value = (field.value as GeoValue[]) || []
  const refs = useRef<(GeoObjectRef | any)[]>([])
  const currentEditRef = useRef<any>()

  const newCoordinatesRef = useRef()
  const [newGeoType, setNewGeoType] = useState<GeoType | null>(null)
  const valueRef = useRef<GeoValue[]>(field.value ?? [])
  const currentEditMonitorRef = useRef<any>(null)
  const [currentEdit, setCurrentEdit] = useState<any>(null)
  const defaultState = {
    center: [55.751574, 37.573856],
    zoom: 5,
  }
  useEffect(() => {
    valueRef.current = field.value
  }, [field.value])
  useEffect(() => {
    if (!ymaps || !mapDivRef.current) {
      return
    }
    if (mapRef.current) {
      return
    }
    mapRef.current = new ymaps.Map(mapDivRef.current, {
      center: [55.76, 37.64],
      zoom: 10,
    })
  }, [ymaps])
  useEffect(() => {
    if (!ymaps) {
      return
    }
    if (initRef.current) {
      return
    }
    let i = 0
    for (const item of field.value) {
      let geoObject
      switch (item.type) {
        case GeoType.Polygon:
          geoObject = new ymaps.Polygon(
            [
              // Координаты вершин внешнего контура.
              item.coordinates,
            ],
            {
              editorDrawingCursor: 'crosshair',
              vIndex: i,
              vGeomType: GeoType.Polygon,
            },
            {
              fillColor: '#1976d2',
              strokeColor: '#1976d2',
              strokeOpacity: 1,
              fillOpacity: 0.4,
              strokeWidth: 2,
            },
          )
          break
        case GeoType.Circle:
          geoObject = new ymaps.Circle(
            [
              // Координаты центра круга.
              item.center,
              // Радиус круга в метрах.
              item.radius,
            ],
            {
              editorDrawingCursor: 'crosshair',
              vIndex: i,
              vGeomType: GeoType.Circle,
            },
            {
              fillColor: '#1976d2',
              strokeColor: '#1976d2',
              strokeOpacity: 1,
              fillOpacity: 0.4,
              strokeWidth: 2,
            },
          )
          break
      }
      geoObject?.events.add('click', (e) => {
        handleEditRef(e.originalEvent.target, (e.originalEvent.target as any).properties.get('vIndex'))
      })
      refs.current.push(geoObject)
      mapRef.current.geoObjects.add(geoObject)
      ++i
    }
    if (mapRef.current.geoObjects.getBounds()?.length > 0) {
      mapRef.current.setBounds(mapRef.current.geoObjects.getBounds(), { checkZoomRange: true })
    }
    initRef.current = true
  }, [field.value, ymaps])

  const handleAddCircle = () => {
    if (!ymaps) {
      return
    }
    const geoObject = new ymaps.Circle(
      [
        // Координаты вершин внешнего контура.
        [],
        [],
      ],
      {
        editorDrawingCursor: 'crosshair',
        vIndex: valueRef.current.length ?? 0,
        vGeomType: GeoType.Circle,
      },
      {
        fillColor: '#1976d2',
        strokeColor: '#1976d2',
        strokeOpacity: 1,
        fillOpacity: 0.4,
        strokeWidth: 2,
      },
    )

    geoObject.events.add('click', (e) => {
      handleEditRef(e.originalEvent.target, (e.originalEvent.target as any).properties.get('vIndex'))
    })
    if (mapRef.current.geoObjects.add) {
      mapRef.current.geoObjects.add(geoObject)
    }
    refs.current.push(geoObject)
    ;(geoObject.editor as any).startDrawing()
    field.onChange([...(valueRef.current ?? []), { type: GeoType.Circle }])

    handleEditRef(geoObject, field.value?.length ?? 0)
  }
  const handleAddPolygon = () => {
    if (!ymaps) {
      return
    }
    const geoObject = new ymaps.Polygon(
      [
        // Координаты вершин внешнего контура.
        [],
        [],
      ],
      {
        editorDrawingCursor: 'crosshair',
        vIndex: valueRef.current.length ?? 0,
        vGeomType: GeoType.Polygon,
      },
      {
        fillColor: '#1976d2',
        strokeColor: '#1976d2',
        strokeOpacity: 1,
        fillOpacity: 0.4,
        strokeWidth: 2,
      },
    )

    geoObject.events.add('click', (e) => {
      handleEditRef(e.originalEvent.target, (e.originalEvent.target as any).properties.get('vIndex'))
    })
    if (mapRef.current.geoObjects.add) {
      mapRef.current.geoObjects.add(geoObject)
    }
    refs.current.push(geoObject)
    ;(geoObject.editor as any).startDrawing()
    field.onChange([...(valueRef.current ?? []), { type: GeoType.Polygon, coordinates: [] }])

    handleEditRef(geoObject, field.value?.length ?? 0)
  }

  const handleEditRef = (ref: any, index: number) => {
    const type = (ref as any).properties.get('vGeomType') as GeoType
    if (currentEditRef.current) {
      currentEditRef.current.editor.events.remove(['vertexadd', 'vertexdragend'])
      currentEditRef.current.events.remove(['geometrychange'])
      currentEditRef.current.editor.stopDrawing()
      currentEditRef.current.editor.stopEditing()
    }
    if (currentEditMonitorRef.current) {
      currentEditMonitorRef.current.remove('drawing')
      currentEditMonitorRef.current.remove('editing')
      currentEditMonitorRef.current = null
    }
    const stateMonitor = new ymaps!.Monitor(ref.editor.state)
    currentEditMonitorRef.current = stateMonitor
    stateMonitor.add('drawing', function (newValue) {
      ref.options.set('strokeColor', newValue ? '#FF0000' : '#0000FF')
    })
    stateMonitor.add('editing', function (newValue) {
      ref.options.set('strokeColor', newValue ? '#FF0000' : '#0000FF')
    })
    ref.editor.startEditing()
    currentEditRef.current = ref
    setCurrentEdit(ref)
    if (type === GeoType.Polygon) {
      ref.editor.events.add(['vertexadd', 'vertexdragend'], (event: any) => {
        const coordinates = event.originalEvent.target.geometry.getCoordinates()[0]
        field.onChange([...(valueRef.current ?? []).map((i, idx) => (idx === index ? { ...i, coordinates } : i))])
      })
    }
    if (type === GeoType.Circle) {
      ref.events.add(['geometrychange'], (event: any) => {
        const center = event.originalEvent.target.geometry.getCoordinates()
        const radius = Math.ceil(ref.geometry.getRadius())
        field.onChange([
          ...(valueRef.current ?? []).map((i, idx) =>
            idx === index
              ? {
                  ...i,
                  center,
                  radius,
                }
              : i,
          ),
        ])
      })
    }
  }
  const handleDelete = () => {
    if (currentEditRef.current) {
      const index = (currentEditRef.current as any).properties.get('vIndex')
      const newValue = [...valueRef.current]
      newValue.splice(index, 1)
      const newRefs = [...refs.current]
      newRefs.splice(index, 1)
      refs.current = newRefs
      for (const ref of refs.current) {
        const curIndex = (ref as any).properties.get('vIndex')
        if (curIndex > index) {
          ;(ref as any).properties.set('vIndex', curIndex - 1)
        }
      }

      field.onChange(newValue)
      currentEditRef.current.editor.events.remove(['vertexadd', 'vertexdragend'])
      currentEditRef.current.editor.stopDrawing()
      currentEditRef.current.editor.stopEditing()
      mapRef.current.geoObjects.remove(currentEditRef.current)
      setCurrentEdit(null)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <div ref={mapDivRef} className={styles.map} />
      </div>
      {
        <div className={styles.imageToolbar}>
          <IconButton aria-label="panorama" onClick={handleAddCircle}>
            <PanoramaFishEyeIcon />
          </IconButton>
          <IconButton aria-label="circle" onClick={handleAddPolygon}>
            <CropSquareIcon />
          </IconButton>
          {currentEdit && (
            <IconButton aria-label="circle" onClick={handleDelete}>
              <DeleteForeverIcon />
            </IconButton>
          )}
        </div>
      }
    </div>
  )
}
