export const ComplaintFieldsMap = {
  user: 'Клиент',
  admin: 'Менеджер',
  created: 'Статус',
  unit: 'Заведение',
  order: 'Заказ',
  orderId: 'ID Заказа',
  reviewId: 'ID Отзыва',
  source: 'Источник',
  status: 'Статус',
  adminComment: 'Комментарий менеджера',
  assetsAdded: 'Добавлены фотографии',
  assetsRemoved: 'Удалены фотографии',
  withholdingAmount: 'Сумма удержания',
  appealAmount: 'Сумма апеляции',
  text: 'Жалоба',
  reasonType: 'Причина',
  resolveType: 'Решение',
  answer: 'Ответ',
  promoCode: 'Промокод',
  createdAt: 'Время создания',
  updatedAt: 'Время изменения',
  resolvedAt: 'Время решения',
}

export enum ComplaintChangeSource {
  Aggregator = 'aggregator',
  User = 'user',
}

export const ComplaintChangeSourceList = [
  { id: ComplaintChangeSource.Aggregator, name: 'Агрегатор' },
  { id: ComplaintChangeSource.User, name: 'Клиент' },
]
