import * as React from 'react'

import styles from './index.module.scss'

import { PromoIcon } from 'src/types/enum/PromoIcon'
import { PromoColor } from 'src/types/enum/PromoColor'
import { PromoTemplate } from 'src/types/enum/PromoTemplate'
import { useMemo } from 'react'
import OnePlusOneSvg from 'src/components/svg/OnePlusOneSvg'
import PercentSvg from 'src/components/svg/PercentSvg'
import ScooterSvg from 'src/components/svg/ScooterSvg'
import RubleSvg from 'src/components/svg/RubleSvg'
import GiftSvg from 'src/components/svg/GiftSvg'
import { IPromoSettings } from 'src/types/interfaces/IPromoSettings'

interface Props {
  image?: any
  icon: PromoIcon
  color: PromoColor
  badgeColor: PromoColor
  template: PromoTemplate
  badge?: string
  name?: string
  settings?: IPromoSettings
}

const PromoColorImageCard = (props: Props) => {
  const icon = useMemo(() => {
    const color = props.badgeColor
    switch (props.icon) {
      case PromoIcon.OnePlusOne:
        return <OnePlusOneSvg color={color} />
      case PromoIcon.Ruble:
        return <RubleSvg color={color} />
      case PromoIcon.Scooter:
        return <ScooterSvg color={color} />
      case PromoIcon.Percent:
        return <PercentSvg color={color} />
      case PromoIcon.Gift:
        return <GiftSvg color={color} />
    }
  }, [props.icon, props.badgeColor])
  const isGradient = props.color?.includes(';')
  return (
    <div
      className={styles.root}
      style={{
        ...(props.color
          ? {
              background: isGradient
                ? `linear-gradient(125.99deg, ${props.color.split(';')[0]} -2.47%, ${props.color.split(';')[1]} 86.22%)`
                : props.color,
            }
          : {}),
      }}
    >
      <div className={styles.block}>
        {props.icon && icon}
        <div className={styles.name}>{props.name}</div>
      </div>
      <div className={styles.badge}>
        {props.badge?.replace('{{minOrderAmount}}', props.settings?.minOrderAmount?.toString() || '{{minOrderAmount}}')}
      </div>
      {props.image && <img src={props.image.link} className={styles.image} />}
    </div>
  )
}
export default PromoColorImageCard
